// const Restaurants = () => {
//   return <h1>Blog Articles</h1>;
// };

// export default Restaurants;
import EllipsisText from 'react-ellipsis-text';
import axios from 'axios';
import React, { Component, useState, useEffect } from 'react';
import configData from '../../config.json';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarLink,
  MDBBtn,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from 'mdb-react-ui-kit';

import './styles.css';
import Footer from '../../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../../components/Navbar';
import { Router, Link, useNavigate } from 'react-router-dom';
import { useRestaurantContext } from '../../context/restaurants';
import { debounce } from 'lodash';

export default function Restaurants() {
  const restaurantContext = useRestaurantContext();

  // const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [basicModal3, setBasicModal3] = useState(false);
  const [toggleCreateAccountModal, setToggleCreateAccountModal] = useState(false);
  const [toggleForgotAccountModal, setToggleForgotAccountModal] = useState(false);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [agree_to_terms, setAgreeTerms] = useState(false);
  const [orderNotAllowed, setOrderNotAllowed] = useState(false);

  const [query, setQuery] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const locCache = window.localStorage.getItem('position');
    if (locCache) {
      const loc = JSON.parse(locCache);
      const payload = {
        ignoreDineinTakeout: true,
        geoLocation: {
          coordinates: [loc.longitude, loc.latitude],
        },
      };
      getRestaurants(payload);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          // console.log('Latitude is :', position.coords.latitude);
          // console.log('Longitude is :', position.coords.longitude);
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const payload = {
            ignoreDineinTakeout: true,
            geoLocation: {
              coordinates: [longitude, latitude],
            },
          };
          getRestaurants(payload);

          window.localStorage.setItem('position', JSON.stringify({ latitude, longitude }));
        },
        () => {
          const payload = {
            ignoreDineinTakeout: true,
            geoLocation: {
              coordinates: [0, 0],
            },
          };
          getRestaurants(payload);
        },
      );
    } else {
      const payload = {
        ignoreDineinTakeout: true,
        geoLocation: {
          coordinates: [0, 0],
        },
      };
      getRestaurants(payload);
    }

    localStorage.removeItem('orders');
    localStorage.removeItem('return_url');
  }, []);

  useEffect(() => {
    setRestaurants([...restaurantContext.restaurants]);
  }, [restaurantContext?.restaurants]);

  const usernameChange = (value) => {
    setUsername(value);
  };

  const emailChange = (value) => {
    setEmail(value);
  };

  const contactChange = (value) => {
    setContact(value);
  };

  const passwordChange = (value) => {
    setPassword(value);
  };

  const confirmPasswordChange = (value) => {
    setConfirmPassword(value);
  };

  const agreeTermsChange = (value) => {
    setAgreeTerms(!agree_to_terms);
  };

  const toggleShow2 = () => {
    setBasicModal3(!basicModal3);
  };

  const logIn = () => {
    const data = `email=${username}&password=${password}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`${configData.SERVER_URL}/user/login`, data, { config })
      .then((response) => {
        if (response.data.Message == 'User successfully logged in') {
          setUserProfile(response.data);
          localStorage.setItem('user_profile', JSON.stringify(response.data));
          const return_url = localStorage.getItem('return_url');
          if (typeof return_url !== 'undefined' && return_url !== null) {
            window.location.replace(return_url);
          }
          closeModal3();
        } else {
          // setUserProfile({ Error: 'Invalid Credentials' });
          notifyError();
        }
      })
      .catch((error) => {
        // element.parentElement.innerHTML = `Error: ${error.message}`;
        // console.error('There was an error!', error);
        // setUserProfile({ Error: 'Invalid Credentials' });
        notifyError();
      });
  };

  const signUp = () => {
    if (agree_to_terms) {
      if (email == '' || username == '' || password == '') {
        checkRequiredFields();
      } else {
        const data = `email=${email}&name=${username}&password=${password}&mobilenumber=${contact}`;
        const config = {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        };
        axios
          .post(`${configData.SERVER_URL}/user/register`, data, { config })
          .then((response) => {
            if (response.data.Message == 'User successfully registered.') {
              setUserProfile(response.data);
              localStorage.setItem('user_profile', JSON.stringify(response.data));
              // console.log(response.data);
              setToggleCreateAccountModal(false);
            } else {
              notifyError();
            }
          })
          .catch((error) => {
            // error.response.data
            emailExisting();
          });
      }
    } else {
      agreeToTermsFirst();
    }
  };

  const forgotPassword = () => {
    const data = `email=${email}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`${configData.SERVER_URL}/user/forgotpassword`, data, { config })
      .then((response) => {
        // console.log(response.data);
        if (response.data.Message == 'Reset successful. Please check your email.') {
          setToggleForgotAccountModal(false);
        } else {
          notifyError();
        }
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };

  const notifyError = () => {
    toast('Invalid Credentials!', {
      containerId: 'A',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const emailExisting = () => {
    toast('Email allerede taget.', {
      containerId: 'B',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const checkRequiredFields = () => {
    toast('Navn, e-mail og adgangskode er påkrævet.', {
      containerId: 'B',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const agreeToTermsFirst = () => {
    toast('Sæt venligst kryds ved accepter vilkårene først, før du fortsætter.', {
      containerId: 'B',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const googleSingIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      localStorage.setItem('return_url', window.location.href);
      const data = `tokenid=${tokenResponse.access_token}`;
      const config = {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      };
      axios
        .post(`${configData.SERVER_URL}/user/auth/google/validate/web`, data, {
          config,
        })
        .then((response2) => {
          setUserProfile(response2.data);
          localStorage.setItem('user_profile', JSON.stringify(response2.data));
        })
        .catch((error) => {
          console.error('There was an error in calling api!', error);
        });
      closeModal3();
    },
  });

  const responseFacebook = (response) => {
    localStorage.setItem('return_url', window.location.href);
    const data = `accesstoken=${response.accessToken}&facebookid=${response.id}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`${configData.SERVER_URL}/user/auth/facebook/validate`, data, {
        config,
      })
      .then((response2) => {
        setUserProfile(response2.data);
        localStorage.setItem('user_profile', JSON.stringify(response2.data));
      })
      .catch((error) => {
        console.error('There was an error in calling api!', error);
      });
    closeModal3();
  };

  const closeModal3 = () => {
    setBasicModal3(!basicModal3);
  };

  const getRestaurants = (value) => {
    let token = '';
    let user = localStorage.getItem('user_profile');
    if (user) {
      user = JSON.parse(user);
      token = user.token;
    }

    axios
      .post(`${configData.SERVER_URL}/mobile/restaurant/fetch`, value, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const sorted = sortRestaurants(response.data);

        setRestaurants([...sorted]);
        restaurantContext.setRestaurants([...sorted]);
        restaurantContext.setFetching(false);
      });
  };

  const sortRestaurants = (restaurants) => {
    return restaurants.sort((a, b) => {
      if (a.distance == null || b.distance == null) {
        return 0;
      }
      const distanceA = parseFloat(a?.distance?.replace('km', '') || 0);
      const distanceB = parseFloat(b?.distance?.replace('km', '') || 0);

      return distanceA - distanceB;
    });
  };

  const logOut = (response) => {
    // console.log('log out');
    setUserProfile([]);
    localStorage.removeItem('user_profile');
    localStorage.removeItem('orders');
    googleLogout();
    window.location.replace(window.location.pathname);
  };

  const filterResto = (item) => {
    if (query) {
      return item?.name?.toLowerCase?.()?.includes?.(query?.toLowerCase?.());
    }

    return true;
  };

  return (
    <>
      <NavBar getRestaurants={getRestaurants} />

      <MDBRow className="restaurants_row restaurant_page_restaurants_row">
        <SearchBar query={query} setQuery={setQuery} />

        {restaurants.filter(filterResto).map((restaurant) => (
          <MDBCol sm="12" className="restaurants_col ">
            <div className="restaurants_col_div relative">
              <div
                className={'cursor-pointer'}
                onClick={(e) => {
                  // if (restaurant.status !== 'Open') {
                  //   e.preventDefault();
                  //   e.stopPropagation();
                  //   return;
                  // }
                  if (!restaurant.isdinein && !restaurant.istakeout) {
                    setOrderNotAllowed(true);
                    return;
                  }

                  navigate(`/restaurants/${restaurant.id}`);
                }}
                //href={`/restaurants/${restaurant.id}?isdinein=${restaurant.isdinein}&istakeout=${restaurant.istakeout}`}
              >
                {restaurant.status === 'Open' && (restaurant.isdinein || restaurant.istakeout) ? (
                  <MDBBtn
                    // href={`/restaurants/${restaurant.id}?isdinein=${restaurant.isdinein}&istakeout=${restaurant.istakeout}`}
                    className="starBuff"
                    type="button"
                  >
                    {/* {restaurant.status} */}
                    Åben
                  </MDBBtn>
                ) : (
                  //  <MDBBtn onClick={toggleShow}>Full screen below xl</MDBBtn>

                  <MDBBtn className="starBuff closeStop" type="button">
                    {/* {restaurant.status} */}
                    Lukket
                  </MDBBtn>
                )}
                <img
                  src={restaurant.coverimage}
                  className="img-fluid restaurants_landing"
                  alt=""
                  // style={{ maxWidth: '24rem' }}
                />
              </div>
            </div>
            <MDBCard style={{}} alignment="center">
              <MDBCardBody>
                <span
                  className="restaurant_link cursor-pointer"
                  onClick={(e) => {
                    // if (restaurant.status !== 'Open') {
                    //   e.preventDefault();
                    //   e.stopPropagation();
                    //   return;
                    // }

                    navigate(`/restaurants/${restaurant.id}`);
                  }}
                  // href={`/restaurants/${restaurant.id}?isdinein=${restaurant.isdinein}&istakeout=${restaurant.istakeout}`}
                >
                  <MDBCardTitle>{restaurant.name}</MDBCardTitle>
                </span>
                <MDBCardText>
                  <MDBIcon fas icon="map-marker-alt" />
                  &nbsp;
                  <EllipsisText text={restaurant.address} length={'35'} />
                </MDBCardText>
                <MDBCardText style={{ margin: '-12px 0px 8px 0' }}>
                  <EllipsisText text={restaurant.distance} length={'35'} />
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}

        {restaurants.filter(filterResto).length == 0 && query && !restaurantContext.fetching ? (
          <MDBRow>
            <MDBCol className="text-center">Ingen restaurant fundet</MDBCol>
          </MDBRow>
        ) : null}
      </MDBRow>

      <MDBContainer className="paragraph4_container mt-4">
        <div class="d-flex align-items-center mb-3 ">
          <MDBCol className="paragraph4">
            <MDBTypography tag="h2" className="mb-0">
              <strong>Download app’en og få alle fordelene ved heywaitr</strong>
            </MDBTypography>
            <MDBTypography className="lead mb-0">
              heywaitr er tilgængelig i både App Store & Google Play
            </MDBTypography>
            <MDBCol className="download_here" size="">
              <MDBBtn className="me-2" type="button">
                <MDBCol>
                  <img src="app-store-badge.png" className="img-fluid" alt="..." />
                </MDBCol>
              </MDBBtn>
              <MDBBtn className="me-2" type="button">
                <MDBCol>
                  <img src="google-play-badge.png" className="img-fluid" alt="..." />
                </MDBCol>
              </MDBBtn>
            </MDBCol>
          </MDBCol>
          <MDBCol>
            <img src="Image 2.png" className="img-fluid shadow-4" alt="..." />
          </MDBCol>
        </div>
      </MDBContainer>

      <Footer />

      <MDBModal show={basicModal3} setShow={setBasicModal3} tabIndex="-1">
        <MDBModalDialog className="detail_modal">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {/* <MDBIcon fas icon="sign-in-alt" className="fs-2" /> */}
              </MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={closeModal3}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <>
                <MDBRow className="detail_login">
                  <MDBCol>
                    <MDBCard alignment="center">
                      <MDBCardBody>
                        {/* <img
                        src="/heywaitr.b08c1632.jpg"
                        className='img-thumbnail-detail-mobile'
                        alt='...'
                        style={{   }}
                      />   */}
                        <MDBCardTitle>
                          <MDBTypography tag="h3" className="mb-0 fw-bold">
                            Velkommen tilbage
                            {/* {(typeof mycards !== 'undefined' && mycards !== null)?'g':'ggg'} */}
                          </MDBTypography>
                        </MDBCardTitle>
                        <MDBCardText>
                          Hejsa! Log ind med din heywaitr konto eller
                          <a
                            className="signup_link"
                            // href="/register"
                            onClick={() => {
                              toggleShow2();
                              setTimeout(() => {
                                setToggleCreateAccountModal(!toggleCreateAccountModal);
                              }, 400);
                            }}
                          >
                            &nbsp;Opret en konto
                          </a>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="detail_login">
                  <MDBCol>
                    <MDBCard>
                      <MDBCardBody className="detail_login_input_form">
                        {/* <MDBInputGroup textBefore='Contact Number' className='mb-3'> */}
                        {/* <label for="username">Username</label> */}
                        <input
                          id="username"
                          className="form-control"
                          type="text"
                          placeholder="Email"
                          onChange={(e) => usernameChange(e.target.value)}
                        />
                        <input
                          id="password"
                          className="form-control"
                          type="password"
                          placeholder="Kodeord"
                          onChange={(e) => passwordChange(e.target.value)}
                        />
                        <MDBBtn className="btn-lg detail_login_submit" onClick={() => logIn()}>
                          Log ind
                        </MDBBtn>
                        <p style={{ textAlign: 'center' }}>
                          <MDBTypography>
                            <MDBTypography className="forgotpassword" tag="small">
                              <a
                                // href="/forgot"
                                onClick={() => {
                                  setToggleForgotAccountModal(!toggleForgotAccountModal);
                                  setTimeout(() => {
                                    toggleShow2();
                                  }, 400);
                                }}
                              >
                                Glemt kodeord?
                              </a>
                            </MDBTypography>
                          </MDBTypography>
                        </p>
                        <ToastContainer
                          enableMultiContainer
                          containerId={'A'}
                          position="top-center"
                          autoClose={1000}
                          hideProgressBar={true}
                          newestOnTop={false}
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          className="toasterror"
                        />

                        <MDBRow>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <hr />
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <p style={{ textAlign: 'center' }}>eller</p>
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <hr />
                            </MDBTypography>
                          </MDBCol>
                        </MDBRow>

                        <FacebookLogin
                          appId="2799368613681742"
                          autoLoad={false}
                          fields="name,email,picture"
                          callback={responseFacebook}
                          render={(renderProps) => (
                            <MDBBtn
                              onClick={renderProps.onClick}
                              rounded
                              className="text-dark sign_in_others fs-6"
                              color="light"
                            >
                              <MDBIcon fab icon="facebook-square fs-4" style={{ float: 'left' }} />
                              <span>Log ind med Facebook</span>
                            </MDBBtn>
                          )}
                        />

                        <MDBBtn
                          onClick={() => googleSingIn()}
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <MDBIcon fab icon="google fs-4" style={{ float: 'left' }} />
                          <span>Log ind med Google</span>
                        </MDBBtn>

                        <MDBBtn
                          disabled
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <MDBIcon fab icon="apple fs-4" style={{ float: 'left' }} />
                          <span>Log ind med Apple</span>
                        </MDBBtn>
                        <MDBBtn
                          disabled
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <span>Fortsæt som guest</span>
                        </MDBBtn>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </>
            </MDBModalBody>
            <MDBModalFooter></MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={toggleCreateAccountModal} setShow={setToggleCreateAccountModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Opret konto</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setToggleCreateAccountModal(!toggleCreateAccountModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer className="signin_container" breakpoint="sm">
                <MDBRow className="detail_signin">
                  <MDBCol>
                    <MDBCard alignment="center">
                      <MDBCardBody>
                        <MDBCardTitle>
                          <MDBTypography tag="h3" className="mb-0 fw-bold">
                            Hejsa! Opret konto
                          </MDBTypography>
                        </MDBCardTitle>
                        <MDBCardText>
                          Har du allerede en konto?
                          <a
                            className="signup_link"
                            // href="/login"
                            onClick={() => {
                              setToggleCreateAccountModal(!toggleCreateAccountModal);
                              setTimeout(() => {
                                toggleShow2();
                              }, 400);
                            }}
                          >
                            &nbsp;Log ind
                          </a>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="detail_signin">
                  <MDBCol>
                    <MDBCard>
                      <MDBCardBody className="detail_signin_input_form">
                        <input
                          id="username"
                          className="form-control"
                          type="text"
                          placeholder="Navn"
                          onChange={(e) => usernameChange(e.target.value)}
                        />
                        <input
                          id="email"
                          className="form-control"
                          type="text"
                          placeholder="Email"
                          onChange={(e) => emailChange(e.target.value)}
                        />
                        <input
                          id="contact"
                          className="form-control"
                          type="text"
                          placeholder="Telefonummer"
                          onChange={(e) => contactChange(e.target.value)}
                        />
                        <input
                          id="password"
                          className="form-control"
                          type="password"
                          placeholder="Kodeord"
                          onChange={(e) => passwordChange(e.target.value)}
                        />
                        {/* <input
                          id="confirm_password"
                          className="form-control"
                          type="password"
                          placeholder="Bekræft kodeord"
                          onChange={(e) => confirmPasswordChange(e.target.value)}
                        /> */}
                        <input
                          id="agree_to_terms"
                          type="checkbox"
                          onChange={(e) => agreeTermsChange(e.target.value)}
                        />
                        <label for="agree_to_terms">
                          &nbsp;Ved at oprette en konto accepterer du vores{' '}
                          <a href="">Betingelser</a>
                        </label>
                        <MDBBtn className="btn-lg detail_signin_submit" onClick={() => signUp()}>
                          Opret konto
                        </MDBBtn>
                        <ToastContainer
                          enableMultiContainer
                          containerId={'B'}
                          position="top-center"
                          autoClose={1000}
                          hideProgressBar={true}
                          newestOnTop={false}
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          className="toasterror"
                        />
                        <p style={{ textAlign: 'center' }}></p>
                        {/* <ToastContainer
                          position="top-center"
                          autoClose={1000}
                          hideProgressBar={true}
                          newestOnTop={false}
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          className="toasterror"
                        /> */}

                        {/* <MDBRow>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <hr />
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <p style={{ textAlign: 'center' }}>eller</p>
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <hr />
                            </MDBTypography>
                          </MDBCol>
                        </MDBRow>

                        <FacebookLogin
                          appId="2799368613681742"
                          autoLoad={false}
                          fields="name,email,picture"
                          callback={responseFacebook}
                          render={(renderProps) => (
                            <MDBBtn
                              onClick={renderProps.onClick}
                              rounded
                              className="text-dark sign_in_others fs-6"
                              color="light"
                            >
                              <MDBIcon fab icon="facebook-square fs-4" style={{ float: 'left' }} />
                              <span>Log ind med Facebook</span>
                            </MDBBtn>
                          )}
                        />
                        <MDBBtn rounded className="text-dark sign_in_others fs-6" color="light">
                          <MDBIcon fab icon="google fs-4" style={{ float: 'left' }} />
                          <span>Log ind med Google</span>
                        </MDBBtn>
                        <MDBBtn
                          disabled
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <MDBIcon fab icon="apple fs-4" style={{ float: 'left' }} />
                          <span>Log ind med Apple</span>
                        </MDBBtn>
                        <MDBBtn
                          disabled
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <span>Fortsæt  som guest</span>
                        </MDBBtn> */}
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={toggleForgotAccountModal} setShow={setToggleForgotAccountModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Glemt kodeord?</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setToggleForgotAccountModal(!toggleForgotAccountModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer className="signin_container" breakpoint="sm">
                <MDBRow className="detail_signin">
                  <MDBCol>
                    <MDBCard alignment="center">
                      <MDBCardBody>
                        <MDBCardTitle>
                          <MDBTypography tag="h3" className="mb-0 fw-bold">
                            Hejsa! Opret konto
                          </MDBTypography>
                        </MDBCardTitle>
                        <MDBCardText>
                          Har du allerede en konto?
                          <a
                            className="signup_link"
                            // href="/login"
                            onClick={() => {
                              setToggleForgotAccountModal(!toggleForgotAccountModal);
                              setTimeout(() => {
                                toggleShow2();
                              }, 400);
                            }}
                          >
                            &nbsp;Log ind
                          </a>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="detail_signin">
                  <MDBCol>
                    <MDBCard>
                      <MDBCardBody className="detail_signin_input_form">
                        <input
                          id="email"
                          className="form-control"
                          type="text"
                          placeholder="Email"
                          onChange={(e) => emailChange(e.target.value)}
                        />
                        <MDBBtn
                          className="btn-lg detail_signin_submit"
                          onClick={() => forgotPassword()}
                        >
                          Reset Password
                        </MDBBtn>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
            <MDBModalFooter></MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={orderNotAllowed} staticBackdrop>
        <MDBModalDialog className="detail_modal openhours_modal">
          <MDBModalContent style={{ maxWidth: '600px' }}>
            <MDBModalBody
              className="text-center"
              style={{
                padding: '6rem',
              }}
            >
              <h4>🇩🇰 Det er midlertidigt lukket for online- og app bestillinger</h4>
              <p>
                Vi tager ikke imod online- og app bestillinger lige nu, men hvis du får fat i en af
                ansatte, vil de med glæde hjælpe dig.
              </p>
              <br /> <br />
              <h4>🇬🇧 Online and app orders are temporarily closed</h4>
              <p>
                We are not accepting online and app orders right now, but if you get hold of one of
                the employees, they will be happy to assist you.
              </p>
              <MDBBtn
                color="success p-1 my-4"
                className="add_to_cart"
                style={{
                  width: '150px',
                  height: '40px',
                }}
                onClick={() => {
                  setOrderNotAllowed(false);
                }}
              >
                Luk
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBRow className="navigation_bar_small">
        <MDBCol size="4">
          <a href="/">
            <MDBIcon fas icon="home" />
          </a>
        </MDBCol>
        <MDBCol size="4">
          {localStorage.getItem('user_profile') !== undefined &&
          localStorage.getItem('user_profile') !== null ? (
            <a href="/orders">
              <MDBIcon fas icon="utensils" />
            </a>
          ) : (
            <MDBIcon fas icon="utensils" />
          )}
        </MDBCol>
        <MDBCol size="4">
          {(localStorage.getItem('user_profile') !== undefined &&
            localStorage.getItem('user_profile') !== null) |
          (userProfile.length !== 0 && userProfile !== null) ? (
            <a href="/profile">
              <MDBIcon fas icon="user-alt" />
            </a>
          ) : (
            <MDBIcon fas icon="user-alt" />
          )}
        </MDBCol>
      </MDBRow>
    </>
  );
}

function SearchBar(props) {
  const { query, setQuery } = props;

  return (
    <div className="search-div">
      <div className="relative search-bar">
        <input
          className=""
          placeholder="Søg efter et sted..."
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
        <div className="addon">
          Søg
          <i className="fa  fa-search" />
        </div>
      </div>
    </div>
  );
}
