import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import configData from '../../config.json';
// import FacebookLogin from 'react-facebook-login';
// import { useNavigate } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {
  // GoogleLogin,
  useGoogleLogin,
  googleLogout,
} from '@react-oauth/google';
import DatePicker from 'react-datepicker';
// import TimePicker from 'react-time-picker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
// import GoogleLogin from 'react-google-login';
// import Carousel from 'react-multi-carousel';
// import Carousel2 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import Sticky from 'react-sticky-el';
import Sticky2 from 'react-sticky-el';
// import Sticky3 from 'react-sticky-el';
import ScrollSpyTabs from '../../components/ScrollSpyTabs';
// import ScrollspyNav from 'react-scrollspy-nav';
import {
  MDBNavbar,
  MDBCheckbox,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBTabs,
  MDBTabsItem,
  MDBValidationItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRadio,
  MDBBtnGroup,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBTypography,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBInputGroup,
  // MDBPagination, MDBPaginationItem, MDBPaginationLink ,
  // MDBCarousel,
  // MDBCarouselInner,
  // MDBCarouselItem,
  // MDBCarouselElement,
  // MDBCarouselCaption,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { MultiSelect } from 'primereact/multiselect';
// import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import axios from 'axios';

import './styles.css';
import '../../components/ScrollSpyTabs.css';
import Footer from '../../components/Footer';
// import ReduxCounter from './ReduxCounter';
import Select from 'react-select';
// import res from 'express/lib/response';
import { chain, cloneDeep, isEmpty, sortBy, uniqueId } from 'lodash';
// import { Watch } from 'react-loader-spinner';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import ProcessingOrder from '../../lottie_processing.js';
import NavBar from '../../components/Navbar';
import { useNotificationContext } from '../../context/notification';
import { useOrderContext } from '../../context/order';
import useWindowSize from '../../utils/useWindowSize';
import ReviewModal from './ReviewModal.js';
import Loader from '../../components/Loader.js';

function useForceUpdate() {
  const [, forceUpdate] = React.useReducer(() => ({}), {});
  return forceUpdate;
}

function useScript(url, name) {
  const [lib, setLib] = useState({});

  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = () => setLib({ [name]: window[name] });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return lib;
}

function useIsOverflow(ref, callback) {
  const [isOverflow, setIsOverflow] = React.useState(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
}

export default function Detail() {
  const forceUpdate = useForceUpdate();
  const broadcast = new BroadcastChannel('notif-channel');
  const notificationContext = useNotificationContext();
  const orderContext = useOrderContext();
  const size = useWindowSize();
  const isMobileView = size.width <= 1023;

  let the_url = window.location.pathname;
  localStorage.setItem('return_url', the_url);
  let parts = the_url.split('/');
  let the_url_id = parts.pop();
  let urlid = {
    id: the_url_id,
  };

  const { Reepay } = useScript('https://checkout.reepay.com/checkout.js', 'Reepay');

  // const [additionalPrice, setAdditionalPrice] = useState(0);
  const [validPhone, setValidPhone] = useState(true);
  const [validCard, setValidCard] = useState(true);
  const [validTable, setValidTable] = useState(true);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [basicModal2mc, setBasicModal2mc] = useState(false);
  const [basicModal3, setBasicModal3] = useState(false);
  const [basicModal4, setBasicModal4] = useState(false);
  const [basicModal5, setBasicModal5] = useState(false);
  const [nowOrLaterModal, setNowOrLaterModal] = useState(false);
  const [pickupDate, setPickupDate] = useState(new Date());
  const [toggleCreateAccountModal, setToggleCreateAccountModal] = useState(false);
  const [startTime, setstartTime] = useState(new Date());
  const [pickupTime, setPickupTime] = useState(
    `${new Date().getHours()}:${new Date().getMinutes()}`,
  );
  // const [value, onChange] = useState(`${new Date().getHours()}:${new Date().getMinutes()}`);
  // const [fillActive, setFillActive] = useState('tab1');
  const [isDineIn, setIsDineIn] = useState(true);
  const [pickUp, setpickUp] = useState('now');
  const [restaurants, setRestaurantDetails] = useState({});
  const [categories, setCategories] = useState([]);
  const [restaurantTips, setRestaurantTips] = useState([]);
  const [uniqueCategoriesFull, setUniqueCategoriesFull] = useState([]);
  const [uniqueCategoriesVisible, setUniqueCategoriesVisible] = useState([]);
  // const [chunkTabs, setChunkTabs] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [menuItemsCopy, setMenuItemsCopy] = useState([]);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [totalOrders, setTotalOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [tipPrice, setTipPrice] = useState([]);
  const [tipId, setTipId] = useState([]);
  const [checkout_items, setCheckOutItems] = useState([]);
  const [toggleTwoModal, setToggleTwoModal] = useState(false);
  const [tableNumbers, setTableNumbers] = useState([]);
  // const [reepayUrl, setReePayUrl] = useState('');
  const [userProfile, setUserProfile] = useState({});
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [final_notes, setNote] = useState('');
  const [final_contact, setContact] = useState('');
  const [final_table, setTable] = useState('');
  const [mycards, setCards] = useState({});
  const [card_to_use, setCardToUse] = useState('');
  const [pay_confirm, setPayConfirm] = useState({});
  const [restaurant_id, setRestaurantId] = useState([]);
  const [email, setEmail] = useState('');
  const [contact, setContactSignUp] = useState('');
  const [agree_to_terms, setAgreeTerms] = useState('');
  const [restaurantInfo, setRestaurantInfo] = useState({
    dineInSupported: true,
    takeOutSupported: true,
    advanceOrderSupported: true,
  });

  const [counter, setCounter] = useState(1);
  const incrementCounter = () => setCounter(counter + 1);

  const [selectedhighercat, setselectedhighercat] = useState({});
  const [poolRunningPending, setPoolRunningPending] = useState(false);
  const [poolRunningConfirmed, setPoolRunningConfirmed] = useState(false);
  const [sortedCategories, setSortedCategories] = useState([]);
  const [orderNotAllowed, setOrderNotAllowed] = useState(false);
  const [menuNotAvailable, setMenuNotAvailable] = useState(false);
  const [higherCategoriesLoaded, setHigherCategoriesLoaded] = useState(false);

  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false);
  const [rushModeModal, setRushModeModal] = useState(false);

  const [guestInfo, setGuestInfo] = useState({});
  const [guestInfoValidation, setGuestInfoValidation] = useState({});

  const [loading, setLoading] = useState(false);
  const [hasBag, setHasBag] = useState(false);

  const orderPendingPoolRef = useRef();
  const orderConfirmedPoolRef = useRef();
  const highercatRequestsRef = useRef([]);
  const catRequestsRef = useRef([]);

  const [lagTime, setLagTime] = useState(30);

  const higherCatRef = React.useRef();
  const isHigherCatOverflow = useIsOverflow(higherCatRef);

  useEffect(() => {
    if (notificationContext?.closePendingModal) {
      setBasicModal4(false);
      if (notificationContext?.setClosePendingModal) {
        notificationContext.setClosePendingModal(false);
      }
    }
  }, [notificationContext?.closePendingModal]);

  useLayoutEffect(() => {
    if (orderContext?.pendingOrder?._id) {
      if (!orderContext?.pendingOrder.status) {
        if (!basicModal4) {
          setTimeout(() => {
            setPayConfirm({ ...orderContext?.pendingOrder });
            setBasicModal4(true);
          }, 1000);
        }
      } else if (orderContext?.pendingOrder.status == 'confirmed') {
        setPayConfirm({ ...orderContext?.pendingOrder });
      }
    }
  }, [orderContext?.pendingOrder?._id]);

  useEffect(() => {
    getRestaurantDetails(urlid.id);
    getTableNumbers(urlid);
    getCategories(urlid);
    getFirstDisplay(urlid);
    getRestaurantConfig(urlid);
    checkForSavedPreorderInfo();
    checkUsersMobile();

    checkNavbarToBeHidden();
    fetchLagTime();

    return () => {
      clearInterval(orderPendingPoolRef.current);
      clearInterval(orderConfirmedPoolRef.current);

      localStorage.removeItem('orders');
      // localStorage.removeItem('return_url');
    };
  }, []);

  useEffect(() => {
    getUniqueCategories(urlid);
  }, [categories]);

  useEffect(() => {
    calculatePrice();
  }, [totalOrders, isDineIn, hasBag]);

  useEffect(() => {
    if (!basicModal) {
      setCurrentOrder([]);
    }
  }, [basicModal]);

  useEffect(() => {
    if (pay_confirm?._id) {
      if (!pay_confirm?.status) {
        poolPendingOrder();
      } else if (pay_confirm?.status == 'confirmed') {
        poolConfirmedOrder();
      }
    }
  }, [pay_confirm]);

  useEffect(() => {
    if (!poolRunningPending) {
      console.log('STOPPING PENDING');
      clearInterval(orderPendingPoolRef.current);
      orderPendingPoolRef.current = null;
    }
  }, [poolRunningPending]);

  useEffect(() => {
    if (!poolRunningConfirmed) {
      console.log('STOPPING CONFIRMED');
      clearInterval(orderConfirmedPoolRef.current);
      orderConfirmedPoolRef.current = null;
    }
  }, [poolRunningConfirmed]);

  useEffect(() => {
    if (restaurants?._id) {
      if (!isDineIn && pickUp == 'now' && restaurantInfo.takeOutSupported) {
        if (!isOpenThisTime()) {
          if (size?.width > 1023) {
            setOrderNotAllowed(!orderNotAllowed);
          }
        }
      }
    }
  }, [isDineIn, pickUp, restaurantInfo?.takeOutSupported, restaurants]);

  useEffect(() => {
    getLastestAvailableTime();
  }, [restaurants]);

  useEffect(() => {
    checkUsersMobile();
  }, [userProfile]);

  useEffect(() => {
    if (categories.length == 0) {
      // Meaning this restaurant doesnt have higher category, then just refetch the resto info t
      // to get the "isOpen" key

      if (!isDineIn && pickUp == 'later') {
        // Advance order
        const date = pickupDate;
        const time = startTime;

        const dateDate = moment(date).format('MM/DD/YYYY');
        const dateTime = moment(time).format('HH:mm');

        refetchRestoAvailability(`${dateDate} ${dateTime}`);
      } else {
        // order asap or dinein
        refetchRestoAvailability();
      }

      return;
    }
    if (!isDineIn && pickUp == 'later') {
      // Advance order
      const date = pickupDate;
      const time = startTime;

      const dateDate = moment(date).format('MM/DD/YYYY');
      const dateTime = moment(time).format('HH:mm');

      getCategories(urlid, `${dateDate} ${dateTime}`);
    } else {
      // order asap or dinein
      getCategories(urlid);
    }
  }, [pickupDate, startTime, isDineIn, pickUp]);

  useEffect(() => {
    // Check items on cart if it is still available
    if (higherCategoriesLoaded) {
      checkItemsFromCart(categories, sortedCategories, restaurants);
    }
  }, [categories, sortedCategories, restaurants, higherCategoriesLoaded]);

  useEffect(async () => {
    setIsApplePayAvailable(await Reepay.isApplePayAvailable());
    setIsGooglePayAvailable(await Reepay.isGooglePayAvailable());
  }, [Reepay]);

  useEffect(() => {
    if (orderContext.reviewModal) {
      const my_orders = JSON.parse(localStorage.getItem('orders'));
      setCheckOutItems(my_orders);
      setBasicModal2(!basicModal2);
    }
  }, [orderContext.reviewModal]);

  useEffect(() => {
    if (!basicModal2) {
      orderContext.setReviewModal(false);
    }
  }, [basicModal2]);

  useEffect(() => {
    if (!isEmpty(selectedhighercat) && !isEmpty(restaurants)) {
      checkForOrderTypeDefaulting();
    }
  }, [selectedhighercat?.Category, restaurants]);

  const isGuest = React.useMemo(() => {
    return !userProfile.token && orderContext.isGuest;
  }, [userProfile, orderContext.isGuest]);

  useEffect(() => {
    if (restaurants.RushMode) {
      setRushModeModal(true);
    }
  }, [restaurants?._id]);

  const refetchRestoAvailability = (date) => {
    const userProfile = JSON.parse(localStorage.getItem('user_profile'));

    axios
      .post(
        `${configData.SERVER_URL}/mobile/restaurant/fetchspecific?id=${the_url_id}`,
        {
          id: urlid.id,
          date: date || null,
        },
        { headers: { Authorization: `Bearer ${userProfile?.token}` } },
      )
      .then((response) => {
        // setRestaurantDetails(response.data);
        restaurants.isOpen = response.data.isOpen;
        forceUpdate();
      });
  };

  //-----FOR PENDING--------
  const poolPendingOrder = () => {
    setPoolRunningPending(true);
    orderPendingPoolRef.current = setInterval(() => {
      checkPendingOrder();
    }, 5000);
  };

  const checkPendingOrder = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/orders/checkstatus?id=${pay_confirm._id}`,
        data: null,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      });

      const { data } = response;
      console.log('CHECK PENDING', data);
      if (data?.type == 'order_accepted') {
        updatePendingCache('confirmed');
        setPayConfirm({
          ...pay_confirm,
          status: 'confirmed',
        });
        setBasicModal4(false);
        stopPoolingPending();
        if (!notificationContext.notificationModal.open) {
          notificationContext.setNotificatioModal({
            open: true,
            notification: {
              notification: {
                body: data.message,
              },
            },
          });
        }
      } else if (data?.type == 'order_cancelled') {
        clearPendingCache();
        setPayConfirm({});
        setBasicModal4(false);
        stopPoolingPending();
        if (!notificationContext.notificationModal.open) {
          notificationContext.setNotificatioModal({
            open: true,
            notification: {
              notification: {
                body: data.message || data.reason,
              },
            },
          });
        }
      } else if (data?.type == 'order_completed') {
        clearPendingCache();
        setPayConfirm({});
        setBasicModal4(false);
        stopPoolingPending();
        if (!notificationContext.notificationModal.open) {
          notificationContext.setNotificatioModal({
            open: true,
            notification: {
              notification: {
                body: data.message || data.reason,
              },
            },
          });
        }
      }

      return;
    } catch (ex) {
      console.log('checkPendingOrder', ex.message);
    }
  };

  const stopPoolingPending = () => {
    setPoolRunningPending(false);
  };

  //-----FOR CONFIRMED--------
  const poolConfirmedOrder = () => {
    setPoolRunningConfirmed(true);
    orderConfirmedPoolRef.current = setInterval(() => {
      checkConfirmedOrder();
    }, 5000);
  };

  const checkConfirmedOrder = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/orders/checkstatus?id=${pay_confirm._id}`,
        data: null,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      });

      const { data } = response;
      console.log('CHECK CONFIRMED', data);
      if (data?.type == 'order_completed') {
        clearPendingCache();
        setPayConfirm({});
        stopPoolingConfirm();
        if (!notificationContext.notificationModal.open) {
          notificationContext.setNotificatioModal({
            open: true,
            notification: {
              notification: {
                body: data.message,
              },
            },
          });
        }
      }

      return;
    } catch (ex) {
      console.log('checkPendingOrder', ex.message);
    }
  };

  const stopPoolingConfirm = () => {
    setPoolRunningConfirmed(false);
  };

  const updatePendingCache = (status) => {
    const cache = JSON.parse(localStorage.getItem('hw_pending_order'));
    const newcache = { ...cache, status };
    localStorage.setItem('hw_pending_order', JSON.stringify(newcache));
  };

  const clearPendingCache = () => {
    localStorage.removeItem('hw_pending_order');
  };

  const emailChange = (value) => {
    setEmail(value);
  };

  const contactChangeSignUp = (value) => {
    setContactSignUp(value);
  };

  const agreeTermsChange = (value) => {
    setAgreeTerms(value);
  };

  const signUp = () => {
    if (agree_to_terms) {
      if (email == '' || username == '' || password == '') {
        checkRequiredFields();
      } else {
        const data = `email=${email}&name=${username}&password=${password}&mobilenumber=${contact}`;
        const config = {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        };
        axios
          .post(`${configData.SERVER_URL}/user/register`, data, { config })
          .then((response) => {
            if (response.data.Message == 'User successfully registered.') {
              setUserProfile(response.data);
              localStorage.setItem('user_profile', JSON.stringify(response.data));
              // console.log(response.data);
              setToggleCreateAccountModal(false);
            } else {
              notifyError();
            }
          })
          .catch((error) => {
            // error.response.data
            emailExisting();
          });
      }
    } else {
      agreeToTermsFirst();
    }
  };

  const googleSingIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      localStorage.setItem('return_url', window.location.href);
      const data = `tokenid=${tokenResponse.access_token}`;
      const config = {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      };
      axios
        .post(`${configData.SERVER_URL}/user/auth/google/validate/web`, data, {
          config,
        })
        .then((response2) => {
          setUserProfile(response2.data);
          localStorage.setItem('user_profile', JSON.stringify(response2.data));
        })
        .catch((error) => {
          console.error('There was an error in calling api!', error);
        });
      closeModal3();
    },
  });

  const responseFacebook = (response) => {
    localStorage.setItem('return_url', window.location.href);
    const data = `accesstoken=${response.accessToken}&facebookid=${response.id}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`${configData.SERVER_URL}/user/auth/facebook/validate`, data, {
        config,
      })
      .then((response2) => {
        setUserProfile(response2.data);
        localStorage.setItem('user_profile', JSON.stringify(response2.data));
      })
      .catch((error) => {
        console.error('There was an error in calling api!', error);
      });
    closeModal3();
  };

  const changeDineIn = (response, target) => {
    setValidTable(true);

    if (response == 'pickup' && restaurantInfo.takeOutSupported) {
      setIsDineIn(false);
      try {
        document.getElementById('sidebar_checkout_card_id').style.maxHeight = '200px';
      } catch {}
    } else if (response == 'dine' && restaurantInfo.dineInSupported) {
      setIsDineIn(true);
      try {
        document.getElementById('sidebar_checkout_card_id').style.maxHeight = '400px';
      } catch {}
    }
  };

  const changePickupDate = (response) => {
    if (response == 'now') {
      setpickUp('now');
    } else {
      if (!restaurantInfo.advanceOrderSupported) {
        return;
      }
      setpickUp('later');
    }
  };

  const removeFromCart = (product_id) => {
    var indexToRemove;
    checkout_items.forEach((v, i) => {
      if (v.orders[0] == product_id) {
        indexToRemove = i;
        // SyntaxError: Illegal break statement
        // break;
      }
    });
    let y = checkout_items;
    y.splice(indexToRemove, 1);
    setCheckOutItems(y);
    let z = totalOrders;
    z.splice(indexToRemove, 1);
    setTotalOrders(z);
    let x = JSON.parse(localStorage.getItem('orders'));
    x.splice(indexToRemove, 1);
    localStorage.setItem('orders', JSON.stringify(x));
    calculatePrice();
  };

  const notifyError = () => {
    toast('Invalid Credentials!', {
      containerId: 'A',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const emailExisting = () => {
    toast('Email allerede taget.', {
      containerId: 'B',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const checkRequiredFields = () => {
    toast('Navn, e-mail og adgangskode er påkrævet.', {
      containerId: 'B',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const agreeToTermsFirst = () => {
    toast('Sæt venligst kryds ved accepter vilkårene først, før du fortsætter.', {
      containerId: 'B',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const incrementCounter2 = (product_id) => {
    let x = parseFloat(document.getElementById(product_id).value) + 1;
    document.getElementById(product_id).value = x;
    const product_id_arr = product_id.split('index');
    const orders_tmp1 = JSON.parse(localStorage.getItem('orders'));
    let gio = orders_tmp1.map((order_tmp1) => {
      if (order_tmp1.orders[0] == product_id_arr[0]) {
        let z = order_tmp1;
        let y = z.orders;
        z.orders[5] = document.getElementById(product_id).value;
        return z;
      } else {
        return order_tmp1;
      }
    });
    // console.log('inc2');
    // console.log(gio);
    localStorage.setItem('orders', JSON.stringify(gio));
    setTotalOrders(gio);
    setCheckOutItems(gio);
  };

  const toggleShowNowOrLaterModal = () => setNowOrLaterModal(!nowOrLaterModal);

  const incrementCounter3 = (product_id) => {
    // let x = parseFloat(document.getElementById(product_id).value) + 1;
    // document.getElementById(product_id).value = x;
    let newcurrentOrder = currentOrder.map((orderTmp) => {
      orderTmp.orders[6].map((orderTmp2) => {
        if (product_id == orderTmp2._id) {
          orderTmp2.quantity += 1;
        }
      });

      return orderTmp;
    });
    setCurrentOrder([...newcurrentOrder]);
  };

  const decrementCounter3 = (product_id) => {
    // let x = parseFloat(document.getElementById(product_id).value) - 1;
    // if (x < 0) {
    //   document.getElementById(product_id).value = 1;
    // } else {
    //   document.getElementById(product_id).value = x;
    // // }
    // document.getElementById(product_id).value = x;
    let newcurrentOrder = currentOrder.map((orderTmp) => {
      orderTmp.orders[6].map((orderTmp2) => {
        if (product_id == orderTmp2._id) {
          if (orderTmp2.quantity > 0) {
            orderTmp2.quantity -= 1;
          }
        }
      });

      return orderTmp;
    });

    setCurrentOrder([...newcurrentOrder]);
  };

  const incrementCounter3mc = (product_id) => {
    var product_idx = product_id.replace('hatsune', '');
    let newcurrentOrder = currentOrder.map((orderTmp) => {
      orderTmp.orders[6].map((orderTmp2) => {
        if (product_idx == orderTmp2._id) {
          orderTmp2.quantity += 1;
        }
      });

      return orderTmp;
    });
    setCurrentOrder([...newcurrentOrder]);
    // console.log(product_id);
    // if (product_id.includes('raikiri')) {
    //   let product_idx = product_id.replace('raikiri', '');
    //   let x = parseFloat(document.getElementById(product_id).value) + 1;

    //   document.getElementById(product_id).value = x;
    //   let product_extra_2 = currentOrder.map((orderTmp) => {
    //     orderTmp.orders[6].map((orderTmp2) => {
    //       // console.log(x);
    //       if (product_idx == orderTmp2._id) {
    //         // console.log('equal');
    //         orderTmp2.quantity = x;
    //       }
    //     });
    //   });
    // } else {
    //   let product_idx = product_id.replace('hatsune', '');
    //   let x = parseFloat(document.getElementById(product_id).value) + 1;
    //   document.getElementById(product_id).value = x;
    //   let product_extra_2 = currentOrder.map((orderTmp) => {
    //     orderTmp.orders[6].map((orderTmp2) => {
    //       if (product_idx == orderTmp2._id) {
    //         orderTmp2.quantity = x;
    //       }
    //     });
    //   });
    // }
  };

  const decrementCounter3mc = (product_id) => {
    var product_idx = product_id.replace('hatsune', '');
    let newcurrentOrder = currentOrder.map((orderTmp) => {
      orderTmp.orders[6].map((orderTmp2) => {
        if (product_idx == orderTmp2._id) {
          if (orderTmp2.quantity > 0) {
            orderTmp2.quantity -= 1;
          }
        }
      });

      return orderTmp;
    });

    setCurrentOrder([...newcurrentOrder]);
    // console.log(product_id);
    // if (product_id.includes('raikiri')) {
    //   let product_idx = product_id.replace('raikiri', '');
    //   let x = parseFloat(document.getElementById(product_id).value) - 1;
    //   // console.log('gio');
    //   // console.log(x);

    //   if (x < 0) {
    //     document.getElementById(product_id).value = 1;
    //   } else {
    //     document.getElementById(product_id).value = x;
    //   }
    //   document.getElementById(product_id).value = x;
    //   let product_extra_2 = currentOrder.map((orderTmp) => {
    //     orderTmp.orders[6].map((orderTmp2) => {
    //       if (product_idx == orderTmp2._id) {
    //         orderTmp2.quantity = x;
    //       }
    //     });
    //   });
    // } else {
    //   let product_idx = product_id.replace('hatsune', '');
    //   let x = parseFloat(document.getElementById(product_id).value) - 1;
    //   if (x < 0) {
    //     document.getElementById(product_id).value = 1;
    //   } else {
    //     document.getElementById(product_id).value = x;
    //   }
    //   document.getElementById(product_id).value = x;
    //   let product_extra_2 = currentOrder.map((orderTmp) => {
    //     orderTmp.orders[6].map((orderTmp2) => {
    //       if (product_idx == orderTmp2._id) {
    //         orderTmp2.quantity = x;
    //       }
    //     });
    //   });
    // }
  };

  let decrementCounter = () => setCounter(counter - 1);

  if (counter <= 0) {
    decrementCounter = () => setCounter(1);
  }

  const decrementCounter2 = (product_id) => {
    const product_id_arr2 = product_id.split('index');
    let x = parseFloat(document.getElementById(product_id).value) - 1;
    if (x <= 0) {
      document.getElementById(product_id).value = 1;
    } else {
      document.getElementById(product_id).value = x;
    }
    const orders_tmp1 = JSON.parse(localStorage.getItem('orders'));
    let gio = orders_tmp1.map((order_tmp1) => {
      if (order_tmp1.orders[0] == product_id_arr2[0]) {
        let z = order_tmp1;
        let y = z.orders;
        z.orders[5] = document.getElementById(product_id).value;
        return z;
      } else {
        return order_tmp1;
      }
    });
    localStorage.setItem('orders', JSON.stringify(gio));
    setTotalOrders(gio);
    setCheckOutItems(gio);
  };

  const closeModal = () => {
    setCurrentOrder([]);
    setBasicModal(!basicModal);
  };

  const closeModal3 = () => {
    setBasicModal3(!basicModal3);
  };

  const closeModal4 = () => {
    setBasicModal4(!basicModal4);
    setCurrentOrder([]);
    setTotalOrders([]);
    setTotalPrice([]);
    setCheckOutItems([]);
    localStorage.removeItem('orders');
  };

  const clearCartItems = () => {
    setCurrentOrder([]);
    setTotalOrders([]);
    setTotalPrice([]);
    setCheckOutItems([]);
    // localStorage.clear();
    localStorage.removeItem('orders');
    // window.location.replace(window.location.pathname);
    // setBasicModal2(!basicModal2);
  };

  const closeModal2 = () => {
    setBasicModal2(!basicModal2);
  };

  const closeModal2mc = () => {
    setBasicModal2mc(!basicModal2mc);
  };

  const saveModal = () => {
    let my_orders = localStorage.getItem('orders');
    if (typeof my_orders !== 'undefined' && my_orders !== null) {
      const orders_tmp1 = JSON.parse(localStorage.getItem('orders'));
      let order_tmp5 = document.getElementById('product_quantity');
      let order_tmp6 = currentOrder;
      order_tmp6[0].orders[5] = parseFloat(order_tmp5.value);
      order_tmp6[0].orders.push(selectedhighercat._id || '');
      orders_tmp1.push(order_tmp6[0]);
      localStorage.setItem('orders', JSON.stringify(orders_tmp1));
      setTotalOrders(orders_tmp1);
    } else {
      let order_tmp4 = document.getElementById('product_quantity');
      let order_tmp3 = currentOrder;
      order_tmp3[0].orders[5] = parseFloat(order_tmp4.value);
      order_tmp3[0].orders.push(selectedhighercat._id || '');
      localStorage.setItem('orders', JSON.stringify(order_tmp3));
      setTotalOrders(order_tmp3);
    }
    setCurrentOrder([]);
    setCounter(1);
    setBasicModal(!basicModal);
  };

  const saveModal2 = () => {
    setBasicModal2(!basicModal2);
  };

  const toggleShow = (
    product_id,
    product_name,
    product_price,
    product_description,
    product_image,
    product_quantity,
    product_extras,
    product_attrbs,
  ) => {
    let product_extra_2 = product_extras.map((product_extra) => {
      product_extra.quantity = 0;
      return product_extra;
    });
    let product_attributes_2 = product_attrbs.map((product_attrb) => {
      product_attrb.options.map((option) => {
        option.quantity = 0;
      });
      return product_attrb;
    });
    let hash = Math.random().toString(16).substr(2, 14);
    setCurrentOrder([
      {
        orders: [
          product_id + '#' + hash,
          product_name,
          product_price,
          product_description,
          product_image,
          product_quantity,
          product_extra_2,
          product_attributes_2,
          0,
        ],
      },
    ]);
    setBasicModal(!basicModal);
  };

  const toggleShow2 = () => {
    setBasicModal5(false);
    getRestaurantTips(localStorage.getItem('restaurant_id'));

    const userProfile = JSON.parse(localStorage.getItem('user_profile'));

    if (userProfile !== undefined && userProfile !== null && userProfile.profile) {
      const my_orders = JSON.parse(localStorage.getItem('orders'));
      // console.log('my orders');
      // console.log(my_orders);
      //card data
      axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/orders/v2/pmlists?restaurant=${localStorage.getItem(
          'restaurant_id',
        )}`,
        data: null,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      }).then((response) => {
        setCards(response.data);
      });
      setCheckOutItems(my_orders);
      // console.log(checkout_items);
      setBasicModal2(!basicModal2);
    } else {
      orderContext.setSignupModal(true);

      // console.log(checkout_items);
      // setBasicModal3(!basicModal3);
      // localStorage.setItem("return_to_restaurant",the_url_id);
      // navigate(`/login`);
    }
  };

  const toggleShow2mc = () => {
    setBasicModal5(false);
    getRestaurantTips(localStorage.getItem('restaurant_id'));

    const userProfile = JSON.parse(localStorage.getItem('user_profile'));

    if (userProfile !== undefined && userProfile !== null && userProfile.profile) {
      const my_orders = JSON.parse(localStorage.getItem('orders'));
      // console.log('my orders');
      // console.log(my_orders);
      //card data
      axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/orders/v2/pmlists?restaurant=${localStorage.getItem(
          'restaurant_id',
        )}`,
        data: null,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      }).then((response) => {
        setCards(response.data);
      });
      setCheckOutItems(my_orders);
      setBasicModal2mc(!basicModal2mc);
    } else {
      setBasicModal3(!basicModal3);
    }
  };

  const getRestaurantTips = (value) => {
    const userProfile = JSON.parse(localStorage.getItem('user_profile'));

    axios({
      method: 'GET',
      url: `${configData.SERVER_URL}/tips/mobile/fetch?restaurant=${value}`,
      data: null,
      headers: {
        Authorization: `Bearer ${userProfile?.token}`,
      },
    }).then((response) => {
      setRestaurantTips(response.data);
    });
  };

  const getRestaurantDetails = (value) => {
    localStorage.setItem('return_url', window.location.href);
    let the_url = window.location.pathname;
    let parts = the_url.split('/');
    let the_url_id = parts.pop();
    localStorage.setItem('restaurant_id', the_url_id);
    setRestaurantId(the_url_id);
    const userProfile = JSON.parse(localStorage.getItem('user_profile'));

    if (
      typeof localStorage.getItem('user_profile') !== 'undefined' &&
      localStorage.getItem('user_profile') !== null
    ) {
      setUserProfile(JSON.parse(localStorage.getItem('user_profile')));
    }
    if (
      typeof localStorage.getItem('orders') !== 'undefined' &&
      localStorage.getItem('orders') !== null
    ) {
      setTotalOrders(JSON.parse(localStorage.getItem('orders')));
      // calculatePrice();
    }
    axios
      .post(
        `${configData.SERVER_URL}/mobile/restaurant/fetchspecific?id=${the_url_id}`,
        {
          id: value,
        },
        { headers: { Authorization: `Bearer ${userProfile?.token}` } },
      )
      .then((response) => {
        response.data.availability = response.data.AvailabilityOriginal;

        setIsDineIn(response?.data?.isdinein || false);

        setRestaurantInfo({
          takeOutSupported: response?.data?.istakeout || false,
          dineInSupported: response?.data?.isdinein || false,
          advanceOrderSupported: response?.data?.allowAdvanceOrder || false,
        });

        setRestaurantDetails(response.data);
        //restaurant detail
        let restaurantSchedule = [];
        restaurantSchedule.push(response.data.availability.Mon);
        restaurantSchedule.push(response.data.availability.Tue);
        restaurantSchedule.push(response.data.availability.Wed);
        restaurantSchedule.push(response.data.availability.Thu);
        restaurantSchedule.push(response.data.availability.Fri);
        restaurantSchedule.push(response.data.availability.Sat);
        restaurantSchedule.push(response.data.availability.Sun);
        var firstitem = true;
        var prevItem = '';
        var startIndex = 0;
        var restaurantSchedule2 = [];
        restaurantSchedule.forEach((value, index) => {
          if (index > 0) {
            if (prevItem.start == value.start && prevItem.end == value.end) {
              if (index == 6) {
                if (startIndex == index) {
                  restaurantSchedule2.push(startIndex);
                } else {
                  restaurantSchedule2.push(startIndex + 'to' + index);
                }
              }
            } else {
              if (startIndex == index - 1) {
                restaurantSchedule2.push(startIndex);
              } else {
                restaurantSchedule2.push(startIndex + 'to' + (index - 1));
              }
              startIndex = index;
            }
          }
          prevItem = value;
        });
      });
  };

  const getRestaurantConfig = () => {
    const params = new URLSearchParams(window.location.search);
    const isdinein = params.get('isdinein');
    const istakeout = params.get('istakeout');

    // if (isdinein != 'true') {
    //   setIsDineIn(false);
    // }

    // setRestaurantInfo({
    //   takeOutSupported: istakeout == 'true',
    //   dineInSupported: isdinein == 'true',
    // });
  };

  const getCategories = async (value, datetime) => {
    //prevent race condition
    const reqId = uniqueId('REQUEST');
    highercatRequestsRef.current.push(reqId);

    axios
      .get(
        `${configData.SERVER_URL}/highercategory/fetch?restaurant=${the_url_id}${
          datetime ? `&date=${datetime}` : ''
        }`,
      )
      .then((response) => {
        const index = highercatRequestsRef.current.findIndex((a) => a == reqId);
        if (index > -1) {
          highercatRequestsRef.current.splice(index, 1);
        }
        if (highercatRequestsRef.current.length > 0) {
          return;
        }
        let curday = moment().format('ddd');
        let date = datetime || '';
        if (datetime) {
          curday = moment(datetime).format('ddd');
        }

        const responseData = (response?.data || []).map((item) => {
          const availability = properAvailability(item?.AvailabilityCopy || {});

          item = {
            ...item,
            start: availability[curday].start,
            end: availability[curday].end,
            isAvailable: getStatus2(item, '', curday, date),
          };

          return item;
        });

        setCategories([...responseData]);
        if (response.data?.[0]) {
          if (isEmpty(selectedhighercat)) {
            setselectedhighercat({ ...responseData?.[0] });
          } else {
            // Meaning there is already a selected cat then just update it entirely
            const selected = responseData.find((a) => a._id == selectedhighercat._id);

            if (selected) {
              selectedhighercat.isAvailable = selected.isAvailable;
              setselectedhighercat(selectedhighercat);
              // setselectedhighercat({ ...selectedhighercat, isAvailable: selected.isAvailable });
            }
          }
        }
      });

    //prevent race condition
    const reqId2 = uniqueId('REQUESTB');
    catRequestsRef.current.push(reqId2);

    axios
      .get(`${configData.SERVER_URL}/menu/category/fetch?restaurant=${the_url_id}`)
      .then((response) => {
        const index = catRequestsRef.current.findIndex((a) => a == reqId2);
        if (index > -1) {
          catRequestsRef.current.splice(index, 1);
        }
        if (catRequestsRef.current.length > 0) {
          return;
        }

        const sorted = sortBy(response.data, ['Order'], ['asc']);
        setSortedCategories(sorted);
        setTimeout(() => {
          setHigherCategoriesLoaded(true);
        }, 500);
      });
  };

  const getStatus2 = (restaurant, type, _curday, date, fromPromo) => {
    try {
      const clonedResto = cloneDeep(restaurant);

      if (fromPromo) {
        clonedResto.Availability = clonedResto?.PromoAvailability || {};
      }

      let curday = moment().format('ddd');
      let curtime = moment().format('HH:mm');

      if (_curday) {
        curday = _curday;
      }

      if (date) {
        if (date?.includes(' ')) {
          curday = moment(date, 'MM/DD/YYYY HH:mm').format('ddd');
          curtime = moment(date, 'MM/DD/YYYY HH:mm').format('HH:mm');
        } else {
          curday = moment(date, 'MM/DD/YYYY').format('ddd');
          curtime = moment(date, 'MM/DD/YYYY').format('HH:mm');
        }
      }

      if (clonedResto.Availability[curday]) {
        if (clonedResto.Availability[curday].isAvailable === true) {
          //open 24 hours
          if (clonedResto.Availability[curday].start === clonedResto.Availability[curday].end) {
            return type == 'string' ? 'Open' : true;
          }

          const restaurantOpen = moment('2020-01-01 ' + clonedResto.Availability[curday].start);
          const restaurantClose = moment('2020-01-01 ' + clonedResto.Availability[curday].end);
          const _curDateTime = moment('2020-01-01 ' + curtime);

          if (_curDateTime >= restaurantOpen && _curDateTime <= restaurantClose) {
            return type == 'string' ? 'Open' : true;
          } else {
            if (clonedResto.Availability[curday].othertime) {
              let open = false;
              clonedResto.Availability[curday].othertime.map((time) => {
                const restaurantOpen2 = moment('2020-01-01 ' + time.start);
                const restaurantClose2 = moment('2020-01-01 ' + time.end);

                if (open) {
                  return;
                }

                if (_curDateTime >= restaurantOpen2 && _curDateTime <= restaurantClose2) {
                  open = true;
                }
              });

              if (open) {
                return type == 'string' ? 'Open' : true;
              }
            }

            return type == 'string' ? 'Closed' : false;
          }
        }
        return type == 'string' ? 'Closed' : false;
      }

      return type == 'string' ? 'Closed' : false;
    } catch (ex) {
      console.log('getStatus err: ' + ex.message);
      return 'Closed';
    }
  };

  const getTableNumbers = (value) => {
    axios
      .get(`${configData.SERVER_URL}/tableno/mobile/fetch?restaurant=${the_url_id}`, value)
      .then((response) => {
        let gio = response.data.map((order_tmp1) => {
          return {
            label: order_tmp1.number,
            value: order_tmp1.number,
          };
        });
        setTableNumbers(gio);
      });
    if (
      typeof localStorage.getItem('mobile_pay') !== 'undefined' &&
      localStorage.getItem('mobile_pay') !== null
    ) {
      if (localStorage.getItem('mobile_pay') == 'YES') {
        localStorage.removeItem('mobile_pay');
        // setBasicModal4(true);
      }
    }
  };

  const payOrder = () => {
    setLoading(true);
    let orders_to_pay = [];
    let extras_to_pay = [];
    let variants_to_pay = [];
    checkout_items.forEach((checkout) => {
      checkout.orders[0] = checkout.orders[0].split('#')[0];
    });
    checkout_items.map((checkout_item) => {
      checkout_item.orders[6].map((extra) => {
        if (extra.quantity > 0) {
          extras_to_pay.push({
            _id: extra._id,
            qty: extra.quantity,
          });
        }
      });

      checkout_item.orders[7].map((variant) => {
        variant.options.map((option) => {
          if (option.quantity > 0) {
            variants_to_pay.push({
              _id: variant._id,
              value: option._id,
              qty: option.quantity,
            });
          }
        });
      });

      orders_to_pay.push({
        _id: checkout_item.orders[0],
        qty: checkout_item.orders[5],
        extras: extras_to_pay,
        attributes: variants_to_pay,
      });
      extras_to_pay = [];
      variants_to_pay = [];
    });

    var dt = new Date();
    // dt.toString('YYYY-MM-DDTHH:mm:ss.sssZ');
    localStorage.setItem('return_url', window.location.href);
    if (card_to_use == 'mobile_pay' || card_to_use == 'apple_pay' || card_to_use == 'google_pay') {
      localStorage.setItem('return_url', window.location.href);
      localStorage.setItem('mobile_pay', 'YES');

      let root_url = window.location.origin
        ? window.location.origin + '/'
        : window.location.protocol + '/' + window.location.host + '/';
      var isMobilePay = card_to_use == 'mobile_pay';
      if (
        !isDineIn &&
        pickUp == 'later' &&
        typeof pickupDate === 'object' &&
        typeof pickupTime === 'string'
      ) {
        var currentdate = moment(pickupDate).format('MM/DD/YYYY');
        var currenttime = moment(startTime).format('h:mm a');
        var currentdatetime = moment(currentdate + ' ' + currenttime).format();

        // var currentdatetime=moment(pickupDate).format('MM/DD/YYYY') + " " + pickupTime;
        // var currentdatetime = moment(currentdatetime).format();
        var myOrder = {
          restaurantId: restaurant_id,
          date: dt,
          isDineIn: isDineIn,
          mobilenumber: final_contact,
          notes: final_notes,
          tableNumber: final_table ? final_table.label : '',
          promoCodeId: '',
          isMobilepay: isMobilePay,
          tipId: tipId,
          isweb: true,
          successuri: `${root_url}api/redirect`,
          failuri: `${root_url}api/redirect`,
          isAdvanceOrder: true,
          advanceOrderDate: currentdatetime,
          order: orders_to_pay,
          bagtakeawayprice:
            restaurants?.BagTakeAway && !isDineIn && hasBag
              ? restaurants?.BagTakeAwayPrice || 0
              : 0,
        };
      } else {
        var myOrder = {
          restaurantId: restaurant_id,
          date: dt,
          isDineIn: isDineIn,
          mobilenumber: final_contact,
          notes: final_notes,
          tableNumber: final_table ? final_table.label : '',
          promoCodeId: '',
          isMobilepay: isMobilePay,
          tipId: tipId,
          isweb: true,
          successuri: `${root_url}api/redirect`,
          failuri: `${root_url}api/redirect`,
          order: orders_to_pay,
          bagtakeawayprice:
            restaurants?.BagTakeAway && !isDineIn && hasBag
              ? restaurants?.BagTakeAwayPrice || 0
              : 0,
        };
      }

      axios({
        method: 'POST',
        url: `${configData.SERVER_URL}/order/checkout`,
        data: myOrder,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      })
        .then((response) => {
          // setPayConfirm(response.data.order_details);
          // setToggleTwoModal(!toggleTwoModal);
          setCurrentOrder([]);
          setTotalOrders([]);
          setTotalPrice([]);
          setCheckOutItems([]);
          localStorage.removeItem('orders');
          // setBasicModal4(!basicModal4);
          window.location.replace(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error('There was an error!', error);
        });
    } else {
      if (
        !isDineIn &&
        pickUp == 'later' &&
        typeof pickupDate === 'object' &&
        typeof pickupTime === 'string'
      ) {
        var currentdate = moment(pickupDate).format('MM/DD/YYYY');
        var currenttime = moment(startTime).format('h:mm a');
        var currentdatetime = moment(currentdate + ' ' + currenttime).format();
        // var currentdatetime=moment(pickupDate).format('MM/DD/YYYY') + " " + pickupTime;
        // var currentdatetime = moment(currentdatetime).format();
        var myOrder = {
          restaurantId: restaurant_id,
          date: dt,
          isDineIn: isDineIn,
          mobilenumber: final_contact,
          notes: final_notes,
          tableNumber: final_table ? final_table.label : '',
          promoCodeId: '',
          payment_method: card_to_use,
          tipId: tipId,
          isweb: true,
          isAdvanceOrder: true,
          advanceOrderDate: currentdatetime,
          order: orders_to_pay,
          bagtakeawayprice:
            restaurants?.BagTakeAway && !isDineIn && hasBag
              ? restaurants?.BagTakeAwayPrice || 0
              : 0,
        };
      } else {
        var myOrder = {
          restaurantId: restaurant_id,
          date: dt,
          isDineIn: isDineIn,
          mobilenumber: final_contact,
          notes: final_notes,
          tableNumber: final_table ? final_table.label : '',
          promoCodeId: '',
          payment_method: card_to_use,
          tipId: tipId,
          isweb: true,
          order: orders_to_pay,
          bagtakeawayprice:
            restaurants?.BagTakeAway && !isDineIn && hasBag
              ? restaurants?.BagTakeAwayPrice || 0
              : 0,
        };
      }
      //   return;
      axios({
        method: 'POST',
        url: `${configData.SERVER_URL}/orders/v2/preorder`,
        data: myOrder,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      })
        .then((response) => {
          setLoading(false);
          setPayConfirm(response.data.order_details);
          setToggleTwoModal(!toggleTwoModal);
          setCurrentOrder([]);
          setTotalOrders([]);
          setTotalPrice([]);
          setCheckOutItems([]);
          localStorage.removeItem('orders');
          setBasicModal4(!basicModal4);
          saveOrderInfoToCache(response.data.order_details);
        })
        .catch((error) => {
          setLoading(false);
          console.error('There was an error!', error);
        });
    }
  };

  const saveOrderInfoToCache = (order) => {
    localStorage.setItem('hw_pending_order', JSON.stringify(order));
  };

  const addCard = () => {
    setLoading(true);
    savePreorderActionWhenAddingCard();
    localStorage.setItem('return_url', window.location.href);
    let root_url = window.location.origin
      ? window.location.origin + '/'
      : window.location.protocol + '/' + window.location.host + '/';
    axios({
      method: 'GET',
      url: `${configData.SERVER_URL}/order/addcard?successuri=${root_url}api/redirect&failuri=${root_url}api/redirect&restaurant=${restaurant_id}`,
      data: null,
      headers: {
        Authorization: `Bearer ${userProfile.token}`,
      },
    }).then((response) => {
      window.location.replace(response.data);
    });
  };

  const getUniqueCategories = (value) => {
    let uniqueCategoriesRaw = [];
    categories.map((category) =>
      category.TaggedCategories.map((taggedCategory) => uniqueCategoriesRaw.push(taggedCategory)),
    );
    const uniqueCategoriesFull1 = [
      ...new Set(uniqueCategoriesRaw.map((item) => item?._id?.concat('*~*', item.Category))),
    ];
    setUniqueCategoriesFull(uniqueCategoriesFull1);
  };

  const getFirstDisplay = (value) => {
    if (uniqueCategoriesFull.length > 0) {
      handleFillClick(uniqueCategoriesFull[0]);
    } else {
      handleFillClick('no_higher_cat');
    }
  };

  const calculatePrice = (orders) => {
    const _totalOrders = orders || totalOrders;

    let total_price = 0;
    let extra_price = 0;
    let variant_price = 0;
    _totalOrders.map((totalOrder) => {
      // console.log('next order');
      total_price =
        total_price + parseFloat(totalOrder.orders[2] * parseFloat(totalOrder.orders[5]));
      totalOrder.orders[6].map((extra) => {
        extra_price =
          extra_price +
          parseFloat(extra.price * parseFloat(extra.quantity) * parseFloat(totalOrder.orders[5]));
      });
      // extra_price = extra_price * parseFloat(totalOrder.orders[5]);
      totalOrder.orders[7].map((variant) => {
        variant.options.map((option) => {
          variant_price += parseFloat(
            option.additionalPrice * (option.quantity || 0) * parseFloat(totalOrder.orders[5]),
          );

          // else {
          //   variant_price =
          //     variant_price + parseFloat(option.additionalPrice * parseFloat(option.quantity));
          // }
        });
      });
      // variant_price = variant_price * parseFloat(totalOrder.orders[5]);
    });

    var v_orders_8 = 0;
    _totalOrders.forEach((v, i) => {
      v.orders[7].forEach((x, y) => {
        x.options.forEach((z, w) => {
          if (z.quantity == 1) {
            v_orders_8 = v_orders_8 + z.additionalPrice;
          }
        });
      });
      v.orders[8] = v_orders_8;
      v_orders_8 = 0;
    });

    let bagPrice = 0;
    if (!isDineIn && restaurants?.BagTakeAway) {
      bagPrice = parseFloat(restaurants?.BagTakeAwayPrice || 0);
    }

    if (!hasBag) {
      bagPrice = 0;
    }

    setTotalPrice(total_price + extra_price + variant_price + bagPrice);
  };

  const usernameChange = (value) => {
    setUsername(value);
  };
  const passwordChange = (value) => {
    setPassword(value);
  };

  const cardChange = (value) => {
    if (value == 'mobile_pay') {
      setCardToUse('mobile_pay');
    } else {
      setCardToUse(value);
    }
    setValidCard(true);
  };

  const notesChange = (value) => {
    setNote(value);
  };

  const contactChange = (value) => {
    setContact(value);
    setValidPhone(true);
  };

  const tableChange = (value) => {
    setTable(value);
    setValidTable(true);
  };

  const validateForm = (value) => {
    if (value == 'phone') {
      setValidPhone(false);
    }
    if (value == 'card') {
      setValidCard(false);
    }
    if (value == 'table') {
      setValidTable(false);
    }
  };

  const extraChangeCheckOut = (target, method, target_field) => {
    let tmp1 = checkout_items;
    let target_field_arr = target_field.split('index');
    tmp1.forEach((v, i) => {
      if (i == target_field_arr[1]) {
        v.orders[6].forEach((x, y) => {
          if (x._id == target_field_arr[0]) {
            if (method == 'increase') {
              x.quantity = x.quantity + 1;
            }
            if (method == 'decrease') {
              let newqty = x.quantity - 1;
              if (newqty <= 0) {
                newqty = 0;
              }
              x.quantity = newqty;
            }
          }
          document.getElementById(target_field).value = x.quantity;
        });
      }
    });
    setCheckOutItems(tmp1);
    setTotalOrders(tmp1);
    localStorage.setItem('orders', JSON.stringify(tmp1));
    calculatePrice();
  };

  const roundToTwo = (num) => {
    return +(Math.round(num + 'e+2') + 'e-2');
  };

  const tipChange = (target) => {
    let tip_price = totalPrice * (parseFloat(target.value) / 100);
    roundToTwo(tip_price);
    setTipPrice(roundToTwo(tip_price));
    if (target.id == 'no_tip') {
      setTipId([]);
    } else {
      setTipId(target.id);
    }
  };

  const getNextSiblings = (e) => {
    let siblings = [];
    while ((e = e.nextSibling)) {
      siblings.push(e);
    }
    return siblings;
  };

  const attributeChangeCheckOut = (target) => {
    let split_attribs = target.value.split('~');
    let attrIndexArray = target.name.split('~');
    let product_key = split_attribs[0].split('#');
    let processOrder = checkout_items;
    const checked = target.checked;
    if (target.className.includes('variation_box')) {
      //this is the main toggle
      if (target.type == 'radio') {
        target.parentNode.nextElementSibling.style.display = 'initial';
      } else {
        let the_siblings_array = getNextSiblings(target.parentNode.parentNode);
        if (checked) {
          for (let i = 1; i < the_siblings_array.length; i++) {
            if (the_siblings_array[i].nodeName != '#text') {
              if (the_siblings_array[i].className.includes('variation_box_in')) {
                the_siblings_array[i].style.display = 'initial';
              }
            }
          }
          processOrder.forEach((v, i) => {
            if (v.orders[0] == split_attribs[0] && attrIndexArray[0] == i) {
              v.orders[7].forEach((x, y) => {
                if (x._id == split_attribs[1]) {
                  x.options.forEach((z, w) => {
                    if (z._id == split_attribs[2]) {
                      z.quantity = 1;
                    }
                  });
                }
              });
            }
          });
        } else {
          for (let i = 1; i < the_siblings_array.length; i++) {
            // the_siblings_array[i].style.display = "none";
            if (the_siblings_array[i].nodeName != '#text') {
              if (the_siblings_array[i].className.includes('variation_box_in')) {
                the_siblings_array[i].style.display = 'none';
              }
            }
          }
          processOrder.forEach((v, i) => {
            if (v.orders[0] == split_attribs[0] && attrIndexArray[0] == i) {
              v.orders[7].forEach((x, y) => {
                if (x._id == split_attribs[1]) {
                  //uncheck master
                  x.options.forEach((z, w) => {
                    if (z._id == split_attribs[2]) {
                      z.quantity = 0;
                    }
                  });
                }
              });
            }
          });
          processOrder.forEach((v, i) => {
            if (v.orders[0] == split_attribs[0] && attrIndexArray[0] == i) {
              v.orders[7].forEach((x, y) => {
                //uncheck children
                if (x.hasOwnProperty('belongsto')) {
                  if (x.belongsto == split_attribs[2]) {
                    var elements = document.getElementsByName(i + '~' + x._id);
                    for (var ii = 0; ii < elements.length; ii++) {
                      if (elements[ii].type == 'radio') {
                        elements[ii].checked = false;
                      }
                    }
                    x.options.forEach((zz, ww) => {
                      zz.quantity = 0;
                    });
                  }
                }
              });
            }
          });
        }
      }
    } else {
      processOrder.forEach((v, i) => {
        if (v.orders[0] == split_attribs[0] && attrIndexArray[0] == i) {
          v.orders[7].forEach((x, y) => {
            if (x._id == split_attribs[1]) {
              x.options.forEach((z, w) => {
                if (z._id == split_attribs[2]) {
                  z.quantity = 1;
                } else {
                  z.quantity = 0;
                }
              });
            }
          });
        }
      });
    }
    setCheckOutItems([...processOrder]);
    setTotalOrders([...processOrder]);
    localStorage.setItem('orders', JSON.stringify(processOrder));
  };

  const attributeChange = (target) => {
    let split_attribs = target.value.split('~');
    let processOrder = currentOrder;
    const checked = target.checked;

    console.log('target', target);
    console.log('checked', checked);

    if (target.className.includes('variation_box')) {
      console.log('checked', checked);

      //this is the main toggle
      if (false) {
        target.parentNode.nextElementSibling.style.display = 'initial';
      } else {
        let the_siblings_array = getNextSiblings(target.parentNode.parentNode);
        console.log('the_siblings_array', the_siblings_array);
        if (checked) {
          // target.parentNode.nextElementSibling.nextElementSibling.style.display = "initial";
          for (let i = 1; i < the_siblings_array.length; i++) {
            if (the_siblings_array[i].nodeName != '#text') {
              if (the_siblings_array[i].className.includes('variation_box_in')) {
                the_siblings_array[i].style.display = 'initial';
              }
            }
          }
          processOrder[0].orders[7].forEach((v, i) => {
            if (v._id == split_attribs[1]) {
              v.options.forEach((c, d) => {
                if (c._id == split_attribs[2]) {
                  c.selected = 'Y';
                  c.quantity = 1;
                }
              });
            }
          });
        } else {
          // target.parentNode.nextElementSibling.nextElementSibling.style.display = "none";
          for (let i = 1; i < the_siblings_array.length; i++) {
            if (the_siblings_array[i].nodeName != '#text') {
              if (the_siblings_array[i].className.includes('variation_box_in')) {
                the_siblings_array[i].style.display = 'none';
              }
            }
          }
        }
      }
    } else {
      //this is the options
      let testbelongsto = processOrder[0].orders[7].find(
        (variant) => variant._id == split_attribs[1],
      );
      if (target.type == 'radio' && testbelongsto !== 'undefined') {
        if (testbelongsto.hasOwnProperty('belongsto')) {
          processOrder[0].orders[7].forEach((v, i) => {
            if (v._id == split_attribs[1]) {
              v.options.forEach((c, d) => {
                c.quantity = 0;
                delete c.selected;
                delete v.selected;
                if (c._id == split_attribs[2]) {
                  c.quantity = 1;
                  c.selected = 'Y';
                  v.selected = 'Y';
                }
              });
            }
          });
        } else {
          processOrder[0].orders[7].forEach((v, i) => {
            if (v._id == split_attribs[1]) {
              v.options.forEach((c, d) => {
                if (c._id == split_attribs[2]) {
                  c.quantity = 1;
                  c.selected = 'Y';
                  v.selected = 'Y';
                }
              });
            }
          });
        }
      } else {
        if (checked) {
        } else {
          let testbelongsto = processOrder[0].orders[7].find(
            (variant) => variant._id == split_attribs[1],
          );
          if (testbelongsto.hasOwnProperty('belongsto')) {
          } else {
            processOrder[0].orders[7].forEach((v, i) => {
              if (v._id == split_attribs[1]) {
                v.options.forEach((c, d) => {
                  c.quantity = 0;
                  delete c.selected;
                  if (c._id == split_attribs[2]) {
                    c.quantity = 1;
                    c.selected = 'Y';
                  }
                });
              }
            });
          }
        }
      }
    }
    setCurrentOrder([...processOrder]);
  };

  const logIn = () => {
    const data = `email=${username}&password=${password}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`${configData.SERVER_URL}/user/login`, data, { config })
      .then((response) => {
        if (response.data.Message == 'User successfully logged in') {
          setUserProfile(response.data);
          localStorage.setItem('user_profile', JSON.stringify(response.data));
          closeModal3();
        } else {
          setUserProfile({});
          notifyError();
        }
      })
      .catch((error) => {
        // element.parentElement.innerHTML = `Error: ${error.message}`;
        console.error('There was an error!', error);
        setUserProfile({});
        notifyError();
      });
  };

  //GET MENU ITEMS
  const handleFillClick = (value) => {
    // axios.get(`${configData.SERVER_URL}/menu/fetch?restaurant=${the_url_id}&category=${value.split('*~*')[0]}`,
    axios
      .get(`${configData.SERVER_URL}/menu/fetch?restaurant=${the_url_id}&category=All`, value)
      .then((response) => {
        let sortedAsceding = response.data.data.sort((a, b) => {
          return a?.category?.localeCompare(b?.category);
        });
        setMenuItems(sortedAsceding);
        setMenuItemsCopy(sortedAsceding);
      });
    if (value == 'no_higher_cat') {
      let uniqueCategoriesRaw = [];
      menuItems.map((category) => uniqueCategoriesRaw.push(category));
      const uniqueCategoriesFull1 = [
        ...new Set(uniqueCategoriesRaw.map((item) => item.categoryid.concat('*~*', item.category))),
      ];
      setUniqueCategoriesFull(uniqueCategoriesFull1);
    }
  };

  //Grouping util
  const groupMenuByCategory = (menuItems) => {
    //check if there is a selected higher cat
    if (selectedhighercat?._id) {
      const { TaggedCategories } = selectedhighercat;

      menuItems = cloneDeep(menuItems).filter((a) => {
        return TaggedCategories.find((b) => b._id == a.categoryid);
      });

      let noOrderCount = 0;
      menuItems = menuItems.map((a, index) => {
        if (!a.order) {
          a.order = menuItems.length + index;
          noOrderCount++;
        }

        return a;
      });

      if (noOrderCount == menuItems.length) {
        menuItems = menuItems.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      } else {
        menuItems = menuItems.sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }
          if (a.order > b.order) {
            return 1;
          }
          return 0;
        });
      }
    }

    const categories = chain(menuItems)
      .groupBy('category')
      .map((value, key) => {
        const exist = sortedCategories.find((a) => a.Category == key);
        const order = exist?.Order || null;
        return { category: key, order, menu: value, categoryid: exist?._id };
      })
      .value();

    return categories.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });
  };

  // higher cat actions
  const selectHigherCat = (item) => {
    const { TaggedCategories } = item;
    // console.log('TaggedCategories', TaggedCategories);
    // console.log('menuItemsCopy', menuItemsCopy);
    const filteredmenu = cloneDeep(menuItemsCopy).filter((a) => {
      return TaggedCategories.find((b) => b.Category == a.category || b.label == a.category);
    });

    setselectedhighercat({ ...item });
    setMenuItems([...filteredmenu]);
    //select first item
    const grouped = groupMenuByCategory(filteredmenu);
    setTimeout(() => {
      document.getElementById('category_' + grouped?.[0]?.category.toLowerCase())?.click?.();
    }, 100);
  };

  //group categories
  const grouped = groupMenuByCategory(menuItems);

  const OtherTime = ({ day }) => {
    if (restaurants?.availability?.[day].othertime) {
      return (
        <>
          {restaurants?.availability?.[day].othertime?.map?.((item) => {
            return (
              <>
                <br />
                <span style={{ marginLeft: '5.2rem' }}>
                  {item.start} - {item.end}
                </span>
              </>
            );
          })}
        </>
      );
    }
    return null;
  };

  const filterTime = (time) => {
    //filter via the availability day
    const pureTime = moment(time).format('HH:mm');
    const selectedDate = moment(pickupDate).format('MM/DD/YYYY');
    const pickupDateTime = moment(selectedDate + ' ' + pureTime, 'MM/DD/YYYY HH;mm');

    //check if is future
    const isfuture = moment(pickupDateTime).isSameOrAfter(moment(), 'minutes');

    return getStatus(restaurants, '', pickupDateTime) && isfuture;
  };

  const getStatus = (restaurant, type, pickupDateTime) => {
    const curday = moment(pickupDateTime).format('ddd');
    const curtime = moment(pickupDateTime).format('HH:mm');

    if (restaurant?.availability?.[curday]) {
      if (restaurant.availability[curday].isAvailable === true) {
        //open 24 hours
        if (restaurant.availability[curday].start === restaurant.availability[curday].end) {
          return type == 'string' ? 'Open' : true;
        }

        const restaurantOpen = moment('2020-01-01 ' + restaurant.availability[curday].start);
        const restaurantClose = moment('2020-01-01 ' + restaurant.availability[curday].end);
        const _curDateTime = moment('2020-01-01 ' + curtime);

        // Check if time is continuos
        if (restaurantClose < restaurantOpen && _curDateTime > restaurantOpen) {
          return type == 'string' ? 'Open' : true;
        }

        if (_curDateTime >= restaurantOpen && _curDateTime <= restaurantClose) {
          return type == 'string' ? 'Open' : true;
        } else {
          if (restaurant.availability[curday].othertime) {
            let open = false;
            restaurant.availability[curday].othertime.map((time) => {
              const restaurantOpen2 = moment('2020-01-01 ' + time.start);
              const restaurantClose2 = moment('2020-01-01 ' + time.end);

              if (open) {
                return;
              }

              if (_curDateTime >= restaurantOpen2 && _curDateTime <= restaurantClose2) {
                open = true;
              }
            });

            if (open) {
              return type == 'string' ? 'Open' : true;
            }
          }

          return type == 'string' ? 'Closed' : false;
        }
      }
      return type == 'string' ? 'Closed' : false;
    }

    return type == 'string' ? 'Closed' : false;
  };

  const isOpenThisTime = () => {
    const pureTime = moment().format('HH:mm');
    const selectedDate = moment().format('MM/DD/YYYY');
    const dateTime = moment(selectedDate + ' ' + pureTime, 'MM/DD/YYYY HH:mm');

    return getStatus(restaurants, true, dateTime) && restaurants.isOpen;
  };

  const toggleOrderNotAllowed = () => {
    if (!isDineIn && pickUp == 'now' && restaurantInfo.takeOutSupported) {
      if (!isOpenThisTime()) {
        setOrderNotAllowed(!orderNotAllowed);
      }
    }

    // if (!isDineIn && pickUp == 'now') {
    //   setOrderNotAllowed(!orderNotAllowed);
    // }
  };

  //save preorder actions: dinein/takeout,pickUp info, Mobilnummer,notes, Bordnummer, Tip
  const savePreorderActionWhenAddingCard = () => {
    const data = {
      isDineIn,
      pickUp,
      pickupDate,
      startTime,
      final_contact,
      final_table,
      final_notes,
      tipId,
      restaurant_id,
    };

    localStorage.setItem('hw_preorder_info', JSON.stringify(data));
  };

  const checkForSavedPreorderInfo = () => {
    let preorderInfo = localStorage.getItem('hw_preorder_info');

    if (preorderInfo) {
      preorderInfo = JSON.parse(preorderInfo);
      const {
        isDineIn,
        pickUp,
        pickupDate,
        startTime,
        final_contact,
        final_table,
        final_notes,
        tipId,
      } = preorderInfo;

      setTimeout(async () => {
        getRestaurantTips(localStorage.getItem('restaurant_id'));

        setIsDineIn(isDineIn);
        setpickUp(pickUp);
        setPickupDate(new Date(pickupDate));
        setstartTime(new Date(startTime));
        setContact(final_contact);
        setTable(final_table);
        setNote(final_notes);
        setTipId(tipId);

        const userProfile = JSON.parse(localStorage.getItem('user_profile'));

        const my_orders = JSON.parse(localStorage.getItem('orders'));

        const response = await axios({
          method: 'GET',
          url: `${configData.SERVER_URL}/orders/v2/pmlists?restaurant=${localStorage.getItem(
            'restaurant_id',
          )}`,
          data: null,
          headers: {
            Authorization: `Bearer ${userProfile.token}`,
          },
        });
        setCards(response.data);
        setCheckOutItems(my_orders);

        setToggleTwoModal(!toggleTwoModal);

        localStorage.removeItem('hw_preorder_info');
      }, 500);
    }
  };

  const getLastestAvailableTime = () => {
    //set today as the starting time
    const today = moment().add(lagTime, 'minutes');

    let availableFound = false;
    let latestAvailable = null;

    //add limiter that tracks that all days have been checked
    let loopsDone = {};

    do {
      //get the next 15 mins
      const remainder = lagTime - (today.minute() % lagTime);
      const next15mins = moment(today).add(remainder, 'minutes');

      //get the start/end availability for this day
      let start = restaurants?.Availability?.[next15mins.format('ddd')].start;
      let end = restaurants?.Availability?.[next15mins.format('ddd')].end;
      start = moment(`${next15mins.format('MM/DD/YYYY')} ${start}`, 'MM/DD/YYYY HH:mm');
      end = moment(`${next15mins.format('MM/DD/YYYY')} ${end}`, 'MM/DD/YYYY HH:mm');

      //compare looping if is available for this time
      if (
        next15mins.isBetween(start, end, 'minute', '[]') &&
        restaurants?.Availability?.[next15mins.format('ddd')].isAvailable
      ) {
        //if is available set flag to true
        availableFound = true;
        latestAvailable = next15mins;
      } else {
        //else add 15mins until found
        today.add(remainder, 'minutes');
      }

      loopsDone = {
        ...loopsDone,
        [next15mins.format('ddd')]: true,
      };
    } while (!availableFound && Object.keys(loopsDone).length < 7);

    if (latestAvailable) {
      setstartTime(latestAvailable.toDate());
      setPickupDate(latestAvailable.toDate());
    }
  };

  const checkUsersMobile = () => {
    const userProfile = JSON.parse(localStorage.getItem('user_profile'));
    if (userProfile !== undefined && userProfile !== null && userProfile.profile) {
      if (userProfile?.profile?.mobilenumber) {
        if (userProfile?.profile?.mobilenumber?.substring?.(0, 2) == '45') {
          setContact(userProfile?.profile?.mobilenumber);
        } else {
          setContact('45' + userProfile?.profile?.mobilenumber);
        }
      }
    }
  };

  const actualIsCheckoutAllowed = () => {
    //if dine in just check if restaurant is open and time between opening
    if (isDineIn) {
      return isOpenThisTime();
    } else {
      //if take out

      //if advance order, check if order datetime is available
      if (pickUp == 'later') {
        //get pickupDate and startTime
        const selectedDate = moment(pickupDate).format('MM/DD/YYYY');
        const pureTime = moment(startTime).format('HH:mm');
        const dateTime = moment(selectedDate + ' ' + pureTime, 'MM/DD/YYYY HH:mm');

        //check if is future
        const isfuture = moment(dateTime).isSameOrAfter(moment(), 'minutes');
        const isOpen = getStatus(restaurants, true, dateTime);

        return isfuture && isOpen;
      } else {
        //if not advance order just check if restaurant is open
        return isOpenThisTime();
      }
    }
  };

  const isCheckoutAllowed = React.useMemo(() => {
    // for mobile view always return true, since validation will be on after selecting the items
    if (isMobileView) {
      return true;
    }

    return actualIsCheckoutAllowed();
  }, [restaurants, isDineIn, pickUp, pickupDate, startTime, size]);

  const checkIfCategoryIsDisable = (category) => {
    if (isEmpty(selectedhighercat)) {
      //No higher category for this resto so just based on the "restaurant" day
      return !restaurants.isOpen;
    }

    if (!isDineIn && pickUp == 'later') {
      return !selectedhighercat.isAvailable;
    }

    return !selectedhighercat.isAvailable || !restaurants.isOpen;
  };

  const properAvailability = (availability) => {
    let hash = {};
    Object.entries(availability || {}).map(([day, times]) => {
      if (times?.othertime?.length == 1) {
        const start = times?.othertime?.[0].start;
        const end = times?.othertime?.[0].end;

        //just check if end time is 23:59 then it means that its continuos
        if (end == '23:59') {
          hash = {
            ...hash,
            [day]: {
              start,
              end: times.end,
              isAvailable: times.isAvailable,
            },
          };

          return;
        }
      }

      hash = {
        ...hash,
        [day]: times,
      };
    });

    return hash;
  };

  const handleOptionSelection = (e, option, variation) => {
    // console.log('e', e);
    // console.log('option', option);
    // console.log('variation', variation);
    // THIS IS ONLY FOR VARIATIONS THAT ARE NOT MULTIPLE SELECTION
    if (option.selected == 'Y') {
      delete option.selected;

      //Check if this variation has items that "belongsto" this option
      if (option?.isconnectedto) {
        option?.isconnectedto.map((connectid) => {
          const exist = currentOrder[0].orders[7].find((a) => a._id == connectid);

          if (exist?.options) {
            exist.options.map((a) => {
              delete a.selected;
            });
          }
        });
      }

      setCurrentOrder([...currentOrder]);
    } else {
      //Remove selected items in this variatiom
      variation.options.map((opt) => {
        delete opt.selected;
      });
      attributeChange(e.target);
    }
  };

  const handleOptionSelectionCheckout = (e, checkoutIndex, option, variation) => {
    let processOrder = checkout_items;

    // THIS IS ONLY FOR VARIATIONS THAT ARE NOT MULTIPLE SELECTION
    if (option.selected == 'Y') {
      delete option.selected;

      //Check if this variation has items that "belongsto" this option
      if (option?.isconnectedto) {
        option?.isconnectedto.map((connectid) => {
          const exist = processOrder[checkoutIndex].orders[7].find((a) => a._id == connectid);

          if (exist?.options) {
            exist.options.map((a) => {
              delete a.selected;
            });
          }
        });
      }

      setCheckOutItems([...processOrder]);
      setTotalOrders([...processOrder]);
      localStorage.setItem('orders', JSON.stringify(processOrder));
      calculatePrice();
    } else {
      variation.options.map((opt) => {
        if (opt._id == option._id) {
          opt.selected = 'Y';
        } else {
          delete opt.selected;
        }
      });

      attributeChangeCheckOut(e.target);
    }
  };

  const checkItemsFromCart = (highercategories, categories, restaurant) => {
    const orderCache = window.localStorage.getItem('orders');

    if (!orderCache) {
      return;
    }

    const cartItems = JSON.parse(orderCache);

    if (highercategories.length > 0) {
      // Has higher categories
      const newCartItems = cartItems.filter((item) => {
        const itemHigherCat = item?.orders?.[9] || '';

        const higherCat = highercategories.find((a) => a._id == itemHigherCat);
        return higherCat?.isAvailable || false;
      });

      window.localStorage.setItem('orders', JSON.stringify(newCartItems));

      setCurrentOrder([...newCartItems]);
      setCheckOutItems([...newCartItems]);
      setTotalOrders([...newCartItems]);
      calculatePrice();
      if (newCartItems.length == 0) {
        localStorage.removeItem('orders');
      }
    } else {
      // No higher categories so just based on the resto opening hours
      const newCartItems = cartItems.filter((item) => {
        return restaurant.isOpen || false;
      });

      window.localStorage.setItem('orders', JSON.stringify(newCartItems));

      setCurrentOrder([...newCartItems]);
      setCheckOutItems([...newCartItems]);
      setTotalOrders([...newCartItems]);
      calculatePrice();
      if (newCartItems.length == 0) {
        localStorage.removeItem('orders');
      }
    }
  };

  const RenderMobileDineInTakeoutControl = ({ mainDivClassName, fromMainPage }) => {
    if (fromMainPage) {
      mainDivClassName += ' m-0';
    }

    return (
      <MDBRow className={`${mainDivClassName || ''}`}>
        <MDBCol className={`mc_details_col ${fromMainPage ? ' mb-4' : ''}`}>
          {isDineIn == true ? (
            <MDBBtnGroup className="restaurant-dtl-btn-radio1 dinein_btn_grp dinein_selected">
              <MDBBtn
                className="dinein_btn_grp1"
                onClick={(e) => {
                  changeDineIn('dine', e.target);
                }}
                style={!restaurantInfo.dineInSupported ? { cursor: 'not-allowed' } : {}}
              >
                <MDBIcon fas icon="utensils" />
                &nbsp;&nbsp;Dine in
              </MDBBtn>
              <MDBBtn
                className="dinein_btn_grp2"
                onClick={(e) => {
                  changeDineIn('pickup', e.target);
                }}
                style={!restaurantInfo.takeOutSupported ? { cursor: 'not-allowed' } : {}}
              >
                <MDBIcon fas icon="motorcycle" />
                &nbsp;&nbsp;Afhent
              </MDBBtn>
            </MDBBtnGroup>
          ) : (
            ''
          )}
          {isDineIn == true ? (
            ''
          ) : (
            <>
              <MDBBtnGroup className="restaurant-dtl-btn-radio1 dinein_btn_grp">
                <MDBBtn
                  className="dinein_btn_grp4"
                  onClick={(e) => {
                    changeDineIn('dine', e.target);
                  }}
                  style={!restaurantInfo.dineInSupported ? { cursor: 'not-allowed' } : {}}
                >
                  <MDBIcon fas icon="utensils" />
                  &nbsp;&nbsp;Dine in
                </MDBBtn>
                <MDBBtn
                  className="dinein_btn_grp3"
                  onClick={(e) => {
                    changeDineIn('pickup', e.target);
                  }}
                  style={!restaurantInfo.takeOutSupported ? { cursor: 'not-allowed' } : {}}
                >
                  <MDBIcon fas icon="motorcycle" />
                  &nbsp;&nbsp;Afhent
                </MDBBtn>
              </MDBBtnGroup>
              <MDBRow className={`mc_order_choices m-0`}>
                <MDBBtn
                  outline
                  className="mc_order_choices_btn"
                  onClick={() => {
                    setBasicModal2mc(!basicModal2mc);
                    setTimeout(() => {
                      setNowOrLaterModal(!nowOrLaterModal);
                    }, 400);
                  }}
                >
                  <MDBCol size="10" className="mc_order_choicescol">
                    <MDBRow>
                      <MDBCardText className="mc_order_choices_now">Gå til betaling</MDBCardText>
                    </MDBRow>
                    <MDBRow>
                      <MDBCardText className="mc_order_choices_advance">
                        Eller Forudbestil
                      </MDBCardText>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol size="2" className="mc_order_choicescol mc_order_choicescolicon">
                    <MDBIcon className="mc_order_choices_icon" fas icon="angle-right" />
                  </MDBCol>
                </MDBBtn>
              </MDBRow>
            </>
          )}
        </MDBCol>
      </MDBRow>
    );
  };

  const getTotalOrderInThisCategory = (options) => {
    let count = 0;
    options.map((item) => {
      count = count + item.quantity;
    });

    return count;
  };

  const incrementCounterMutipleAttr = (product_id) => {
    let newcurrentOrder = currentOrder.map((orderTmp) => {
      orderTmp.orders[7].map((orderTmp2) => {
        orderTmp2?.options?.map((item) => {
          if (product_id == item._id) {
            if (orderTmp2.hasLimitNumberPerItems && item.limit) {
              const newLimit = item.quantity + 1;
              if (newLimit > item.limit) {
                return;
              }
            }

            if (orderTmp2.hasLimitNumberPerItems && orderTmp2.hasLimitPerCategory) {
              const totalOrderInThisCategory = getTotalOrderInThisCategory(orderTmp2?.options);
              if (totalOrderInThisCategory + 1 > orderTmp2.limitPerCategory) {
                return;
              }
            }

            item.quantity += 1;
            item.selected = 'Y';
            orderTmp2.selected = 'Y';
          }
        });
      });

      return orderTmp;
    });
    setCurrentOrder([...newcurrentOrder]);
  };

  const decrementCounterMutipleAttr = (product_id) => {
    let newcurrentOrder = currentOrder.map((orderTmp) => {
      orderTmp.orders[7].map((orderTmp2) => {
        orderTmp2?.options?.map((item) => {
          if (product_id == item._id) {
            if (item.quantity > 0) {
              item.quantity -= 1;

              if (item.quantity == 0) {
                delete item.selected;
                delete orderTmp2.selected;
              }
            }
          }
        });
      });

      return orderTmp;
    });
    setCurrentOrder([...newcurrentOrder]);
  };

  const parentSelected = (belongsto) => {
    const exists = currentOrder?.[0]?.orders?.[7]?.find((a) => {
      return a?.options?.find?.((b) => {
        return b._id == belongsto;
      });
    });

    const option = exists?.options?.find?.((b) => {
      return b._id == belongsto;
    });

    return option?.selected == 'Y';
  };

  const testGuestCheckout = async () => {
    const response = await axios({
      url: 'https://checkout-api.reepay.com/v1/session/charge',
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: 'Basic cHJpdl84MTM4OTA1ZDJkYzcyODhjMGRhOTg2YTQyM2Q4YmE1ODo=',
      },
      data: {
        order: {
          handle: 'order-12345C',
          customer: {
            handle: 'customer-123',
            first_name: 'John',
            last_name: 'Doe',
            phone: '+4531313131',
          },
          amount: 50000,
          payment_methods: ['card'],
        },
        accept_url: 'https://webshop.com/accept/order-12345',
        cancel_url: 'https://webshop.com/decline/order-12345',
      },
    });

    var rp = new Reepay.ModalCheckout(response.data.id);
    rp.addEventHandler(Reepay.Event.Accept, function (data) {
      console.log('Success', data);
    });

    console.log(response.data);
  };

  const registerGuestUser = async () => {
    const payload = {
      firstname: guestInfo.firstname,
      lastname: guestInfo.lastname,
      phone: final_contact,
      email: guestInfo.email,
    };

    const response = await axios({
      method: 'POST',
      url: `${configData.SERVER_URL}/orders/guest/createuser`,
      data: payload,
    });

    console.log(response.data);
    //Update the guest id to be from the db id
    orderContext.setGuestId(response.data._id.toString());
    localStorage.setItem('guest-uui', response.data._id.toString());

    return response.data;
  };

  const orderViaGuest = async () => {
    setLoading(true);
    //Register first the guest user
    const guestUser = await registerGuestUser();
    const guestId = guestUser._id;

    let orders_to_pay = [];
    let extras_to_pay = [];
    let variants_to_pay = [];
    checkout_items.forEach((checkout) => {
      checkout.orders[0] = checkout.orders[0].split('#')[0];
    });
    checkout_items.map((checkout_item) => {
      checkout_item.orders[6].map((extra) => {
        if (extra.quantity > 0) {
          extras_to_pay.push({
            _id: extra._id,
            qty: extra.quantity,
          });
        }
      });

      checkout_item.orders[7].map((variant) => {
        variant.options.map((option) => {
          if (option.quantity > 0) {
            variants_to_pay.push({
              _id: variant._id,
              value: option._id,
              qty: option.quantity,
            });
          }
        });
      });

      orders_to_pay.push({
        _id: checkout_item.orders[0],
        qty: checkout_item.orders[5],
        extras: extras_to_pay,
        attributes: variants_to_pay,
      });
      extras_to_pay = [];
      variants_to_pay = [];
    });

    var dt = new Date();
    // dt.toString('YYYY-MM-DDTHH:mm:ss.sssZ');
    localStorage.setItem('return_url', window.location.href);

    localStorage.setItem('return_url', window.location.href);
    localStorage.setItem('mobile_pay', 'YES');

    let root_url = window.location.origin
      ? window.location.origin + '/'
      : window.location.protocol + '/' + window.location.host + '/';
    var isMobilePay = card_to_use == 'mobile_pay';
    if (
      !isDineIn &&
      pickUp == 'later' &&
      typeof pickupDate === 'object' &&
      typeof pickupTime === 'string'
    ) {
      var currentdate = moment(pickupDate).format('MM/DD/YYYY');
      var currenttime = moment(startTime).format('h:mm a');
      var currentdatetime = moment(currentdate + ' ' + currenttime).format();

      // var currentdatetime=moment(pickupDate).format('MM/DD/YYYY') + " " + pickupTime;
      // var currentdatetime = moment(currentdatetime).format();
      var myOrder = {
        restaurantId: restaurant_id,
        date: dt,
        isDineIn: isDineIn,
        mobilenumber: final_contact,
        notes: final_notes,
        tableNumber: final_table ? final_table.label : '',
        promoCodeId: '',
        isMobilepay: false,
        tipId: tipId,
        isweb: true,
        successuri: `${root_url}api/redirect`,
        failuri: `${root_url}api/redirect`,
        isAdvanceOrder: true,
        advanceOrderDate: currentdatetime,
        order: orders_to_pay,
        isGuest: true,
        guestId,
        bagtakeawayprice:
          restaurants?.BagTakeAway && !isDineIn && hasBag ? restaurants?.BagTakeAwayPrice || 0 : 0,
      };
    } else {
      var myOrder = {
        restaurantId: restaurant_id,
        date: dt,
        isDineIn: isDineIn,
        mobilenumber: final_contact,
        notes: final_notes,
        tableNumber: final_table ? final_table.label : '',
        promoCodeId: '',
        isMobilepay: false,
        tipId: tipId,
        isweb: true,
        successuri: `${root_url}api/redirect`,
        failuri: `${root_url}api/redirect`,
        order: orders_to_pay,
        isGuest: true,
        guestId,
        bagtakeawayprice:
          restaurants?.BagTakeAway && !isDineIn && hasBag ? restaurants?.BagTakeAwayPrice || 0 : 0,
      };
    }

    axios({
      method: 'POST',
      url: `${configData.SERVER_URL}/order/checkout`,
      data: myOrder,
      headers: {
        Authorization: `Bearer ${userProfile.token}`,
      },
    })
      .then((response) => {
        // setPayConfirm(response.data.order_details);
        // setToggleTwoModal(!toggleTwoModal);
        setCurrentOrder([]);
        setTotalOrders([]);
        setTotalPrice([]);
        setCheckOutItems([]);
        localStorage.removeItem('orders');
        // setBasicModal4(!basicModal4);
        window.location.replace(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error('There was an error!', error);
      });
  };

  const checkForOrderTypeDefaulting = () => {
    //This checks if the selected higher category is close then,
    //check if advanceorder is enabled so it can default set to takeout and advance

    //get latest available time higher cat
    getLastestAvailableTimeHigherCat(selectedhighercat);

    if (!selectedhighercat.isAvailable) {
      if (restaurants.istakeout) {
        setIsDineIn(false);
        setpickUp('later');
      }
    }

    if (!restaurants.isOpen && restaurants.istakeout && restaurants.allowAdvanceOrder) {
      setIsDineIn(false);
      setpickUp('later');
    }
  };

  const getLastestAvailableTimeHigherCat = (selectedhighercat) => {
    selectedhighercat = cloneDeep(selectedhighercat);
    //set today as the starting time
    const today = moment().add(lagTime, 'minutes');

    let availableFound = false;
    let latestAvailable = null;

    //add limiter that tracks that all days have been checked
    let loopsDone = {};

    do {
      //get the next 15 mins
      const remainder = lagTime - (today.minute() % lagTime);
      const next15mins = moment(today).add(remainder, 'minutes');

      //get the start/end availability for this day
      let start = selectedhighercat?.Availability?.[next15mins.format('ddd')].start;
      let end = selectedhighercat?.Availability?.[next15mins.format('ddd')].end;
      start = moment(`${next15mins.format('MM/DD/YYYY')} ${start}`, 'MM/DD/YYYY HH:mm');
      end = moment(`${next15mins.format('MM/DD/YYYY')} ${end}`, 'MM/DD/YYYY HH:mm');

      // console.log('next15mins', next15mins);
      // console.log('start', start);
      // console.log('end', end);

      //compare looping if is available for this time
      if (
        next15mins.isBetween(start, end, 'minute', '[]')

        // && selectedhighercat?.Availability?.[next15mins.format('ddd')].isAvailable
      ) {
        //if is available set flag to true
        availableFound = true;
        latestAvailable = next15mins;
      } else {
        //else add 15mins until found
        today.add(remainder, 'minutes');
      }

      loopsDone = {
        ...loopsDone,
        [next15mins.format('ddd')]: true,
      };
    } while (!availableFound && Object.keys(loopsDone).length < 7);

    // console.log('loopsDone', loopsDone);
    // console.log('availableFound', availableFound);
    // console.log('latestAvailable', latestAvailable);

    if (latestAvailable) {
      setstartTime(latestAvailable.toDate());
      setPickupDate(latestAvailable.toDate());
    }
  };

  const checkNavbarToBeHidden = () => {
    const tobeHiddenIds = ['6231fb54a52b9e00166d0fd7'];

    const pathname = window.location.pathname;
    tobeHiddenIds.map((id) => {
      if (pathname?.includes(id)) {
        document.getElementById('_Forside').style.display = 'none';
        document.getElementById('_Restauranter').style.display = 'none';
      }
    });
  };

  const fetchLagTime = async () => {
    const response = await axios({
      method: 'GET',
      url: `${configData.SERVER_URL}/config/mobile/fetch`,
    });

    const lagTime = response.data?.find?.((a) => a.Name == 'lagTime');
    if (lagTime) {
      setLagTime(parseInt(lagTime?.Value?.duration));
    }
  };

  return (
    <>
      <NavBar
        setCards={setCards}
        setCheckOutItems={setCheckOutItems}
        setUserProfile={setUserProfile}
      />

      <MDBRow className="details_row topbanner non_mobile">
        <MDBCol size="4" className="details_col">
          <MDBCard className="restaurant_name" alignment="center">
            <MDBCardBody className="restaurant_details_holder">
              {/* <MDBRow> */}
              <MDBCardTitle className="restaurant_name1"> {restaurants.name} </MDBCardTitle>
              <MDBCardText className="restaurant_description">
                {' '}
                {restaurants.description}{' '}
              </MDBCardText>
              {/* </MDBRow> */}
              <MDBCardText className="restaurant_address_desktop">
                <p className="adress_header">Lokation</p>
                <span className="restaurant_address">{restaurants.address} </span>
              </MDBCardText>
              <MDBCardText>
                <p className="openinghours_header">Åbningstider</p>
                <RenderAvailability restaurants={restaurants} />
                {/* {typeof restaurants.availability !== 'undefined' ? (
                  <p className="schedule">
                    <span className="weekname">Mandag:</span>{' '}
                    {!restaurants?.availability?.Mon?.isAvailable ? (
                      'Lukket'
                    ) : (
                      <>
                        {restaurants.availability.Mon.start +
                          ' - ' +
                          restaurants.availability.Mon.end}
                        <OtherTime day="Mon" />
                      </>
                    )}
                  </p>
                ) : (
                  ''
                )}
                {typeof restaurants.availability !== 'undefined' ? (
                  <p className="schedule">
                    <span className="weekname">Tirsdag:</span> <OtherTime day="Tue" />
                    {!restaurants?.availability?.Tue?.isAvailable ? (
                      'Lukket'
                    ) : (
                      <>
                        {restaurants.availability.Tue.start +
                          ' - ' +
                          restaurants.availability.Tue.end}
                        <OtherTime day="Tue" />
                      </>
                    )}
                  </p>
                ) : (
                  ''
                )}
                {typeof restaurants.availability !== 'undefined' ? (
                  <p className="schedule">
                    <span className="weekname">Onsdag:</span>{' '}
                    {!restaurants?.availability?.Wed?.isAvailable ? (
                      'Lukket'
                    ) : (
                      <>
                        {restaurants.availability.Wed.start +
                          ' - ' +
                          restaurants.availability.Wed.end}
                        <OtherTime day="Wed" />
                      </>
                    )}
                  </p>
                ) : (
                  ''
                )}
                {typeof restaurants.availability !== 'undefined' ? (
                  <p className="schedule">
                    <span className="weekname">Torsdag:</span>{' '}
                    {!restaurants?.availability?.Thu?.isAvailable ? (
                      'Lukket'
                    ) : (
                      <>
                        {restaurants.availability.Thu.start +
                          ' - ' +
                          restaurants.availability.Thu.end}
                        <OtherTime day="Thu" />
                      </>
                    )}
                  </p>
                ) : (
                  ''
                )}
                {typeof restaurants.availability !== 'undefined' ? (
                  <p className="schedule">
                    <span className="weekname">Fredag:</span>{' '}
                    {!restaurants?.availability?.Fri?.isAvailable ? (
                      'Lukket'
                    ) : (
                      <>
                        {restaurants.availability.Fri.start +
                          ' - ' +
                          restaurants.availability.Fri.end}
                        <OtherTime day="Fri" />
                      </>
                    )}
                  </p>
                ) : (
                  ''
                )}
                {typeof restaurants.availability !== 'undefined' ? (
                  <p className="schedule">
                    <span className="weekname">Lørdag:</span>{' '}
                    {!restaurants?.availability?.Sat?.isAvailable ? (
                      'Lukket'
                    ) : (
                      <>
                        {restaurants.availability.Sat.start +
                          ' - ' +
                          restaurants.availability.Sat.end}
                        <OtherTime day="Sat" />
                      </>
                    )}
                  </p>
                ) : (
                  ''
                )}
                {typeof restaurants.availability !== 'undefined' ? (
                  <p className="schedule">
                    <span className="weekname">Søndag:</span>{' '}
                    {!restaurants?.availability?.Sun?.isAvailable ? (
                      'Lukket'
                    ) : (
                      <>
                        {restaurants.availability.Sun.start +
                          ' - ' +
                          restaurants.availability.Sun.end}
                        <OtherTime day="Sun" />
                      </>
                    )}
                  </p>
                ) : (
                  ''
                )} */}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol className="mobile_css_spacer" size="1">
          &nbsp;
        </MDBCol>
        <MDBCol
          size="4"
          // md='3' offsetMd='2'
          className="details_col restaurant_cover1_col"
        >
          <MDBCard alignment="center">
            <MDBCardBody className="restaurant_coverbody1">
              <img
                className="restaurant_cover1"
                src={`${restaurants.cover}`}
                // height='250'
                alt=""
                loading="lazy"
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol size="3" className="details_col restaurant_cover2_col">
          <MDBCard alignment="center">
            <MDBCardBody className="restaurant_coverbody2">
              <MDBRow className="restaurant_cover_row">
                <MDBCol className="restaurant_cover_col2">
                  <p className="restaurant_cover_title">
                    {restaurants.hasOwnProperty('galleryimages') ? (
                      <>
                        {restaurants.galleryimages.length > 0 ? (
                          <img
                            className="restaurant_cover2 restaurant_cover_title_first"
                            src={`${restaurants.galleryimages[0]}`}
                            height="120"
                            alt=""
                            loading="lazy"
                          />
                        ) : (
                          <img
                            className="restaurant_cover2 restaurant_cover_title_first"
                            src={`${restaurants.cover}`}
                            height="120"
                            alt=""
                            loading="lazy"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        className="restaurant_cover2 restaurant_cover_title_first"
                        src={`${restaurants.cover}`}
                        height="120"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow className="restaurant_cover_row">
                <MDBCol className="restaurant_cover_col2">
                  <p className="restaurant_cover_title">
                    {restaurants.hasOwnProperty('galleryimages') ? (
                      <>
                        {restaurants.galleryimages.length > 1 ? (
                          <img
                            className="restaurant_cover2"
                            src={`${restaurants.galleryimages[1]}`}
                            height="120"
                            alt=""
                            loading="lazy"
                          />
                        ) : (
                          <img
                            className="restaurant_cover2"
                            src={`${restaurants.cover}`}
                            height="120"
                            alt=""
                            loading="lazy"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        className="restaurant_cover2"
                        src={`${restaurants.cover}`}
                        height="120"
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow className="details_row mobile_css_pad1">
        <MDBCol className="details_col">
          {/* <button onClick={testGuestCheckout}>TESTTTT</button> */}
          <p className="our_menu non_mobile">
            Menukort
            <MDBCardText className="daily_schedule">
              <MDBIcon fas icon="clock" />
              &nbsp; &nbsp; {restaurants.isOpen ? 'Åben' : 'Lukket'} &nbsp;{}
              {new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date()) == 'Mon' &&
              typeof restaurants.availability !== 'undefined'
                ? restaurants.availability.Mon.start + ' - ' + restaurants.availability.Mon.end
                : ''}
              {new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date()) == 'Tue' &&
              typeof restaurants.availability !== 'undefined'
                ? restaurants.availability.Tue.start + ' - ' + restaurants.availability.Tue.end
                : ''}
              {new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date()) == 'Wed' &&
              typeof restaurants.availability !== 'undefined'
                ? restaurants.availability.Wed.start + ' - ' + restaurants.availability.Wed.end
                : ''}
              {new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date()) == 'Thu' &&
              typeof restaurants.availability !== 'undefined'
                ? restaurants.availability.Thu.start + ' - ' + restaurants.availability.Thu.end
                : ''}
              {new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date()) == 'Fri' &&
              typeof restaurants.availability !== 'undefined'
                ? restaurants.availability.Fri.start + ' - ' + restaurants.availability.Fri.end
                : ''}
              {new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date()) == 'Sat' &&
              typeof restaurants.availability !== 'undefined'
                ? restaurants.availability.Sat.start + ' - ' + restaurants.availability.Sat.end
                : ''}
              {new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date()) == 'Sun' &&
              typeof restaurants.availability !== 'undefined'
                ? restaurants.availability.Sun.start + ' - ' + restaurants.availability.Sun.end
                : ''}
            </MDBCardText>
          </p>
          <img
            className="mobile_version_bot"
            src={`${restaurants.cover}`}
            height="400"
            alt=""
            loading="lazy"
          />

          <MDBContainer className="mobile_container">
            <MDBCol size="12" className="mc_restaurant_dtl">
              <MDBRow>
                <MDBCardBody className="mc_body">
                  <MDBCardTitle className="mc_restaurant_her_cr"> {restaurants.name} </MDBCardTitle>
                  <MDBCardText className=" "> {restaurants.description} </MDBCardText>
                  <MDBCardText>
                    <MDBIcon fas icon="map-marker-alt" className="icons" />
                    <span className=" ">&nbsp;&nbsp; {restaurants.address} </span>
                  </MDBCardText>
                  <MDBCardText className="schedule_section">
                    <MDBIcon fas icon="clock" className="icons" />
                    &nbsp; &nbsp; {restaurants.isOpen ? 'Åben' : 'Lukket'} &nbsp;
                    <RenderAvailability restaurants={restaurants} />
                    {/* {typeof restaurants.availability !== 'undefined' ? (
                      <p className="schedule">
                        <span className="weekname">Mandag:</span>{' '}
                        {restaurants.availability.Mon.start +
                          ' - ' +
                          restaurants.availability.Mon.end}
                        <OtherTime day="Mon" />
                      </p>
                    ) : (
                      ''
                    )}
                    {typeof restaurants.availability !== 'undefined' ? (
                      <p className="schedule">
                        <span className="weekname">Tirsdag:</span>{' '}
                        {restaurants.availability.Tue.start +
                          ' - ' +
                          restaurants.availability.Tue.end}
                        <OtherTime day="Tue" />
                      </p>
                    ) : (
                      ''
                    )}
                    {typeof restaurants.availability !== 'undefined' ? (
                      <p className="schedule">
                        <span className="weekname">Onsdag:</span>{' '}
                        {restaurants.availability.Wed.start +
                          ' - ' +
                          restaurants.availability.Wed.end}
                        <OtherTime day="Wed" />
                      </p>
                    ) : (
                      ''
                    )}
                    {typeof restaurants.availability !== 'undefined' ? (
                      <p className="schedule">
                        <span className="weekname">Torsdag:</span>{' '}
                        {restaurants.availability.Thu.start +
                          ' - ' +
                          restaurants.availability.Thu.end}
                        <OtherTime day="Thu" />
                      </p>
                    ) : (
                      ''
                    )}
                    {typeof restaurants.availability !== 'undefined' ? (
                      <p className="schedule">
                        <span className="weekname">Fredag:</span>{' '}
                        {restaurants.availability.Fri.start +
                          ' - ' +
                          restaurants.availability.Fri.end}
                        <OtherTime day="Fri" />
                      </p>
                    ) : (
                      ''
                    )}
                    {typeof restaurants.availability !== 'undefined' ? (
                      <p className="schedule">
                        <span className="weekname">Lørdag:</span>{' '}
                        {restaurants.availability.Sat.start +
                          ' - ' +
                          restaurants.availability.Sat.end}
                        <OtherTime day="Sat" />
                      </p>
                    ) : (
                      ''
                    )}
                    {typeof restaurants.availability !== 'undefined' ? (
                      <p className="schedule">
                        <span className="weekname">Søndag:</span>{' '}
                        {restaurants.availability.Sun.start +
                          ' - ' +
                          restaurants.availability.Sun.end}
                        <OtherTime day="Sun" />
                      </p>
                    ) : (
                      ''
                    )} */}
                  </MDBCardText>
                </MDBCardBody>
              </MDBRow>
            </MDBCol>

            <MDBRow className="mc_dinordre">
              <MDBRow className="dinein_takeout">
                <MDBCard alignment="center" className="">
                  <MDBCardBody className="sidebar_dinein_card">
                    <MDBRow className="dinecol2">
                      <MDBCol className="din_ordre">
                        <img
                          src="/icon-order.png"
                          className="dine_or_pickup"
                          alt="..."
                          style={{ paddingLeft: '0' }}
                        />
                        &nbsp;&nbsp;Din ordre
                      </MDBCol>
                      {totalOrders.length > 0 ? (
                        <MDBCol className="sidebar_order_count_all">
                          <MDBBtn className="sidebar_order_count"> {totalOrders.length}</MDBBtn>
                        </MDBCol>
                      ) : (
                        ''
                      )}
                    </MDBRow>

                    <MDBCol className="details_col">
                      {isDineIn == true ? (
                        <MDBBtnGroup className="restaurant-dtl-btn-radio1 dinein_btn_grp dinein_selected">
                          <MDBBtn
                            className="dinein_btn_grp1"
                            onClick={(e) => {
                              changeDineIn('dine', e.target);
                            }}
                            style={!restaurantInfo.dineInSupported ? { cursor: 'not-allowed' } : {}}
                          >
                            <img
                              src="/icon-dinein.png"
                              className="icon_dine_in"
                              // height='20'
                              alt=""
                              loading="lazy"
                            />
                            <MDBIcon fas icon="utensils" />
                            &nbsp;&nbsp;Dine in
                          </MDBBtn>
                          <MDBBtn
                            className="dinein_btn_grp2"
                            onClick={(e) => {
                              changeDineIn('pickup', e.target);
                            }}
                            style={
                              !restaurantInfo.takeOutSupported ? { cursor: 'not-allowed' } : {}
                            }
                          >
                            <img
                              src="/icon-pickup.png"
                              className="icon_dine_in"
                              // height='20'
                              alt=""
                              loading="lazy"
                            />
                            {/* <MDBIcon fas icon="motorcycle" /> */}
                            &nbsp;&nbsp;Afhent
                          </MDBBtn>
                        </MDBBtnGroup>
                      ) : (
                        ''
                      )}
                      {isDineIn == true ? (
                        ''
                      ) : (
                        <MDBBtnGroup className="restaurant-dtl-btn-radio1 dinein_btn_grp">
                          <MDBBtn
                            className="dinein_btn_grp4"
                            onClick={(e) => {
                              changeDineIn('dine', e.target);
                            }}
                            style={!restaurantInfo.dineInSupported ? { cursor: 'not-allowed' } : {}}
                          >
                            <img
                              src="/icon-dinein.png"
                              className="icon_dine_in"
                              // height='20'
                              alt=""
                              loading="lazy"
                            />
                            {/* <MDBIcon fas icon="utensils" /> */}
                            &nbsp;&nbsp;Dine in
                          </MDBBtn>
                          <MDBBtn
                            className="dinein_btn_grp3"
                            onClick={(e) => {
                              changeDineIn('pickup', e.target);
                            }}
                            style={
                              !restaurantInfo.takeOutSupported ? { cursor: 'not-allowed' } : {}
                            }
                          >
                            <img
                              src="/icon-pickup.png"
                              className="icon_dine_in"
                              // height='20'
                              alt=""
                              loading="lazy"
                            />
                            {/* <MDBIcon fas icon="motorcycle" /> */}
                            &nbsp;&nbsp;Afhent
                          </MDBBtn>
                        </MDBBtnGroup>
                      )}
                    </MDBCol>

                    <MDBRow>
                      {isDineIn == true ? (
                        ''
                      ) : (
                        <>
                          {pickUp == 'now' ? (
                            <MDBCard>
                              <MDBCardBody className="place_order_now_later place_order_now">
                                <MDBBtn
                                  className="order_now_btn order_now_btn_checked"
                                  onClick={() => {
                                    changePickupDate('now');
                                  }}
                                >
                                  <MDBCol style={{ float: 'left', fontWeight: 'bold' }}>
                                    Bestil nu
                                  </MDBCol>
                                  <MDBCol style={{ float: 'right' }}>
                                    <MDBIcon
                                      style={{ color: '#88B11A' }}
                                      size="2x"
                                      fas
                                      icon="check-circle"
                                    />
                                  </MDBCol>
                                </MDBBtn>
                              </MDBCardBody>
                            </MDBCard>
                          ) : (
                            <MDBCard className=" ">
                              <MDBCardBody className="place_order_now_later place_order_now">
                                <MDBBtn
                                  className="order_now_btn"
                                  onClick={() => {
                                    changePickupDate('now');
                                  }}
                                >
                                  <MDBCol style={{ float: 'left' }}>Bestil nu</MDBCol>
                                  <MDBCol style={{ float: 'right' }}>
                                    <MDBIcon size="2x" far icon="circle" />
                                  </MDBCol>
                                </MDBBtn>
                              </MDBCardBody>
                            </MDBCard>
                          )}

                          {pickUp == 'later' ? (
                            <>
                              <MDBCard className=" ">
                                <MDBCardBody className="advance_order place_order_now_later place_order_later">
                                  <MDBBtn
                                    className="order_in_advance order_in_advance_checked"
                                    onClick={() => {
                                      changePickupDate('later');
                                    }}
                                    hidden={!restaurantInfo.advanceOrderSupported}
                                  >
                                    <MDBCol style={{ float: 'left', fontWeight: 'bold' }}>
                                      Forudbestil
                                    </MDBCol>
                                    <MDBCol style={{ float: 'right' }}>
                                      <MDBIcon
                                        style={{ color: '#88B11A' }}
                                        size="2x"
                                        fas
                                        icon="check-circle"
                                      />
                                    </MDBCol>
                                  </MDBBtn>
                                  <MDBTypography className="datetime_select_p">
                                    <MDBTypography className="datetime_select" tag="small">
                                      <MDBIcon fas icon="clock" />
                                      &nbsp;&nbsp;Afhentningstidspunkt
                                    </MDBTypography>
                                  </MDBTypography>
                                  <p className="pickupdate_p">
                                    <DatePicker
                                      className="pickupdate"
                                      selected={pickupDate}
                                      isClearable
                                      onChange={(date) => setPickupDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      minDate={new Date()}
                                    />
                                    <MDBIcon
                                      fas
                                      icon="angle-down"
                                      className="pickupdatetimearrowdown1"
                                    />
                                  </p>
                                  <p className="pickupdatetime_p"></p>
                                  <p className="pickuptime_p">
                                    <DatePicker
                                      className="pickuptime"
                                      selected={startTime}
                                      onChange={(date) => setstartTime(date)}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={lagTime}
                                      timeCaption="Time"
                                      dateFormat="HH:mm"
                                      timeFormat="HH:mm"
                                      filterTime={filterTime}
                                    />
                                    <MDBIcon
                                      fas
                                      icon="angle-down"
                                      className="pickupdatetimearrowdown2"
                                    />
                                  </p>
                                </MDBCardBody>
                              </MDBCard>
                            </>
                          ) : (
                            <>
                              <MDBCard className="order_later_unselected">
                                <MDBCardBody className="advance_order place_order_now_later place_order_later">
                                  <MDBBtn
                                    className="order_in_advance"
                                    onClick={() => {
                                      changePickupDate('later');
                                    }}
                                    hidden={!restaurantInfo.advanceOrderSupported}
                                  >
                                    <MDBCol style={{ float: 'left' }}>Forudbestil</MDBCol>
                                    <MDBCol style={{ float: 'right' }}>
                                      <MDBIcon size="2x" far icon="circle" />
                                    </MDBCol>
                                  </MDBBtn>
                                </MDBCardBody>
                              </MDBCard>
                            </>
                          )}
                        </>
                      )}
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>

              {/* MOBILE */}
              <MDBRow className="sidebar_checkout_row">
                {typeof localStorage.getItem('orders') !== 'undefined' &&
                localStorage.getItem('orders') !== null ? (
                  <MDBCol>
                    <MDBCard alignment="center" className="">
                      <MDBCardBody className="sidebar_checkout_card">
                        <MDBCardText>
                          <MDBCol className="sidebar_checkout_card_col">
                            {/* <MDBIcon className="cart_icon fa-2x"  fas icon="shopping-cart" /> */}
                            <MDBRow className="sidebar_checkout_card_row">
                              {totalOrders?.map?.((x, v) => (
                                <>
                                  <MDBRow className="main_breakdown">
                                    <MDBCol className="breakdown_main">
                                      {x.orders[5] + 'x  ' + x.orders[1]}
                                    </MDBCol>
                                    <MDBCol className="breakdown_price">
                                      DKK&nbsp;{roundToTwo(x.orders[2] * x.orders[5])}
                                    </MDBCol>
                                  </MDBRow>
                                  <br />
                                  {x?.orders?.[6]?.map?.((a, b) => (
                                    <>
                                      {a.quantity > 0 ? (
                                        <>
                                          <MDBRow className="extras_breakdown">
                                            <MDBCol className="breakdown_extra">
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              {a.quantity > 0 ? a.quantity + 'x  ' + a.name : ''}
                                            </MDBCol>
                                            <MDBCol className="breakdown_price">
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DKK&nbsp;
                                              {roundToTwo(a.price * a.quantity)}
                                            </MDBCol>
                                          </MDBRow>
                                          <br />
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  ))}
                                  {/* <hr/> */}
                                </>
                              ))}
                              {/* <MDBCol  ><span style={{float:"left"}}>{totalOrders.length} items</span> </MDBCol> 
                        <MDBCol >  <span className='sidebar_price'>DKK {totalPrice}</span></MDBCol>  */}
                            </MDBRow>
                            <MDBRow className="sidebar_checkout_btn">
                              <MDBBtn
                                onClick={() => {
                                  if (isCheckoutAllowed) {
                                    toggleShow2();
                                  } else {
                                    toggleOrderNotAllowed();
                                  }
                                }}
                                className="sidebar_checkout desktop_modals"
                                type="button"
                              >
                                Gå til betaling ({totalOrders.length})
                              </MDBBtn>
                              <MDBBtn
                                onClick={() => {
                                  if (isCheckoutAllowed) {
                                    toggleShow2mc();
                                  } else {
                                    toggleOrderNotAllowed();
                                  }
                                }}
                                className="sidebar_checkout mobile_modals"
                                type="button"
                              >
                                Gå til betaling ({totalOrders.length})
                              </MDBBtn>
                            </MDBRow>
                          </MDBCol>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ) : (
                  ' '
                )}
              </MDBRow>
            </MDBRow>
          </MDBContainer>

          <RenderMobileDineInTakeoutControl
            mainDivClassName={'dinein_takeout dinein_takeout_mobile_only'}
            fromMainPage
          />

          <MDBRow>
            {/* WEB ACTUAL MENU CONTENTS */}
            <MDBTabsContent className="tabcontent_custom xxxnon_mobile scrollspytabs">
              <div
                className="highercatcontainer relative"
                style={{ WebkitOverflowScrolling: 'touch', position: 'sticky', top: 0 }}
                ref={higherCatRef}
              >
                {categories?.map?.((item, index) => {
                  const isactive = selectedhighercat?.Category == item.Category;
                  return (
                    <>
                      <div
                        className={`highercat relative ${isactive ? 'active' : ''}`}
                        onClick={() => {
                          selectHigherCat(item);
                        }}
                        style={{
                          minWidth:
                            item.Category.length * 15 <= 150 ? 150 : item.Category.length * 15,
                        }}
                      >
                        <div>
                          <span className="highercattitle">{item.Category}</span>
                          <span className="highercattimes">
                            {item?.start} - {item?.end == '23:59' ? '00:00' : item?.end}
                          </span>
                        </div>
                        {/*{isactive ? <div className="arrow-down-cat" /> : null} */}
                      </div>
                    </>
                  );
                })}
              </div>

              {grouped.length > 0 ? (
                <ScrollSpyTabs
                  hasHigherCat={categories.length > 0}
                  tabsInScroll={(grouped || []).map((item) => {
                    let isDisabled = checkIfCategoryIsDisable(item);

                    return {
                      text: item.category,
                      component: (
                        <MenuItems
                          menus={item.menu}
                          toggleShow={toggleShow}
                          highercategories={categories}
                          isDisabled={isDisabled}
                          setMenuNotAvailable={setMenuNotAvailable}
                          isMobileView={isMobileView}
                          restaurant={restaurants}
                        />
                      ),
                    };
                  })}
                  isHigherCatOverflow={isHigherCatOverflow}
                />
              ) : (
                <div className="text-center nomenu"> Ingen menu tilgængelig</div>
              )}
            </MDBTabsContent>

            {/* WEB SIDEBAR DINE/TAKEOUT CONTROL */}
            <MDBCol className="dinecol non_mobile">
              <Sticky2 stickyClassName="stickySideBar">
                <MDBRow className="dinein_takeout">
                  <MDBCard alignment="center" className="">
                    <MDBCardBody className="sidebar_dinein_card">
                      <MDBRow className="dinecol2">
                        <MDBCol className="din_ordre">Bestillingsoversigt</MDBCol>
                        {totalOrders.length > 0 ? (
                          <MDBCol className="sidebar_order_count_all">
                            Indkøbskurv: {totalOrders.length}
                          </MDBCol>
                        ) : (
                          ''
                        )}
                      </MDBRow>
                      <p className="text-start start_help_text">
                        Her kan du se din ordre. Du skal vælge om din ordre skal serveres på stedet
                        eller om er til take-away.
                      </p>
                      <p className="pickup-help-text">Vælg venligst</p>
                      <MDBCol className="details_col">
                        {isDineIn == true ? (
                          <MDBBtnGroup className="restaurant-dtl-btn-radio1 dinein_btn_grp dinein_selected">
                            <MDBBtn
                              className="dinein_btn_grp1"
                              onClick={(e) => {
                                changeDineIn('dine', e.target);
                              }}
                              style={
                                !restaurantInfo.dineInSupported ? { cursor: 'not-allowed' } : {}
                              }
                            >
                              <MDBIcon fas icon="utensils" />
                              &nbsp;&nbsp;Dine in
                            </MDBBtn>
                            <MDBBtn
                              className="dinein_btn_grp2"
                              onClick={(e) => {
                                changeDineIn('pickup', e.target);
                              }}
                              style={
                                !restaurantInfo.takeOutSupported ? { cursor: 'not-allowed' } : {}
                              }
                            >
                              <MDBIcon fas icon="motorcycle" />
                              &nbsp;&nbsp;Afhent
                            </MDBBtn>
                          </MDBBtnGroup>
                        ) : (
                          ''
                        )}
                        {isDineIn == true ? (
                          ''
                        ) : (
                          <MDBBtnGroup className="restaurant-dtl-btn-radio1 dinein_btn_grp">
                            <MDBBtn
                              className="dinein_btn_grp4"
                              onClick={(e) => {
                                changeDineIn('dine', e.target);
                              }}
                              style={
                                !restaurantInfo.dineInSupported ? { cursor: 'not-allowed' } : {}
                              }
                            >
                              <MDBIcon fas icon="utensils" />
                              &nbsp;&nbsp;Dine in
                            </MDBBtn>
                            <MDBBtn
                              className="dinein_btn_grp3"
                              onClick={(e) => {
                                changeDineIn('pickup', e.target);
                              }}
                              style={
                                !restaurantInfo.takeOutSupported ? { cursor: 'not-allowed' } : {}
                              }
                            >
                              <MDBIcon fas icon="motorcycle" />
                              &nbsp;&nbsp;Afhent
                            </MDBBtn>
                          </MDBBtnGroup>
                        )}
                      </MDBCol>

                      <MDBRow>
                        {isDineIn == true ? (
                          ''
                        ) : (
                          <>
                            <p className="text-start pickup-help-text">
                              Afhent hurtigst muligt eller til afhentning senere?
                            </p>
                            {pickUp == 'now' ? (
                              <MDBCard>
                                <MDBCardBody className="place_order_now_later place_order_now">
                                  <MDBBtn
                                    className="order_now_btn order_now_btn_checked"
                                    onClick={() => {
                                      changePickupDate('now');
                                    }}
                                  >
                                    <MDBCol style={{ float: 'left', fontWeight: 'bold' }}>
                                      Bestil nu
                                    </MDBCol>
                                    <MDBCol style={{ float: 'right' }}>
                                      <MDBIcon
                                        style={{ color: '#FFFFFF' }}
                                        size="2x"
                                        fas
                                        icon="check-circle"
                                      />
                                    </MDBCol>
                                  </MDBBtn>
                                </MDBCardBody>
                              </MDBCard>
                            ) : (
                              <MDBCard className=" ">
                                <MDBCardBody className="place_order_now_later place_order_now">
                                  <MDBBtn
                                    className="order_now_btn"
                                    onClick={() => {
                                      changePickupDate('now');
                                    }}
                                  >
                                    <MDBCol style={{ float: 'left' }}>Bestil nu</MDBCol>
                                    <MDBCol style={{ float: 'right' }}>
                                      <MDBIcon
                                        style={{ color: '#FFFFFF' }}
                                        size="2x"
                                        far
                                        icon="circle"
                                      />
                                    </MDBCol>
                                  </MDBBtn>
                                </MDBCardBody>
                              </MDBCard>
                            )}

                            {pickUp == 'later' ? (
                              <>
                                <MDBCard className=" ">
                                  <MDBCardBody className="advance_order place_order_now_later place_order_later">
                                    <MDBBtn
                                      className="order_in_advance order_in_advance_checked"
                                      onClick={() => {
                                        changePickupDate('later');
                                      }}
                                      hidden={!restaurantInfo.advanceOrderSupported}
                                    >
                                      <MDBCol style={{ float: 'left', fontWeight: 'bold' }}>
                                        Forudbestil
                                      </MDBCol>
                                      <MDBCol style={{ float: 'right' }}>
                                        <MDBIcon
                                          style={{ color: '#FFFFFF' }}
                                          size="2x"
                                          fas
                                          icon="check-circle"
                                        />
                                      </MDBCol>
                                    </MDBBtn>
                                    <MDBTypography className="datetime_select_p">
                                      <MDBTypography className="datetime_select" tag="small">
                                        Afhentningstidspunkt
                                      </MDBTypography>
                                    </MDBTypography>
                                    <p className="pickupdate_p">
                                      <DatePicker
                                        className="pickupdate"
                                        selected={pickupDate}
                                        isClearable
                                        onChange={(date) => setPickupDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                      />
                                      <MDBIcon
                                        fas
                                        icon="angle-down"
                                        className="pickupdatetimearrowdown1"
                                      />
                                    </p>
                                    <p className="pickupdatetime_p"></p>
                                    <p className="pickuptime_p">
                                      <DatePicker
                                        className="pickuptime"
                                        selected={startTime}
                                        onChange={(date) => setstartTime(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={lagTime}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        filterTime={filterTime}
                                      />
                                      <MDBIcon
                                        fas
                                        icon="angle-down"
                                        className="pickupdatetimearrowdown2"
                                      />
                                    </p>
                                  </MDBCardBody>
                                </MDBCard>
                              </>
                            ) : (
                              <>
                                <MDBCard className="order_later_unselected">
                                  <MDBCardBody className="advance_order place_order_now_later place_order_later">
                                    <MDBBtn
                                      className="order_in_advance"
                                      onClick={() => {
                                        changePickupDate('later');
                                      }}
                                      hidden={!restaurantInfo.advanceOrderSupported}
                                    >
                                      <MDBCol style={{ float: 'left' }}>Forudbestil</MDBCol>
                                      <MDBCol style={{ float: 'right' }}>
                                        <MDBIcon size="2x" far icon="circle" />
                                      </MDBCol>
                                    </MDBBtn>
                                  </MDBCardBody>
                                </MDBCard>
                              </>
                            )}
                          </>
                        )}
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBRow>

                <MDBRow className="sidebar_checkout_row">
                  {typeof localStorage.getItem('orders') !== 'undefined' &&
                  localStorage.getItem('orders') !== null ? (
                    <MDBCol>
                      <MDBCard alignment="center" className="">
                        <MDBCardBody className="sidebar_checkout_card">
                          <MDBCardText>
                            <MDBCol className="sidebar_checkout_card_col">
                              {/*<MDBRow className="main_breakdown">
                                <MDBCol className="breakdown_main">
                                  <MDBTypography tag="strong">
                                    Antal produkter:{' '}
                                    {totalOrders.length > 0 ? totalOrders.length : ''}
                                  </MDBTypography>
                                </MDBCol>
                                <MDBCol className="breakdown_main">
                                  <MDBTypography tag="strong">
                                    {' '}
                                    Total: DKK {totalPrice}
                                  </MDBTypography>
                                </MDBCol>
                              </MDBRow>*/}
                              <MDBRow className="sidebar_checkout_btn">
                                <MDBBtn
                                  onClick={() => {
                                    if (isCheckoutAllowed) {
                                      toggleShow2();
                                    } else {
                                      toggleOrderNotAllowed();
                                    }
                                  }}
                                  className="sidebar_checkout desktop_modals"
                                  type="button"
                                >
                                  Total: DKK {totalPrice} - Gå til betaling
                                </MDBBtn>
                                <MDBBtn
                                  onClick={() => () => {
                                    if (isCheckoutAllowed) {
                                      toggleShow2mc();
                                    } else {
                                      toggleOrderNotAllowed();
                                    }
                                  }}
                                  className="sidebar_checkout mobile_modals"
                                  type="button"
                                >
                                  Total: DKK {totalPrice} Gå til betaling ({totalOrders.length})
                                </MDBBtn>
                              </MDBRow>
                            </MDBCol>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  ) : (
                    ' '
                  )}
                </MDBRow>
              </Sticky2>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>

      {/* DESKTOP/MOBILE MENU ATTRIBUTE SELECTION */}
      <MDBModal
        className="add_product_modal"
        show={basicModal}
        setShow={setBasicModal}
        tabIndex="-1"
      >
        <MDBModalDialog className="detail_modal">
          <MDBModalContent>
            <MDBModalHeader className="hide_desktop">
              <MDBBtn className="btn-close" color="none" onClick={closeModal}></MDBBtn>
            </MDBModalHeader>
            {/* WEB MENU ATTRIBUTE SELECTION */}
            {!isMobileView ? (
              <>
                <MDBModalBody className="desktop_add_cart">
                  {currentOrder.length > 0 ? (
                    <>
                      {/* {console.log(currentOrder)} */}
                      <MDBRow className="detail_tab_row_mobile detail_tab_addcart_image">
                        <MDBCol>
                          <img
                            src={currentOrder[0].orders[4]}
                            className="img_cart_desktop_full"
                            alt="..."
                            style={{}}
                          />
                        </MDBCol>
                      </MDBRow>
                      {/* AMOUNT START */}
                      <span className="amount_wrap">
                        <p className="amount_text">Antal</p>
                        <span className="toggle_holder_amount">
                          <MDBCol className="detail_modal_counterx add_to_cart_col">
                            <MDBBtn
                              onClick={() => decrementCounter()}
                              outline
                              color="black"
                              className="detail_modal_inc_dec_btn add_to_cart_plus_minus"
                              type="button"
                            >
                              <MDBIcon fas size="2x" icon="minus-circle" />
                            </MDBBtn>
                            <MDBInput
                              id="product_quantity"
                              type="number"
                              value={counter}
                              placeholder=""
                            />
                            <MDBBtn
                              onClick={() => incrementCounter()}
                              outline
                              color="black"
                              className="detail_modal_inc_dec_btn add_to_cart_plus_minus"
                              type="button"
                            >
                              <MDBIcon fas size="2x" icon="plus-circle" />
                            </MDBBtn>
                          </MDBCol>
                        </span>
                      </span>
                      {/* AMOUNT END */}
                      <MDBCol className="variation_spacer">
                        <MDBRow className="filler_only">&nbsp;</MDBRow>
                        <MDBRow className="detail_tab_row_mobile">
                          <MDBRow>
                            <MDBCard className="detail_tab_title_mobile" alignment="center">
                              <MDBCardBody>
                                <MDBCardTitle
                                  className="detail_tab_content_mobile pl-0"
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {' '}
                                  {currentOrder[0].orders[1]}{' '}
                                </MDBCardTitle>
                              </MDBCardBody>
                            </MDBCard>
                          </MDBRow>
                          <MDBRow className="detail_tab_row2_mobile">
                            <MDBCol>
                              <MDBCard alignment="center">
                                <MDBCardBody className="detail_tab_content_wrapper">
                                  <MDBCardText className="detail_tab_content_descr">
                                    {currentOrder[0].orders[3]}
                                  </MDBCardText>
                                </MDBCardBody>
                              </MDBCard>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="detail_tab_row2_mobile">
                            <MDBCol>
                              <MDBCard alignment="left">
                                <MDBCardBody style={{ padding: 0 }}>
                                  <MDBCardTitle className="detail_tab_content2_mobile">
                                    {' '}
                                    DKK {currentOrder[0].orders[2].toFixed(2)}{' '}
                                  </MDBCardTitle>
                                </MDBCardBody>
                              </MDBCard>
                            </MDBCol>
                          </MDBRow>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className="variation_spacer">
                        <MDBRow
                          className="detailTabCartExtra transparent-bg"
                          hidden={Object.entries(currentOrder?.[0]?.orders?.[6] || {}).length === 0}
                        >
                          <MDBCol className="detailTabCartExtraCol transparent-bg">
                            <MDBCard>
                              <MDBCardBody className="detailTabCartExtraBody">
                                <MDBCardText>Tilvalg</MDBCardText>
                              </MDBCardBody>
                            </MDBCard>
                          </MDBCol>
                        </MDBRow>

                        {/* EXTRAS */}
                        {Object.entries(currentOrder?.[0]?.orders?.[6] || {}).length === 0
                          ? ' '
                          : currentOrder?.[0]?.orders?.[6]?.map?.((extra) => (
                              <>
                                <MDBRow className="detailTabCartExtraItems">
                                  <MDBCol className="detailTabCartExtraItemsCol">
                                    <MDBCard>
                                      <MDBCardBody className="detailTabCartExtraItemsBody">
                                        <MDBCardText className="detailTabCartExtraItemsBodyText">
                                          {/* <MDBRow> */}
                                          {extra.name}
                                          <span className="toggle_holder">
                                            <MDBBtn
                                              onClick={() => incrementCounter3(extra._id)}
                                              outline
                                              color="black"
                                              className="extraCounters extraCountersButton"
                                              type="button"
                                            >
                                              <MDBIcon
                                                fas
                                                icon="plus-circle"
                                                // size="2x"
                                                className="detailTabCartExtraItemsBodyTextBtn"
                                              />
                                            </MDBBtn>
                                            <MDBInput
                                              value={extra.quantity}
                                              min="1"
                                              max="99"
                                              type="number"
                                              className="extraCounters"
                                              placeholder=""
                                            />
                                            <MDBBtn
                                              onClick={() => decrementCounter3(extra._id)}
                                              outline
                                              color="black"
                                              className="extraCounters extraCountersButton"
                                              type="button"
                                            >
                                              <MDBIcon
                                                fas
                                                icon="minus-circle"
                                                // size="2x"
                                                className="detailTabCartExtraItemsBodyTextBtn"
                                              />
                                            </MDBBtn>
                                          </span>
                                          {/* </MDBRow> */}
                                          <h5 className="extraPrice">DKK {extra.price} </h5>
                                        </MDBCardText>
                                      </MDBCardBody>
                                    </MDBCard>
                                  </MDBCol>
                                </MDBRow>
                              </>
                            ))}
                      </MDBCol>
                      <hr
                        style={{
                          padding: '0',
                          margin: '0',
                          marginTop: '30px',
                          marginBottom: '30px',
                        }}
                        hidden={Object.entries(currentOrder?.[0]?.orders?.[6] || {}).length === 0}
                      />

                      {/* ATTRIBUTES */}
                      {Object.entries(currentOrder?.[0]?.orders?.[7] || {}).length === 0
                        ? ' '
                        : currentOrder?.[0]?.orders?.[7]
                            ?.filter?.((item) => {
                              return !item.hasOwnProperty('belongsto');
                            })
                            ?.map?.((variation, index) => (
                              <>
                                <MDBCol className="variation_spacer">
                                  <MDBRow className="detailTabCartVariation">
                                    <MDBCol style={{ margin: '0', padding: '0' }}>
                                      <MDBCard>
                                        <MDBCardBody className="detailTabVariationBody">
                                          <MDBCardText>{variation.name} </MDBCardText>
                                        </MDBCardBody>
                                      </MDBCard>
                                    </MDBCol>
                                  </MDBRow>
                                  {variation?.options?.map?.((option) => (
                                    <MDBRow className="detailTabCartVariantItems">
                                      <MDBCol className="detailTabCartExtraItemsCol">
                                        <MDBCard>
                                          <MDBCardBody className="detailTabCartVariantItemsBody">
                                            <MDBCardText className="detailTabCartVariantItemsBodyText">
                                              <MDBRow className="attribLabel">
                                                {variation.isMultiple == true ? (
                                                  <>
                                                    {/* ISMULTIPLE TRUE */}
                                                    <MDBRow className="detailTabCartExtraItems">
                                                      <MDBCol className="detailTabCartExtraItemsCol">
                                                        <MDBCard>
                                                          <MDBCardBody className="detailTabCartExtraItemsBody p-0">
                                                            <MDBCardText className="detailTabCartExtraItemsBodyText">
                                                              {/* <MDBRow> */}
                                                              {option.name}
                                                              <span className="toggle_holder">
                                                                <MDBBtn
                                                                  onClick={() => {
                                                                    incrementCounterMutipleAttr(
                                                                      option._id,
                                                                    );
                                                                  }}
                                                                  outline
                                                                  color="black"
                                                                  className="extraCounters extraCountersButton"
                                                                  type="button"
                                                                >
                                                                  <MDBIcon
                                                                    fas
                                                                    icon="plus-circle"
                                                                    // size="2x"
                                                                    className="detailTabCartExtraItemsBodyTextBtn"
                                                                  />
                                                                </MDBBtn>
                                                                <MDBInput
                                                                  value={option.quantity}
                                                                  min="1"
                                                                  max="99"
                                                                  type="number"
                                                                  className="extraCounters"
                                                                  placeholder=""
                                                                />
                                                                <MDBBtn
                                                                  onClick={() => {
                                                                    decrementCounterMutipleAttr(
                                                                      option._id,
                                                                    );
                                                                  }}
                                                                  outline
                                                                  color="black"
                                                                  className="extraCounters extraCountersButton"
                                                                  type="button"
                                                                >
                                                                  <MDBIcon
                                                                    fas
                                                                    icon="minus-circle"
                                                                    // size="2x"
                                                                    className="detailTabCartExtraItemsBodyTextBtn"
                                                                  />
                                                                </MDBBtn>
                                                              </span>
                                                              {/* </MDBRow> */}
                                                              <h5 className="extraPrice">
                                                                DKK {option.additionalPrice}{' '}
                                                              </h5>
                                                            </MDBCardText>
                                                          </MDBCardBody>
                                                        </MDBCard>
                                                      </MDBCol>
                                                    </MDBRow>

                                                    {currentOrder?.[0]?.orders?.[7]?.map?.(
                                                      (variationx) =>
                                                        variationx.hasOwnProperty('belongsto') &&
                                                        variationx.belongsto == option._id ? (
                                                          <>
                                                            <MDBContainer
                                                              style={{
                                                                display: parentSelected(
                                                                  variationx.belongsto,
                                                                )
                                                                  ? 'block'
                                                                  : 'none',
                                                              }}
                                                              className={`variation_box_in ${variationx._id} mt-2 pl-4`}
                                                              fluid
                                                            >
                                                              <MDBRow className="variationx_name">
                                                                {' '}
                                                                {variationx.name}{' '}
                                                              </MDBRow>
                                                              {variationx?.options?.map?.(
                                                                (optionx) => (
                                                                  <>
                                                                    <label
                                                                      className="row detailTabCartVariantOptions css_flex cursor-pointer"
                                                                      for={
                                                                        variationx._id +
                                                                        '~' +
                                                                        optionx._id
                                                                      }
                                                                    >
                                                                      <MDBCol className="variationxoption">
                                                                        <MDBRadio
                                                                          // onChange={(e) =>
                                                                          //   attributeChange(e.target)
                                                                          // }
                                                                          onClick={(e) => {
                                                                            handleOptionSelection(
                                                                              e,
                                                                              optionx,
                                                                              variationx,
                                                                            );
                                                                          }}
                                                                          name={variationx._id}
                                                                          id={
                                                                            variationx._id +
                                                                            '~' +
                                                                            optionx._id
                                                                          }
                                                                          value={
                                                                            currentOrder[0]
                                                                              .orders[0] +
                                                                            '~' +
                                                                            variationx._id +
                                                                            '~' +
                                                                            optionx._id
                                                                          }
                                                                          label={optionx.name}
                                                                        />
                                                                      </MDBCol>
                                                                      <MDBCol className="variationxoption2">
                                                                        <h5 className="variationxoption2price">
                                                                          DKK {optionx.Price}
                                                                        </h5>
                                                                      </MDBCol>
                                                                    </label>
                                                                  </>
                                                                ),
                                                              )}
                                                            </MDBContainer>
                                                          </>
                                                        ) : (
                                                          ''
                                                        ),
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {/* ISMULTIPLE FALSE  */}
                                                    <label
                                                      className="css_flex"
                                                      for={variation._id + '~' + option._id}
                                                    >
                                                      <MDBRadio
                                                        // onChange={(e) => {
                                                        //   attributeChange(e.target);
                                                        // }}
                                                        onClick={(e) => {
                                                          handleOptionSelection(
                                                            e,
                                                            option,
                                                            variation,
                                                          );
                                                        }}
                                                        className="variation_box"
                                                        name={variation._id}
                                                        value={
                                                          currentOrder[0].orders[0] +
                                                          '~' +
                                                          variation._id +
                                                          '~' +
                                                          option._id
                                                        }
                                                        id={variation._id + '~' + option._id}
                                                        checked={option.selected == 'Y'}
                                                        // label={option.name}
                                                      />
                                                      <div className="form-check-label">
                                                        {option.name}
                                                      </div>
                                                      <MDBCol className="attribPriceCol">
                                                        <h5 className="attribPrice">
                                                          DKK {option.additionalPrice}{' '}
                                                        </h5>
                                                      </MDBCol>
                                                    </label>
                                                    {/* HAS ITEMS THAT BELONGS TO OPTIONS ABOVE */}
                                                    {currentOrder?.[0]?.orders?.[7]?.map?.(
                                                      (variationx) =>
                                                        variationx.hasOwnProperty('belongsto') &&
                                                        variationx.belongsto == option._id ? (
                                                          <>
                                                            <MDBContainer
                                                              style={{ display: 'none' }}
                                                              className={`variation_box_in ${variationx._id}`}
                                                              fluid
                                                            >
                                                              <MDBRow className="variationx_name">
                                                                {' '}
                                                                {variationx.name}
                                                              </MDBRow>
                                                              {variationx?.options?.map?.(
                                                                (optionx) => (
                                                                  <>
                                                                    <label
                                                                      className="row detailTabCartVariantOptions css_flex cursor-pointer"
                                                                      for={
                                                                        variationx._id +
                                                                        '~' +
                                                                        optionx._id
                                                                      }
                                                                    >
                                                                      <MDBCol className="variationxoption">
                                                                        <MDBRadio
                                                                          // onChange={(e) =>
                                                                          //   attributeChange(e.target)
                                                                          // }
                                                                          checked={
                                                                            optionx.selected == 'Y'
                                                                          }
                                                                          onClick={(e) => {
                                                                            handleOptionSelection(
                                                                              e,
                                                                              optionx,
                                                                              variationx,
                                                                            );
                                                                          }}
                                                                          name={variationx._id}
                                                                          id={
                                                                            variationx._id +
                                                                            '~' +
                                                                            optionx._id
                                                                          }
                                                                          value={
                                                                            currentOrder[0]
                                                                              .orders[0] +
                                                                            '~' +
                                                                            variationx._id +
                                                                            '~' +
                                                                            optionx._id
                                                                          }
                                                                          label={optionx.name}
                                                                        />
                                                                      </MDBCol>
                                                                      <MDBCol className="variationxoption2">
                                                                        <h5 className="variationxoption2price">
                                                                          DKK {optionx.Price}{' '}
                                                                        </h5>
                                                                      </MDBCol>
                                                                    </label>
                                                                  </>
                                                                ),
                                                              )}
                                                            </MDBContainer>
                                                          </>
                                                        ) : (
                                                          ''
                                                        ),
                                                    )}
                                                  </>
                                                )}
                                              </MDBRow>
                                            </MDBCardText>
                                          </MDBCardBody>
                                        </MDBCard>
                                      </MDBCol>
                                    </MDBRow>
                                  ))}
                                </MDBCol>
                                <hr style={{ marginTop: '30px', marginBottom: '30px' }} />
                              </>
                            ))}
                    </>
                  ) : null}
                </MDBModalBody>
              </>
            ) : null}

            {/* MOBILE MENU ATTRIBUTE SELECTION */}
            {isMobileView ? (
              <>
                <MDBModalBody className="mobile_add_cart">
                  {currentOrder.length > 0 ? (
                    <>
                      <MDBRow className="mc_menu_list_height_add_cart">
                        <MDBCol className="mc_image_menu_col" size="12">
                          <img
                            src={currentOrder[0].orders[4]}
                            className="img-thumbnail-non-mobile menu_item_list menu_item_image"
                            alt="..."
                            // style={{ maxWidth: '30rem' }}
                          />
                        </MDBCol>{' '}
                        <div className="mobile-menu-counter">
                          <div class="toggle_holder">
                            <div className="hatsune_flex">
                              <MDBCol size="2" className="mc_extraCounters">
                                <MDBBtn
                                  onClick={() => {
                                    decrementCounter();
                                  }}
                                  outline
                                  color="black"
                                  className="extraCounters extraCountersButton"
                                  type="button"
                                >
                                  <MDBIcon
                                    fas
                                    icon="minus-circle"
                                    // size="2x"
                                    className="detailTabCartExtraItemsBodyTextBtn"
                                  />
                                </MDBBtn>
                              </MDBCol>
                              <MDBCol size="3" className="mc_extraCounters mc_extraInputs">
                                <MDBInput
                                  value={counter}
                                  min="1"
                                  max="99"
                                  type="number"
                                  className="extraCounters"
                                  placeholder=""
                                  id="product_quantity"
                                />
                              </MDBCol>
                              <MDBCol size="2" className="mc_extraCounters">
                                <MDBBtn
                                  onClick={() => {
                                    incrementCounter();
                                  }}
                                  outline
                                  color="black"
                                  className="extraCounters extraCountersButton"
                                  type="button"
                                >
                                  <MDBIcon
                                    fas
                                    icon="plus-circle"
                                    // size="2x"
                                    className="detailTabCartExtraItemsBodyTextBtn"
                                  />
                                </MDBBtn>
                              </MDBCol>
                            </div>
                          </div>
                        </div>
                        {/* Title, text and price Start */}
                        <MDBCol size="12" className="mccol_5">
                          <MDBCardTitle className="mc_detail_tab_content mc_detail_tab_menu_item_name mc_menu_title">
                            {' '}
                            {currentOrder[0].orders[1]}
                          </MDBCardTitle>
                          <MDBRow>
                            <MDBCardText className="detail_tab_content_descr">
                              {currentOrder[0].orders[3]}
                            </MDBCardText>
                          </MDBRow>
                          <MDBCardTitle className="detail_tab_content2">
                            {' '}
                            DKK {currentOrder[0].orders[2].toFixed(2)}{' '}
                          </MDBCardTitle>
                        </MDBCol>
                        {/* Title, text and price End */}
                        {/* Increments Start */}
                        {/* Increments End */}
                      </MDBRow>

                      <MDBRow
                        className=" "
                        hidden={Object.entries(currentOrder?.[0]?.orders?.[6] || {}).length === 0}
                      >
                        <MDBCardText className="mc_extras_variants">Ekstra</MDBCardText>
                      </MDBRow>
                      {Object.entries(currentOrder?.[0]?.orders?.[6] || {}).length === 0
                        ? null
                        : currentOrder?.[0]?.orders?.[6]?.map?.((extra) => (
                            <>
                              <MDBRow className="detailTabCartVariantItems">
                                <MDBCol className="mobile_css_pad2">
                                  <MDBCardBody className="detailTabCartVariantItemsBody">
                                    <MDBCardText className="detailTabCartVariantItemsBodyText">
                                      <MDBRow className="attribLabel">
                                        <div className="css_flex">
                                          <div className="form-check-label">
                                            {extra.name}
                                            <h5 className="mc_extraPrice">DKK {extra.price} </h5>
                                          </div>
                                          <div class="toggle_holder">
                                            <div className="hatsune_flex">
                                              <MDBCol size="2" className="mc_extraCounters">
                                                <MDBBtn
                                                  onClick={() => {
                                                    decrementCounter3(extra._id);
                                                  }}
                                                  outline
                                                  color="black"
                                                  className="extraCounters extraCountersButton"
                                                  type="button"
                                                >
                                                  <MDBIcon
                                                    fas
                                                    icon="minus-circle"
                                                    className="detailTabCartExtraItemsBodyTextBtn"
                                                  />
                                                </MDBBtn>
                                              </MDBCol>
                                              <MDBCol
                                                size="3"
                                                className="mc_extraCounters mc_extraInputs"
                                              >
                                                <MDBInput
                                                  value={extra.quantity}
                                                  min="1"
                                                  max="99"
                                                  type="number"
                                                  className="extraCounters"
                                                  placeholder=""
                                                />
                                              </MDBCol>
                                              <MDBCol size="2" className="mc_extraCounters">
                                                <MDBBtn
                                                  onClick={() => {
                                                    incrementCounter3(extra._id);
                                                  }}
                                                  outline
                                                  color="black"
                                                  className="extraCounters extraCountersButton"
                                                  type="button"
                                                >
                                                  <MDBIcon
                                                    fas
                                                    icon="plus-circle"
                                                    // size="2x"
                                                    className="detailTabCartExtraItemsBodyTextBtn"
                                                  />
                                                </MDBBtn>
                                              </MDBCol>
                                            </div>
                                          </div>
                                        </div>
                                      </MDBRow>
                                    </MDBCardText>
                                  </MDBCardBody>
                                </MDBCol>
                              </MDBRow>
                            </>
                          ))}
                      {Object.entries(currentOrder[0].orders[7]).length === 0
                        ? null
                        : currentOrder?.[0]?.orders?.[7]?.map?.((variation) =>
                            !variation.hasOwnProperty('belongsto') ? (
                              <>
                                <MDBRow className=" ">
                                  <MDBCardText className="mc_extras_variants">
                                    {variation.name}
                                  </MDBCardText>
                                </MDBRow>
                                <MDBCard className="mc_variationCard">
                                  {variation?.options?.map?.((option) => (
                                    <MDBRow className="detailTabCartVariantItems">
                                      <MDBCol className="mobile_css_pad2">
                                        <MDBCardBody className="detailTabCartVariantItemsBody">
                                          <MDBCardText className="detailTabCartVariantItemsBodyText">
                                            <MDBRow className="attribLabel">
                                              {variation.isMultiple == true ? (
                                                <>
                                                  <div className="css_flex">
                                                    <div className="form-check-label">
                                                      {option.name}
                                                      <h5 className="mc_extraPrice">
                                                        DKK {option.additionalPrice}{' '}
                                                      </h5>
                                                    </div>
                                                    <div class="toggle_holder">
                                                      <div className="hatsune_flex">
                                                        <MDBCol
                                                          size="2"
                                                          className="mc_extraCounters"
                                                        >
                                                          <MDBBtn
                                                            onClick={() => {
                                                              decrementCounterMutipleAttr(
                                                                option._id,
                                                              );
                                                            }}
                                                            outline
                                                            color="black"
                                                            className="extraCounters extraCountersButton"
                                                            type="button"
                                                          >
                                                            <MDBIcon
                                                              fas
                                                              icon="minus-circle"
                                                              // size="2x"
                                                              className="detailTabCartExtraItemsBodyTextBtn"
                                                            />
                                                          </MDBBtn>
                                                        </MDBCol>
                                                        <MDBCol
                                                          size="3"
                                                          className="mc_extraCounters mc_extraInputs"
                                                        >
                                                          <MDBInput
                                                            value={option.quantity}
                                                            min="1"
                                                            max="99"
                                                            type="number"
                                                            className="extraCounters"
                                                            placeholder=""
                                                          />
                                                        </MDBCol>
                                                        <MDBCol
                                                          size="2"
                                                          className="mc_extraCounters"
                                                        >
                                                          <MDBBtn
                                                            onClick={() => {
                                                              incrementCounterMutipleAttr(
                                                                option._id,
                                                              );
                                                            }}
                                                            outline
                                                            color="black"
                                                            className="extraCounters extraCountersButton"
                                                            type="button"
                                                          >
                                                            <MDBIcon
                                                              fas
                                                              icon="plus-circle"
                                                              // size="2x"
                                                              className="detailTabCartExtraItemsBodyTextBtn"
                                                            />
                                                          </MDBBtn>
                                                        </MDBCol>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  {/* ISMULTIPLE FALSE */}
                                                  <label className="css_flex">
                                                    {
                                                      <>
                                                        <MDBRadio
                                                          checked={option.selected == 'Y'}
                                                          // onChange={(e) => attributeChange(e.target)}
                                                          onClick={(e) => {
                                                            handleOptionSelection(
                                                              e,
                                                              option,
                                                              variation,
                                                            );
                                                          }}
                                                          name={variation._id}
                                                          id={btoa(
                                                            variation._id + '~' + option._id,
                                                          )}
                                                          value={
                                                            currentOrder[0].orders[0] +
                                                            '~' +
                                                            variation._id +
                                                            '~' +
                                                            option._id
                                                          }
                                                          // label={option.name}
                                                        />
                                                        <div className="form-check-label">
                                                          {option.name}
                                                        </div>
                                                      </>
                                                      /* <MDBRadio onChange={e => attributeChange(e.target)} name={variation._id} id={variation._id + "~" + option._id} value={currentOrder[0].orders[0] + "~" + variation._id + "~" + option._id} label={option.name} /> */
                                                    }
                                                    <MDBCol className="attribPriceCol">
                                                      <h5 className="attribPrice">
                                                        DKK {option.additionalPrice}{' '}
                                                      </h5>
                                                    </MDBCol>
                                                  </label>
                                                </>
                                              )}
                                            </MDBRow>
                                          </MDBCardText>
                                        </MDBCardBody>
                                      </MDBCol>
                                      {currentOrder?.[0]?.orders?.[7]?.map?.((variationx) =>
                                        variationx.hasOwnProperty('belongsto') &&
                                        variationx.belongsto == option._id ? (
                                          <>
                                            <MDBContainer
                                              style={{
                                                display: parentSelected(variationx.belongsto)
                                                  ? 'block'
                                                  : 'none',
                                              }}
                                              className={`variation_box_in ${variationx._id}`}
                                              fluid
                                            >
                                              <MDBRow className="variationx_name">
                                                {variationx.name}
                                              </MDBRow>
                                              {variationx?.options?.map?.((optionx) => (
                                                <>
                                                  <label className="row detailTabCartVariantOptions cursor-pointer">
                                                    <div className="css_flex">
                                                      <MDBCol className="variationxoption">
                                                        <MDBRadio
                                                          // onChange={(e) =>
                                                          //   attributeChange(e.target)
                                                          // }
                                                          onClick={(e) => {
                                                            handleOptionSelection(
                                                              e,
                                                              optionx,
                                                              variationx,
                                                            );
                                                          }}
                                                          name={variationx._id}
                                                          id={btoa(
                                                            variationx._id + '~' + optionx._id,
                                                          )}
                                                          value={
                                                            currentOrder[0].orders[0] +
                                                            '~' +
                                                            variationx._id +
                                                            '~' +
                                                            optionx._id
                                                          }
                                                          label={optionx.name}
                                                        />
                                                      </MDBCol>
                                                      {/* <MDBCol className="variationxoption2">
                                                                DKK {optionx.Price}
                                                              </MDBCol> */}
                                                      <MDBCol className="variationxoption2">
                                                        <h5 className="variationxoption2price">
                                                          DKK {optionx.Price}{' '}
                                                        </h5>
                                                      </MDBCol>
                                                    </div>
                                                  </label>
                                                </>
                                              ))}
                                            </MDBContainer>
                                          </>
                                        ) : null,
                                      )}
                                    </MDBRow>
                                  ))}
                                </MDBCard>
                              </>
                            ) : null,
                          )}
                    </>
                  ) : null}
                </MDBModalBody>
              </>
            ) : null}

            <MDBModalFooter className="desktop_add_cart_footer">
              <MDBBtn color="success" className="add_to_cart" onClick={saveModal}>
                Tilføj til kurv
              </MDBBtn>
            </MDBModalFooter>
            <MDBModalFooter className="mobile_add_cart_footer">
              <MDBBtn color="success" className="add_to_cart" onClick={saveModal}>
                Tilføj til kurv
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* PREVIEW SELECTED ITEMS IN CART */}
      <ReviewModal
        basicModal2={basicModal2}
        setBasicModal2={setBasicModal2}
        closeModal2={closeModal2}
        clearCartItems={clearCartItems}
        checkout_items={checkout_items}
        extraChangeCheckOut={extraChangeCheckOut}
        attributeChangeCheckOut={attributeChangeCheckOut}
        handleOptionSelectionCheckout={handleOptionSelectionCheckout}
        decrementCounter2={decrementCounter2}
        incrementCounter2={incrementCounter2}
        removeFromCart={removeFromCart}
        totalOrders={totalOrders}
        contactChange={contactChange}
        validPhone={validPhone}
        actualIsCheckoutAllowed={actualIsCheckoutAllowed}
        toggleOrderNotAllowed={toggleOrderNotAllowed}
        validateForm={validateForm}
        setToggleTwoModal={setToggleTwoModal}
        RenderMobileDineInTakeoutControl={RenderMobileDineInTakeoutControl}
        totalPrice={totalPrice}
        final_contact={final_contact}
        tableNumbers={tableNumbers}
        final_table={final_table}
        tableChange={tableChange}
        validTable={validTable}
        final_notes={final_notes}
        notesChange={notesChange}
        isMobileView={isMobileView}
        isDineIn={isDineIn}
        toggleTwoModal={toggleTwoModal}
        parentSelected={parentSelected}
        setCheckOutItems={setCheckOutItems}
        setTotalOrders={setTotalOrders}
        calculatePrice={calculatePrice}
        userProfile={userProfile}
        orderContext={orderContext}
        isGuest={isGuest}
        guestInfo={guestInfo}
        setGuestInfo={setGuestInfo}
        guestInfoValidation={guestInfoValidation}
        setGuestInfoValidation={setGuestInfoValidation}
      />

      {/* FINAL CHECK OUT PAGE */}
      <MDBModal show={toggleTwoModal} setShow={setToggleTwoModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader className="payment_headerx">
              <MDBModalTitle className="desktop_modals">
                <MDBIcon
                  onClick={() => {
                    setToggleTwoModal(!toggleTwoModal);
                    setTimeout(() => {
                      setBasicModal2(!basicModal2);
                    }, 400);
                  }}
                  fas
                  icon="angle-left"
                />
                &nbsp;&nbsp;&nbsp;Betaling
              </MDBModalTitle>
              <MDBModalTitle className="mobile_modals">
                <MDBIcon
                  onClick={() => {
                    setToggleTwoModal(!toggleTwoModal);
                    setTimeout(() => {
                      setBasicModal2mc(!basicModal2mc);
                    }, 400);
                  }}
                  fas
                  icon="angle-left"
                />
                &nbsp;&nbsp;&nbsp;Betaling
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setToggleTwoModal(!toggleTwoModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="payment_header_body">
              <MDBCard className="tips remove_box_shadow">
                <MDBCardBody className="tipsbody">
                  <MDBCardTitle className=" ">Drikkepenge</MDBCardTitle>

                  <MDBCol>
                    <MDBBtnGroup
                      style={{ display: 'inline-block', transition: 'none' }}
                      className="tip_choices"
                    >
                      <MDBRadio
                        btn
                        btnColor="light"
                        id="no_tip"
                        onClick={(e) => tipChange(e.target)}
                        value="0"
                        name="tip"
                        wrapperTag="span"
                        label="Intet"
                        defaultChecked
                      />
                      {restaurantTips?.map?.((tip, v) => (
                        <>
                          <MDBRadio
                            btn
                            btnColor="light"
                            id={tip._id}
                            onClick={(e) => tipChange(e.target)}
                            value={tip.tipPercentage}
                            name="tip"
                            wrapperTag="span"
                            label={tip.name}
                            checked={tip._id == tipId}
                          />
                        </>
                      ))}
                    </MDBBtnGroup>
                  </MDBCol>
                </MDBCardBody>
              </MDBCard>

              <MDBCard
                className="tips remove_box_shadow"
                hidden={!restaurants?.BagTakeAway || isDineIn}
              >
                <MDBCardBody className="tipsbody">
                  <MDBCardTitle className=" ">Take Away Pose</MDBCardTitle>
                </MDBCardBody>
                <MDBCol>
                  <MDBBtnGroup
                    style={{ display: 'inline-block', transition: 'none', overflow: 'auto' }}
                    className="tip_choices"
                  >
                    <span>
                      <input type="radio" name="hasBag" checked={hasBag} hidden />
                      <label
                        onClick={(e) => setHasBag(true)}
                        checked={hasBag}
                        className="btn btn-light"
                      >
                        Ja
                      </label>
                    </span>
                    <span>
                      <input type="radio" name="hasBag" checked={!hasBag} hidden />
                      <label
                        onClick={(e) => setHasBag(false)}
                        checked={!hasBag}
                        className="btn btn-light"
                      >
                        Nej
                      </label>
                    </span>
                  </MDBBtnGroup>
                </MDBCol>
              </MDBCard>

              <MDBCard className="remove_box_shadow">
                <MDBCardBody className="paymentbreakdown">
                  <MDBCardTitle className="paytitle">Bestillingsoversigt</MDBCardTitle>
                  <MDBCardText>
                    <MDBCardText style={{ fontWeight: 'bold' }}>
                      {totalOrders.length} Vare(r)
                    </MDBCardText>
                    {/* <MDBCardText>
                      {
                        totalOrders.map((x, v) => (
                          <>
                            <h6 style={{ float: "left", fontWeight: "bold" }}>{x.orders[5] + 'x  ' + x.orders[1]}</h6>
                            <h6 style={{ float: "right", fontWeight: "bold", color: "rgb(149, 193, 31)" }}>DKK&nbsp;{roundToTwo((x.orders[2] + x.orders[8]) * x.orders[5])}</h6>
                            <br />
                            <br />
                            {x.orders[6].map((a, b) => (
                              <>
                                {
                                  (a.quantity > 0) ?
                                    <>
                                      <small style={{ float: "left" }}>
                                        {(a.quantity > 0) ? a.quantity + 'x  ' + a.name : ''}
                                      </small>
                                      <small style={{ float: "right" }}>DKK&nbsp;{roundToTwo(a.price * a.quantity)}</small>
                                      <br />
                                    </>
                                    : ''
                                }
                              </>
                            ))} 
                          </>
                        ))
                      }
                    </MDBCardText> */}
                    <MDBRow className="sidebar_checkout_card_row">
                      {totalOrders?.map?.((x, v) => (
                        <>
                          <MDBRow className="main_breakdown">
                            {/* <MDBCol className="breakdown_main">{x.orders[5] + 'x  ' + x.orders[1]}</MDBCol> */}
                            <MDBCol className="breakdown_main">
                              {' '}
                              <MDBTypography tag="strong">
                                {x.orders[5] + 'x  ' + x.orders[1]}{' '}
                              </MDBTypography>
                            </MDBCol>
                            {/* <MDBCol className="breakdown_price">DKK&nbsp;{roundToTwo((x.orders[2] + x.orders[8]) * x.orders[5])}</MDBCol> */}
                            <MDBCol className="breakdown_price">
                              DKK&nbsp;{roundToTwo(x.orders[5] * x.orders[2])}
                            </MDBCol>
                          </MDBRow>
                          {x?.orders?.[7]?.map?.((a, b) => (
                            <>
                              {typeof a._id == 'string' ? (
                                <>
                                  <MDBRow>
                                    <MDBCol className="breakdown_main">{a.name}</MDBCol>
                                  </MDBRow>
                                  {a?.options?.map?.((c, d) =>
                                    c.quantity > 0 ? (
                                      <>
                                        <MDBRow>
                                          <MDBCol className="breakdown_main breakdown_main2">
                                            {c.name}
                                          </MDBCol>
                                          <MDBCol className="breakdown_price">
                                            {roundToTwo(x.orders[5] * c.additionalPrice)}
                                          </MDBCol>
                                        </MDBRow>
                                        {x?.orders?.[7]?.map?.((e, f) => (
                                          <>
                                            {typeof e._id == 'number' &&
                                            !e.hasOwnProperty('isMultiple') ? (
                                              <>
                                                {e.belongsto == c._id
                                                  ? e.options.map((g, h) =>
                                                      g.quantity > 0 ? (
                                                        <MDBRow className="breakdown_main">
                                                          <MDBCol className="breakdown_main breakdown_main3">
                                                            {g.name}
                                                          </MDBCol>
                                                          <MDBCol className="breakdown_price">
                                                            {roundToTwo(x.orders[5] * g.Price)}
                                                          </MDBCol>
                                                        </MDBRow>
                                                      ) : (
                                                        ''
                                                      ),
                                                    )
                                                  : ''}
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      ''
                                    ),
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                              {typeof a._id == 'number' && a.hasOwnProperty('isMultiple')
                                ? a.isMultiple == false
                                  ? a.options.map((k, l) =>
                                      k.quantity > 0 ? (
                                        <MDBRow>
                                          <MDBCol className="breakdown_main breakdown_main2">
                                            {k.name}
                                          </MDBCol>
                                          <MDBCol className="breakdown_price">
                                            {roundToTwo(x.orders[5] * k.additionalPrice)}
                                          </MDBCol>
                                        </MDBRow>
                                      ) : (
                                        ''
                                      ),
                                    )
                                  : ''
                                : ''}
                            </>
                          ))}
                          <br />
                          {x?.orders?.[6]?.map?.((a, b) => (
                            <>
                              {a.quantity > 0 ? (
                                <>
                                  <MDBRow className="extras_breakdown">
                                    <MDBCol className="breakdown_extra">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      {a.quantity > 0 ? a.name + ` (${a.quantity})` : ''}
                                    </MDBCol>
                                    <MDBCol className="breakdown_price">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DKK&nbsp;
                                      {roundToTwo(x.orders[5] * a.price * a.quantity)}
                                    </MDBCol>
                                  </MDBRow>
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          ))}
                          {/* <hr/> */}
                        </>
                      ))}
                    </MDBRow>
                    <hr />
                    <MDBCardText
                      style={{ fontWeight: 'bold' }}
                      hidden={!restaurants?.BagTakeAway || isDineIn || !hasBag}
                    >
                      Take Away Pose:
                      <span className="summary_totals">DKK {restaurants?.BagTakeAwayPrice}</span>
                    </MDBCardText>
                    <MDBCardText style={{ fontWeight: 'bold' }}>
                      Drikkepenge: <span className="summary_totals">{tipPrice}</span>
                    </MDBCardText>
                    <MDBCardText style={{ fontWeight: 'bold' }}>
                      Gebyr:{' '}
                      <span className="summary_totals">
                        {' '}
                        {restaurants.serviceFee !== null && restaurants.serviceFee !== undefined ? (
                          <>{restaurants.currency.code + ' ' + restaurants.serviceFee}</>
                        ) : (
                          'Intet'
                        )}
                      </span>
                    </MDBCardText>
                    <MDBRow>
                      <MDBCol size="10">
                        <MDBCardText style={{ fontWeight: 'bold' }}>Total:&nbsp;</MDBCardText>
                      </MDBCol>
                      <MDBCol size="2">
                        <MDBCardText
                          style={{ fontWeight: 'bold', float: 'right', color: '#95C11F' }}
                        >
                          {totalPrice + tipPrice}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>

              <MDBCard className="remove_box_shadow">
                <MDBCardBody className="select_pay_method">
                  <div hidden={isGuest}>
                    <MDBCardTitle className="paytitle select_pay_method_title">
                      {/* <MDBIcon far icon="hand-pointer" /> */}
                      Vælg en betalingsmetode
                    </MDBCardTitle>
                    <MDBRow className="mobile_pay_row">
                      <MDBCard className="remove_box_shadow ">
                        <MDBCardBody
                          className="payment_method_select cursor-pointer"
                          onClick={(e) => {
                            document.getElementById('mobile_pay').click();
                            e.stopPropagation();
                          }}
                        >
                          <MDBCardTitle>
                            <input
                              onChange={(e) => cardChange(e.target.value)}
                              type="radio"
                              name="card_payment"
                              id="mobile_pay"
                              value="mobile_pay"
                            />
                            <label for="mobile_pay">
                              &nbsp; &nbsp;
                              <img src="/mobilepay40x40.png" height="40" alt="" loading="lazy" />
                              &nbsp; &nbsp; Mobile Pay
                            </label>
                            <br />
                          </MDBCardTitle>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBRow>
                    {isApplePayAvailable ? (
                      <MDBRow className=" ">
                        <MDBCard className="remove_box_shadow">
                          <MDBCardBody
                            className="payment_method_select cursor-pointer"
                            onClick={(e) => {
                              document.getElementById('apple_pay').click();
                              e.stopPropagation();
                            }}
                          >
                            <MDBCardTitle>
                              <input
                                onChange={(e) => cardChange(e.target.value)}
                                type="radio"
                                name="card_payment"
                                id="apple_pay"
                                value="apple_pay"
                              />
                              <label for="apple_pay">
                                <img
                                  src="/apple_pay.png"
                                  height="30"
                                  alt=""
                                  loading="lazy"
                                  className="apple_pay_img"
                                />
                                &nbsp;&nbsp;Apple Pay
                              </label>
                              <br />
                            </MDBCardTitle>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBRow>
                    ) : null}
                    {isGooglePayAvailable ? (
                      <MDBRow className=" ">
                        <MDBCard className="remove_box_shadow">
                          <MDBCardBody
                            className="payment_method_select cursor-pointer"
                            onClick={(e) => {
                              document.getElementById('google_pay').click();
                              e.stopPropagation();
                            }}
                          >
                            <MDBCardTitle>
                              <input
                                onChange={(e) => cardChange(e.target.value)}
                                type="radio"
                                name="card_payment"
                                id="google_pay"
                                value="google_pay"
                              />
                              <label for="google_pay">
                                <img
                                  src="/google_pay.png"
                                  height="30"
                                  alt=""
                                  loading="lazy"
                                  className="apple_pay_img"
                                />
                                &nbsp;&nbsp;Google Pay
                              </label>
                              <br />
                            </MDBCardTitle>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBRow>
                    ) : null}

                    {Object.entries(mycards).length === 0
                      ? ' '
                      : mycards.cards?.map?.((card) => (
                          <>
                            <MDBRow>
                              <MDBCard className="remove_box_shadow">
                                <MDBCardBody
                                  className="payment_method_select cursor-pointer"
                                  onClick={(e) => {
                                    document.getElementById(card.id).click();
                                    e.stopPropagation();
                                  }}
                                >
                                  <MDBCardTitle>
                                    <input
                                      onChange={(e) => cardChange(e.target.value)}
                                      type="radio"
                                      name="card_payment"
                                      id={card.id}
                                      value={card.id}
                                    />
                                    <label for={card.id}>
                                      &nbsp;&nbsp;
                                      {/* <MDBIcon far icon="credit-card" /> */}
                                      {card.card_type.toUpperCase() == 'VISA' ? (
                                        <img
                                          src="/visa40x40.png"
                                          height="40"
                                          alt=""
                                          loading="lazy"
                                        />
                                      ) : (
                                        <MDBIcon far icon="credit-card" />
                                      )}
                                      &nbsp; &nbsp; &nbsp; &nbsp;{card.masked_card}
                                      {/* &nbsp; ({card.card_type.toUpperCase ()}) */}
                                    </label>
                                    <br />
                                  </MDBCardTitle>
                                </MDBCardBody>
                              </MDBCard>
                            </MDBRow>
                          </>
                        ))}

                    <MDBCardText className="enteranewcard" style={{ textAlign: 'center' }}>
                      <MDBBtn
                        onClick={() => {
                          addCard();
                        }}
                        className="me-2   add_card_button"
                        type="button"
                      >
                        Indtast nyt debit/kreditkort
                      </MDBBtn>
                    </MDBCardText>
                  </div>

                  <MDBCardText style={{ textAlign: 'center' }}>
                    <MDBBtn
                      className="placeorder placeorder3"
                      style={{ width: '100%' }}
                      // onClick={() => payOrder()}
                      onClick={() => {
                        if (isGuest) {
                          orderViaGuest();

                          return;
                        }

                        if (card_to_use.length < 1) {
                          validateForm('card');
                        } else {
                          payOrder();
                        }
                      }}
                    >
                      Fortsæt til betaling
                    </MDBBtn>

                    {validCard ? (
                      ''
                    ) : (
                      <>
                        <MDBCardText className="validation_error">
                          Vælg venligst en betalingsmetode
                        </MDBCardText>
                      </>
                    )}
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBModalBody>
            {/* <MDBModalFooter>
              
            </MDBModalFooter> */}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={basicModal3} setShow={setBasicModal3} tabIndex="-1">
        <MDBModalDialog className="detail_modal">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {/* <MDBIcon fas icon="sign-in-alt" className="fs-2" /> */}
              </MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={closeModal3}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <>
                <MDBRow className="detail_login">
                  <MDBCol>
                    <MDBCard alignment="center">
                      <MDBCardBody>
                        {/* <img
                        src="/heywaitr.b08c1632.jpg"
                        className='img-thumbnail-detail-mobile'
                        alt='...'
                        style={{   }}
                      />   */}
                        <MDBCardTitle>
                          <MDBTypography tag="h3" className="mb-0 fw-bold">
                            Log ind for at fortsætte
                            {/* {(typeof mycards !== 'undefined' && mycards !== null)?'g':'ggg'} */}
                          </MDBTypography>
                        </MDBCardTitle>
                        <MDBCardText>
                          Hejsa! Log ind for at fortsætte eller
                          <a
                            className="signup_link"
                            //  href="/register"
                            onClick={() => {
                              toggleShow2();
                              setTimeout(() => {
                                setToggleCreateAccountModal(!toggleCreateAccountModal);
                              }, 400);
                            }}
                          >
                            &nbsp;Opret en konto
                          </a>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="detail_login">
                  <MDBCol>
                    <MDBCard>
                      <MDBCardBody className="detail_login_input_form">
                        {/* <MDBInputGroup textBefore='Contact Number' className='mb-3'> */}
                        {/* <label for="username">Username</label> */}
                        <input
                          id="username"
                          className="form-control"
                          type="text"
                          placeholder="Email"
                          onChange={(e) => usernameChange(e.target.value)}
                        />
                        {/* <label for="password">Password</label> */}
                        <input
                          id="password"
                          className="form-control"
                          type="password"
                          placeholder="Kodeord"
                          onChange={(e) => passwordChange(e.target.value)}
                        />
                        {/* </MDBInputGroup> */}
                        <MDBBtn
                          className="btn-lg detail_login_submit"
                          // color='none'
                          onClick={() => logIn()}
                        >
                          Log ind
                        </MDBBtn>
                        <p style={{ textAlign: 'center' }}>
                          <MDBTypography>
                            <MDBTypography className="forgotpassword" tag="small">
                              <a href="/forgot">Glemt kodeord?</a>
                            </MDBTypography>
                          </MDBTypography>
                        </p>
                        <ToastContainer
                          enableMultiContainer
                          containerId={'A'}
                          position="top-center"
                          autoClose={1000}
                          hideProgressBar={true}
                          newestOnTop={false}
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          className="toasterror"
                        />

                        <MDBRow>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <hr />
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <p style={{ textAlign: 'center' }}>eller</p>
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <hr />
                            </MDBTypography>
                          </MDBCol>
                        </MDBRow>

                        <FacebookLogin
                          appId="2799368613681742"
                          autoLoad={false}
                          fields="name,email,picture"
                          callback={responseFacebook}
                          render={(renderProps) => (
                            <MDBBtn
                              onClick={renderProps.onClick}
                              rounded
                              className="text-dark sign_in_others fs-6"
                              color="light"
                            >
                              <MDBIcon fab icon="facebook-square fs-4" style={{ float: 'left' }} />
                              <span>Log ind med Facebook</span>
                            </MDBBtn>
                          )}
                        />
                        {/* <GoogleLogin
                        clientId="356128359500-hmkgrgcckeo25hi8g2rmsfhl1tkci1ue.apps.googleusercontent.com"
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}           
                      />                                                                           */}
                        <MDBBtn
                          onClick={() => googleSingIn()}
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <MDBIcon fab icon="google fs-4" style={{ float: 'left' }} />
                          <span>Log ind med Google</span>
                        </MDBBtn>

                        <MDBBtn
                          disabled
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <MDBIcon fab icon="apple fs-4" style={{ float: 'left' }} />
                          <span>Log ind med Apple</span>
                        </MDBBtn>
                        <MDBBtn
                          disabled
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <span>Log ind som gæst</span>
                        </MDBBtn>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </>
            </MDBModalBody>
            <MDBModalFooter></MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        closeOnEsc={false}
        show={basicModal4}
        setShow={setBasicModal4}
        tabIndex="-1"
        staticBackdrop
      >
        <MDBModalDialog className="payment_confirm_modal">
          <MDBModalContent>
            <MDBModalHeader style={{ textAlign: 'center' }} className="modal-header-confirm">
              <MDBModalTitle className="processing_complete_title">
                🥳 Tak for din ordre!
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody className="complete_processing">
              <>
                <MDBRow>
                  <MDBCol>
                    <MDBCol className="processing_watch">
                      {/* <Watch
                        height="80"
                        width="80"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      /> */}
                      <ProcessingOrder />
                    </MDBCol>

                    <MDBTypography className="complete_processing_descr complete_processing_label">
                      Restauranten har modtaget din ordre og bekræfter den hurtigst muligt! ⚡
                    </MDBTypography>
                  </MDBCol>
                </MDBRow>
                {/* <MDBRow> 
                <MDBCol>
                  <MDBTypography tag='small'>{pay_confirm.date}</MDBTypography>
                </MDBCol>
              </MDBRow>  */}
              </>
            </MDBModalBody>
            <MDBModalFooter className="complete_processing_footer modal-footer-confirm ">
              <MDBRow className="">
                <MDBCol>
                  <MDBTypography className="complete_processing_label" tag="small">
                    Order ID: {pay_confirm._id}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBTypography
                    className="complete_processing_label complete_processing_receipt"
                    tag="small"
                  >
                    Kvitterings ID: {pay_confirm.refNo}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow className="total_amount_labael">
                <MDBCol size="8">
                  <MDBTypography
                    className="complete_processing_label complete_processing_label_amount"
                    tag="small"
                  >
                    Total:{' '}
                  </MDBTypography>
                </MDBCol>
                <MDBCol size="4" className="complete_processing_amount_col">
                  <MDBTypography
                    className="complete_processing_amount"
                    tag="small"
                    style={{ textAlign: 'right' }}
                  >
                    DKK {pay_confirm.totalAmount}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mc_total_amount_labael">
                <MDBCol size="6">
                  <MDBTypography
                    className="complete_processing_label complete_processing_label_amount"
                    tag="small"
                  >
                    Total:{' '}
                  </MDBTypography>
                </MDBCol>
                <MDBCol size="6" style={{ textAlign: 'right' }}>
                  <MDBTypography className="complete_processing_amount" tag="small">
                    DKK {pay_confirm.totalAmount}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal closeOnEsc={false} show={basicModal5} setShow={setBasicModal5} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <h2>Bestillingstider</h2>
              </MDBModalTitle>
              {/* <MDBBtn className='btn-close' color='none' onClick={closeModal4}></MDBBtn> */}
            </MDBModalHeader>
            <MDBModalBody>
              <>
                {pickUp == 'now' ? (
                  <MDBCard className=" ">
                    <MDBCardBody>
                      <MDBBtn
                        className="order_now_btn"
                        onClick={() => {
                          changePickupDate('now');
                        }}
                      >
                        <MDBCol style={{ float: 'left' }}>Bestil nu</MDBCol>
                        <MDBCol style={{ float: 'right' }}>
                          <MDBIcon style={{ color: 'green' }} size="lg" fas icon="check-circle" />
                        </MDBCol>
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                ) : (
                  <MDBCard className=" ">
                    <MDBCardBody>
                      <MDBBtn
                        className="order_now_btn"
                        onClick={() => {
                          changePickupDate('now');
                        }}
                      >
                        <MDBCol style={{ float: 'left' }}>Bestil nu</MDBCol>
                        <MDBCol style={{ float: 'right' }}>
                          <MDBIcon fas icon="check-circle" />
                        </MDBCol>
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                )}

                {pickUp == 'later' ? (
                  <MDBCard className=" ">
                    <MDBCardBody className="advance_order">
                      <MDBBtn
                        className="order_in_advance"
                        onClick={() => {
                          changePickupDate('later');
                        }}
                        hidden={!restaurantInfo.advanceOrderSupported}
                      >
                        <MDBCol style={{ float: 'left' }}>Forudbestil</MDBCol>
                        <MDBCol style={{ float: 'right' }}>
                          <MDBIcon style={{ color: 'green' }} size="lg" fas icon="check-circle" />
                        </MDBCol>
                      </MDBBtn>
                      <p className="pickupdate_p">
                        <DatePicker
                          className="pickupdate"
                          selected={pickupDate}
                          isClearable
                          onChange={(date) => setPickupDate(date)}
                          minDate={new Date()}
                        />
                        {/* <TimePicker 
                    className="pickuptime"
                    disableClock="true"
                    onChange={setPickupTime} value={pickupTime} /> */}
                      </p>
                    </MDBCardBody>
                  </MDBCard>
                ) : (
                  <MDBCard className=" ">
                    <MDBCardBody className="advance_order">
                      <MDBBtn
                        className="order_in_advance"
                        onClick={() => {
                          changePickupDate('later');
                        }}
                        disabled={!restaurantInfo.takeOutSupported}
                      >
                        <MDBCol style={{ float: 'left' }}>Forudbestil</MDBCol>
                        <MDBCol style={{ float: 'right' }}>
                          <MDBIcon fas icon="check-circle" />
                        </MDBCol>
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                )}
              </>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="placeorder"
                color="success"
                onClick={() => {
                  setBasicModal5(!basicModal5);
                }}
              >
                Vælg tidspunkt
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* MOBILE VERSION */}
      <MDBModal show={nowOrLaterModal} setShow={setNowOrLaterModal} tabIndex="-1">
        <MDBModalDialog size="fullscreen">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="mc_order_schedule">Afhentningstidspunkt</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowNowOrLaterModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="mc_placeorder_modal_body overflow_none">
              <MDBRow className="mc_placeorder">
                {
                  <>
                    {pickUp == 'now' ? (
                      <MDBCard className="remove_box_shadow">
                        <MDBCardBody className="place_order_now_later place_order_now">
                          <MDBBtn
                            className="order_now_btn order_now_btn_checked"
                            onClick={() => {
                              changePickupDate('now');
                            }}
                          >
                            <MDBCol style={{ float: 'left', fontWeight: 'bold' }}>Bestil nu</MDBCol>
                            <MDBCol style={{ float: 'right' }}>
                              <MDBIcon
                                style={{ color: '#88B11A' }}
                                size="2x"
                                fas
                                icon="check-circle"
                              />
                            </MDBCol>
                          </MDBBtn>
                        </MDBCardBody>
                      </MDBCard>
                    ) : (
                      <MDBCard className="remove_box_shadow">
                        <MDBCardBody className="place_order_now_later place_order_now">
                          <MDBBtn
                            className="order_now_btn"
                            onClick={() => {
                              changePickupDate('now');
                            }}
                          >
                            <MDBCol style={{ float: 'left' }}>Bestil nu</MDBCol>
                            <MDBCol style={{ float: 'right' }}>
                              <MDBIcon size="2x" far icon="circle" />
                            </MDBCol>
                          </MDBBtn>
                        </MDBCardBody>
                      </MDBCard>
                    )}

                    {pickUp == 'later' ? (
                      <>
                        <MDBCard className="remove_box_shadow">
                          <MDBCardBody className="advance_order place_order_now_later place_order_later">
                            <MDBBtn
                              className="order_in_advance order_in_advance_checked"
                              onClick={() => {
                                changePickupDate('later');
                              }}
                              hidden={!restaurantInfo.advanceOrderSupported}
                            >
                              <MDBCol style={{ float: 'left', fontWeight: 'bold' }}>
                                Forudbestil
                              </MDBCol>
                              <MDBCol style={{ float: 'right' }}>
                                <MDBIcon
                                  style={{ color: '#88B11A' }}
                                  size="2x"
                                  fas
                                  icon="check-circle"
                                />
                              </MDBCol>
                            </MDBBtn>
                            <MDBTypography className="datetime_select_p">
                              <MDBTypography className="datetime_select" tag="small">
                                <MDBIcon fas icon="clock" />
                                &nbsp;&nbsp;Afhentningstidspunkt
                              </MDBTypography>
                            </MDBTypography>
                            <p className="pickupdate_p">
                              <DatePicker
                                className="pickupdate"
                                selected={pickupDate}
                                isClearable
                                onChange={(date) => setPickupDate(date)}
                                dateFormat="dd/MM/yyyy"
                                minDate={new Date()}
                              />
                              <MDBIcon fas icon="angle-down" className="pickupdatetimearrowdown1" />
                            </p>
                            <p className="pickupdatetime_p"></p>
                            <p className="pickuptime_p">
                              <DatePicker
                                className="pickuptime"
                                selected={startTime}
                                onChange={(date) => setstartTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="HH:mm"
                                timeIntervals={lagTime}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                filterTime={filterTime}
                              />
                              <MDBIcon fas icon="angle-down" className="pickupdatetimearrowdown2" />
                            </p>
                          </MDBCardBody>
                        </MDBCard>
                      </>
                    ) : (
                      <>
                        <MDBCard className="order_later_unselected remove_box_shadow">
                          <MDBCardBody className="advance_order place_order_now_later place_order_later">
                            <MDBBtn
                              className="order_in_advance"
                              onClick={() => {
                                changePickupDate('later');
                              }}
                              hidden={!restaurantInfo.advanceOrderSupported}
                            >
                              <MDBCol style={{ float: 'left' }}>Forudbestil</MDBCol>
                              <MDBCol style={{ float: 'right' }}>
                                <MDBIcon size="2x" far icon="circle" />
                              </MDBCol>
                            </MDBBtn>
                          </MDBCardBody>
                        </MDBCard>
                      </>
                    )}
                  </>
                }
              </MDBRow>
              <MDBRow className="mc_button_orderfrequency_row">
                <MDBBtn
                  className="mc_button_orderfrequency"
                  color="secondary"
                  onClick={() => {
                    setBasicModal2mc(!basicModal2mc);
                    setTimeout(() => {
                      setNowOrLaterModal(!nowOrLaterModal);
                    }, 400);
                  }}
                >
                  Vælg afhentningstidspunkt
                </MDBBtn>
              </MDBRow>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={toggleCreateAccountModal} setShow={setToggleCreateAccountModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Opret konto</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setToggleCreateAccountModal(!toggleCreateAccountModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer className="signin_container" breakpoint="sm">
                <MDBRow className="detail_signin">
                  <MDBCol>
                    <MDBCard alignment="center">
                      <MDBCardBody>
                        <MDBCardTitle>
                          <MDBTypography tag="h3" className="mb-0 fw-bold">
                            Hejsa! Opret konto
                          </MDBTypography>
                        </MDBCardTitle>
                        <MDBCardText>
                          Har du allerede en konto?
                          <a
                            className="signup_link"
                            // href="/login"
                            onClick={() => {
                              setToggleCreateAccountModal(!toggleCreateAccountModal);
                              setTimeout(() => {
                                toggleShow2();
                              }, 400);
                            }}
                          >
                            Sign in
                          </a>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="detail_signin">
                  <MDBCol>
                    <MDBCard>
                      <MDBCardBody className="detail_signin_input_form">
                        <input
                          id="username"
                          className="form-control"
                          type="text"
                          placeholder="Navn"
                          onChange={(e) => usernameChange(e.target.value)}
                        />
                        <input
                          id="email"
                          className="form-control"
                          type="text"
                          placeholder="Email"
                          onChange={(e) => emailChange(e.target.value)}
                        />
                        <input
                          id="contact"
                          className="form-control"
                          type="text"
                          placeholder="Telefonummer"
                          onChange={(e) => contactChangeSignUp(e.target.value)}
                        />
                        <input
                          id="password"
                          className="form-control"
                          type="password"
                          placeholder="Kodeord"
                          onChange={(e) => passwordChange(e.target.value)}
                        />
                        {/* <input
                          id="confirm_password"
                          className="form-control"
                          type="password"
                          placeholder="Bekræft kodeord"
                          // onChange={(e) => confirmPasswordChange(e.target.value)}
                        /> */}
                        <input
                          id="agree_to_terms"
                          type="checkbox"
                          onChange={(e) => agreeTermsChange(e.target.value)}
                        />
                        <label for="agree_to_terms">
                          &nbsp;Ved at oprette en konto accepterer du vores{' '}
                          <a
                            href="https://www.heywaitr.com/heywaitr-app-global-terms-and-conditions/"
                            target="_blank"
                          >
                            Betingelser
                          </a>
                        </label>
                        <MDBBtn className="btn-lg detail_signin_submit" onClick={() => signUp()}>
                          Opret konto
                        </MDBBtn>
                        <ToastContainer
                          enableMultiContainer
                          containerId={'B'}
                          position="top-center"
                          autoClose={1000}
                          hideProgressBar={true}
                          newestOnTop={false}
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          className="toasterror"
                        />
                        <p style={{ textAlign: 'center' }}></p>
                        {/* <ToastContainer
                          position="top-center"
                          autoClose={1000}
                          hideProgressBar={true}
                          newestOnTop={false}
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          className="toasterror"
                        /> */}

                        {/* <MDBRow>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <hr />
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <p style={{ textAlign: 'center' }}>eller</p>
                            </MDBTypography>
                          </MDBCol>
                          <MDBCol>
                            <MDBTypography tag="small">
                              <hr />
                            </MDBTypography>
                          </MDBCol>
                        </MDBRow>

                        <FacebookLogin
                          appId="2799368613681742"
                          autoLoad={false}
                          fields="name,email,picture"
                          callback={responseFacebook}
                          render={(renderProps) => (
                            <MDBBtn
                              onClick={renderProps.onClick}
                              rounded
                              className="text-dark sign_in_others fs-6"
                              color="light"
                            >
                              <MDBIcon fab icon="facebook-square fs-4" style={{ float: 'left' }} />
                              <span>Log ind med Facebook</span>
                            </MDBBtn>
                          )}
                        />
                        <MDBBtn rounded className="text-dark sign_in_others fs-6" color="light">
                          <MDBIcon fab icon="google fs-4" style={{ float: 'left' }} />
                          <span>Log ind med Google</span>
                        </MDBBtn>
                        <MDBBtn
                          disabled
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <MDBIcon fab icon="apple fs-4" style={{ float: 'left' }} />
                          <span>Log ind med Apple</span>
                        </MDBBtn>
                        <MDBBtn
                          disabled
                          rounded
                          className="text-dark sign_in_others fs-6"
                          color="light"
                        >
                          <span>Fortsæt som Gæst</span>
                        </MDBBtn> */}
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={orderNotAllowed} staticBackdrop>
        <MDBModalDialog className="detail_modal openhours_modal">
          <MDBModalContent style={{ maxWidth: '600px' }}>
            <MDBModalBody
              className="text-center"
              style={{
                padding: '6rem',
              }}
            >
              <h4>Hovsa! 👀</h4>
              <p>Du kan ikke vælge ikke "Bestil nu", da restautanten har lukket lige nu.⛔</p>
              <p>
                {' '}
                Du kan vælge et afhentningstidspunkt når restauranten har åbent ved at vælge
                "Forudbestil" ⌚
              </p>
              <MDBBtn
                color="success p-1 my-4"
                className="add_to_cart"
                style={{
                  width: '150px',
                  height: '40px',
                }}
                onClick={() => {
                  setOrderNotAllowed(false);
                }}
              >
                Luk
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={menuNotAvailable} staticBackdrop>
        <MDBModalDialog className="detail_modal openhours_modal">
          <MDBModalContent style={{ maxWidth: '600px' }}>
            <MDBModalBody
              className="text-center"
              style={{
                padding: '6rem',
              }}
            >
              <h4>Hovsa! 👀</h4>
              <p>
                Der kan ikke bestilles fra denne kategori, da det er uden for kategoriens
                åbningstid. ⌚
              </p>
              <p>Prøv igen inden for kategoriens åbningstid eller vælg en anden kategori. 👍</p>
              <MDBBtn
                color="success p-1 my-4"
                className="add_to_cart"
                style={{
                  width: '150px',
                  height: '40px',
                }}
                onClick={() => {
                  setMenuNotAvailable(false);
                }}
              >
                Luk
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={rushModeModal} staticBackdrop>
        <MDBModalDialog className="detail_modal openhours_modal">
          <MDBModalContent style={{ maxWidth: '600px' }}>
            <MDBModalBody
              className="text-center"
              style={{
                padding: '6rem',
              }}
            >
              <h4>{restaurants.RushModeHeader}</h4>
              <p>{restaurants.RushModeMessage} </p>
              <MDBBtn
                color="success p-1 my-4"
                className="add_to_cart"
                style={{
                  width: '150px',
                  height: '40px',
                }}
                onClick={() => {
                  setRushModeModal(false);
                }}
              >
                Luk
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {typeof localStorage.getItem('orders') !== 'undefined' &&
      localStorage.getItem('orders') !== null ? (
        <MDBRow className="mc_detail_tab_checkout">
          <MDBCard alignment="center" className="mc_detail_tab_checkout_card">
            <MDBCardBody>
              <MDBCardText>
                <MDBRow>
                  <MDBCol className="mc_Checkout" size="2">
                    {/* <MDBIcon className="cart_icon fa-2x"  fas icon="shopping-cart" /> */}
                    <img
                      src="/icon-order.png"
                      className="dine_or_pickup"
                      alt="..."
                      style={{ paddingLeft: '0' }}
                    />
                  </MDBCol>
                  <MDBCol className="mc_checkout_items" size="3">
                    <span className="mc_checkout_items_items">{totalOrders.length} Vare(r)</span>
                    <br />
                    <span className="mc_checkout_items_price">DKK {totalPrice}</span>
                  </MDBCol>
                  <MDBCol className="mc_Checkout" size="7">
                    <MDBBtn
                      onClick={() => {
                        if (isCheckoutAllowed) {
                          toggleShow2();
                        } else {
                          toggleOrderNotAllowed();
                        }
                      }}
                      outline
                      color="black"
                      className="desktop_modals"
                      type="button"
                    >
                      Gå til betaling
                    </MDBBtn>
                    {/* <MDBBtn onClick={() => toggleShow2mc()} outline color="black" className='mobile_modals' type='button'>
                        Check Out
                      </MDBBtn> */}
                    <MDBBtn
                      onClick={() => {
                        if (isCheckoutAllowed) {
                          toggleShow2();
                        } else {
                          toggleOrderNotAllowed();
                        }
                      }}
                      outline
                      color="black"
                      className="mobile_modals checkout_mobile_modals"
                      type="button"
                    >
                      Gå til betaling
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      ) : (
        ' '
      )}

      <Footer />

      <MDBRow className="navigation_bar_small">
        <MDBCol size="4">
          <a href="/">
            <MDBIcon fas icon="home" />
          </a>
        </MDBCol>
        <MDBCol size="4">
          {(localStorage.getItem('user_profile') !== undefined &&
            localStorage.getItem('user_profile') !== null) |
          (userProfile.length !== 0 && userProfile !== null) ? (
            <a href="/orders">
              <MDBIcon fas icon="utensils" />
            </a>
          ) : (
            <MDBIcon fas icon="utensils" />
          )}
        </MDBCol>
        <MDBCol size="4">
          {(localStorage.getItem('user_profile') !== undefined &&
            localStorage.getItem('user_profile') !== null) |
          (userProfile.length !== 0 && userProfile !== null) ? (
            <a href="/profile">
              <MDBIcon fas icon="user-alt" />
            </a>
          ) : (
            <MDBIcon fas icon="user-alt" />
          )}
        </MDBCol>
      </MDBRow>

      {loading && <Loader />}
    </>
  );
}

function MenuItems(props) {
  const { menus, toggleShow, isDisabled, setMenuNotAvailable, isMobileView, restaurant } = props;

  return (
    <div>
      {menus?.map?.((menu, uniqueCategoryIndex, uniqueCategoryArray) => {
        const defaultImg =
          'https://res.cloudinary.com/burgerkingnorway/image/upload/v1674745616/Heywaitr/1674745616-images.png';

        if (!menu.image) {
          menu.image = restaurant.MenuPlaceholder || defaultImg;
        }

        const isDefault = menu.image == restaurant.menuPlaceholder;

        return (
          <MDBTabsPane show={true}>
            {uniqueCategoryIndex == 0 ? (
              <>
                <>
                  <div>
                    <MDBCol className="category_intro">
                      <MDBCardTitle>
                        {menu?.category?.replace(
                          /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                          '',
                        )}
                      </MDBCardTitle>
                      <MDBCardText className="detail_tab_content_descr hmm">
                        <div
                          style={{ whiteSpace: 'pre-wrap' }}
                          dangerouslySetInnerHTML={{ __html: menu.categorydesc }}
                        />
                        {/* {menu.categorydesc} */}
                      </MDBCardText>
                    </MDBCol>
                  </div>
                </>
              </>
            ) : (
              ''
            )}

            {/* NEW LIST START */}
            <MDBRow className="menu_list_height">
              <MDBCol size="3" className="mobile_css_col1">
                <img
                  src={menu.image}
                  className="img-thumbnail-non-mobile menu_item_list menu_item_image"
                  alt="..."
                  style={{
                    display:
                      restaurant._id == '64f856dde0639b00147d6cbe' && isMobileView
                        ? 'none'
                        : 'block',
                  }}
                />
              </MDBCol>
              <MDBCol size="7" className="mobile_css_col2">
                <MDBCard className="our_menu_card" alignment="center">
                  <MDBCardBody className="menu_details_all">
                    <MDBCardTitle className="detail_tab_content detail_tab_menu_item_name menu_title">
                      {' '}
                      {menu.name}
                    </MDBCardTitle>
                    <MDBCardText className="detail_tab_content_descr">
                      <div
                        style={{ whiteSpace: 'pre-wrap' }}
                        dangerouslySetInnerHTML={{ __html: menu.description }}
                      />
                    </MDBCardText>
                    <MDBCardTitle className="detail_tab_content2">
                      {' '}
                      DKK {menu?.price?.toFixed?.(2)}{' '}
                    </MDBCardTitle>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol
                size="2"
                className="detail_tab_content_btn_col mobile_css_col1 mobile_css_btn_order"
              >
                <MDBBtn
                  onClick={() => {
                    if (isDisabled) {
                      setMenuNotAvailable(true);
                      return;
                    }

                    props.toggleShow(
                      menu._id,
                      menu.name,
                      menu.price,
                      menu.description,
                      menu.image,
                      0,
                      menu.extras,
                      menu.attributes,
                    );
                  }}
                  outline
                  color="black"
                  className="me-2 detail_tab_content_btn order_button"
                  type="button"
                  hidden={!restaurant?.isMenuBuyable}
                  // disabled={isDisabled}
                >
                  Bestil <MDBIcon fas icon="angle-right" className="mobile_css_none" />
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            {/* NEW LIST END */}
          </MDBTabsPane>
        );
      })}
    </div>
  );
}

function RenderAvailability(props) {
  const { restaurants } = props;

  const days = [
    {
      key: 'Mon',
      label: 'Mandag',
    },
    {
      key: 'Tue',
      label: 'Tirsdag',
    },
    {
      key: 'Wed',
      label: 'Onsdag',
    },
    {
      key: 'Thu',
      label: 'Torsdag',
    },
    {
      key: 'Fri',
      label: 'Fredag',
    },
    {
      key: 'Sat',
      label: 'Lørdag',
    },
    {
      key: 'Sun',
      label: 'Søndag',
    },
  ];

  const getDayEarliestOrLatest = (day, type, startordend) => {
    const times = [
      restaurants?.availability?.[day]?.start + ' - ' + restaurants?.availability?.[day]?.end,
    ];

    if (restaurants?.availability?.[day]?.othertime) {
      restaurants.availability[day].othertime.map((item) => {
        times.push(item?.start + ' - ' + item.end);
      });
    }

    const sorted = times.sort();

    if (type == 'earliest') {
      if (startordend == 'start') {
        return sorted[0].split(' - ')[0];
      } else {
        return sorted[0].split(' - ')[1];
      }
    } else if (type == 'latest') {
      if (startordend == 'start') {
        return sorted[sorted.length - 1].split(' - ')[0];
      } else {
        return sorted[sorted.length - 1].split(' - ')[1];
      }
    }
  };

  const getTime = (day) => {
    let finalStartTime = '';
    let finalEndTime = '';
    let finalTimesMultiple = [];

    const { key, label } = day;

    //Check if close
    if (!restaurants?.availability?.[key]?.isAvailable) {
      return (
        <p className="schedule">
          <span className="weekname">{label}:</span> Lukket
        </p>
      );
    }

    // Single time only
    if ((restaurants?.availability?.[key]?.othertime || []).length == 0) {
      const { start, end } = restaurants?.availability?.[key];

      finalStartTime = start;
      finalEndTime = end;

      //Check if start is 00:00 and end is 23:59 then open 24 hours
      if (start == '00:00' && end == '23:59') {
        finalStartTime = start;
        finalEndTime = end;
      }

      // Check if end is 23:59 meaning closing is in the next day
      if (end == '23:59') {
        const thisDayIndex = days.findIndex((a) => a.key == key);
        let nextDay = days[thisDayIndex + 1];

        // If day is Sunday go back for Monday
        if (!nextDay) {
          nextDay = days[0];
        }
        // Check if next day start is 00:00 then continue the timeframe display
        const nextDayEarliestStartTime = getDayEarliestOrLatest(nextDay.key, 'earliest', 'start');
        if (nextDayEarliestStartTime == '00:00') {
          const nextDayEarliestEndTime = getDayEarliestOrLatest(nextDay.key, 'earliest', 'end');

          finalStartTime = start;
          finalEndTime = nextDayEarliestEndTime;
        }
      }

      //if midnight then display 00:00 for display purposes
      if (finalEndTime == '23:59') {
        finalEndTime = '00:00';
      }
    }

    // Multiple times
    if ((restaurants?.availability?.[key]?.othertime || []).length > 0) {
      // Lists all times and sort by start
      const times = [
        restaurants?.availability?.[key]?.start + ' - ' + restaurants?.availability?.[key]?.end,
      ];

      if (restaurants?.availability?.[key]?.othertime) {
        restaurants.availability[key].othertime.map((item) => {
          times.push(item?.start + ' - ' + item.end);
        });
      }

      const sorted = times.sort();

      // Loop through times
      sorted.map((time) => {
        let start = time.split(' - ')[0];
        let end = time.split(' - ')[1];

        const thisDayIndex = days.findIndex((a) => a.key == key);
        let lastDay = days[thisDayIndex - 1];

        // If day is Monday go back for Sunday
        if (!lastDay) {
          lastDay = days[6];
        }

        //Check if start is 00:00 and end is 23:59 then open 24 hours
        if (start == '00:00' && end == '23:59') {
          //if midnight then display 00:00 for display purposes
          if (end == '23:59') {
            end = '00:00';
          }

          finalTimesMultiple.push({
            start,
            end,
          });
          return;
        }

        // Check if start is 00:00 meaning opening might be from previous day so check previous and do not include this timeframe
        if (start == '00:00') {
          const lastDayLatestEndTime = getDayEarliestOrLatest(lastDay.key, 'latest', 'end');

          if (lastDayLatestEndTime == '23:59') {
            return;
          }
        }

        // Check if end is 23:59 meaning closing is in the next day
        if (end == '23:59') {
          const thisDayIndex = days.findIndex((a) => a.key == key);
          let nextDay = days[thisDayIndex + 1];

          // If day is Sunday go back for Monday
          if (!nextDay) {
            nextDay = days[0];
          }
          // Check if next day start is 00:00 then continue the timeframe display
          const nextDayEarliestStartTime = getDayEarliestOrLatest(nextDay.key, 'earliest', 'start');
          if (nextDayEarliestStartTime == '00:00') {
            const nextDayEarliestEndTime = getDayEarliestOrLatest(nextDay.key, 'earliest', 'end');

            //if midnight then display 00:00 for display purposes
            if (nextDayEarliestEndTime == '23:59') {
              nextDayEarliestEndTime = '00:00';
            }

            finalTimesMultiple.push({
              start,
              end: nextDayEarliestEndTime,
            });
            return;
          }
        }

        //if midnight then display 00:00 for display purposes
        if (end == '23:59') {
          end = '00:00';
        }

        // Check if start is 00:00 and end is 23:59 then open 24 hours
        // Else display as normal
        finalTimesMultiple.push({
          start,
          end,
        });
      });
    }

    if (finalTimesMultiple.length > 0) {
      return (
        <p className="schedule">
          <span className="weekname">{label}:</span>{' '}
          {finalTimesMultiple?.[0].start + ' - ' + finalTimesMultiple?.[0].end}
          {finalTimesMultiple?.length > 1
            ? [...finalTimesMultiple]?.slice?.(1)?.map?.((item) => (
                <>
                  <br />
                  <span style={{ marginLeft: '5.2rem' }}>
                    {item.start} - {item.end}
                  </span>
                </>
              ))
            : null}
        </p>
      );
    }

    return (
      <p className="schedule">
        <span className="weekname">{label}:</span> {finalStartTime + ' - ' + finalEndTime}
      </p>
    );
  };

  return (
    <>
      {restaurants?.availability &&
        days.map((day) => {
          return getTime(day);
        })}
    </>
  );
}
